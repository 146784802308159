@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.reportBody {
  font-family: "Noto Sans", sans-serif;
}

.btn {
  border: none;
  padding: 11px 33px;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
}
.btn-purple {
  color: #fff;
  background: #a42998;
}

.btn-white {
  color: #a42998;
  background: #fff;
}
.header-buttons {
  display: flex;
  gap: 19px;
}
.header-buttons .btn {
  width: 116px;
  padding: 11px 10px;
  overflow: hidden;
  text-align: center;
}
.main-mav {
  display: flex;
  padding-left: 53px;
  background: #a42998;
}
.main-mav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-mav li {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
}
.main-mav li a {
  color: #fff;
  display: block;
  padding: 9px 27px;
  text-decoration: none;
  transition: all 0.2s;
}
.main-mav li.active a,
.main-mav li a:hover {
  background: #b565ac;
}

.wrapper {
  padding: 18px 22px;
}

.left-sidebar {
  width: 253px;
}
.wrapper {
  padding: 18px 22px;
  display: flex;
}
.left-sidebar {
  width: 253px;
}
.content-wrapper {
  width: calc(100% - 253px);
  padding-left: 20px;
}

.acc-link-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.acc-link-wrap {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
}
.acc-open + .acc-link-wrap {
  max-height: 500px;
}
.acc-head {
  position: relative;
  background: #6093aa;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 9px 18px;
  cursor: pointer;
}
.acc-head span {
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 6px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  background: #fff;
}

.acc-head span::before {
  content: "";
  position: absolute;
  width: 13px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #6093aa;
}
.acc-head span::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #6093aa;
  transform: rotate(90deg);
}

.acc-head span::after {
  display: block;
}

.acc-open.acc-head span::after {
  display: none;
}

.acc-link-wrap li a {
  display: block;
  text-decoration: none;
  padding: 8px 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #6093aa;
  background: #eeecec;
  transition: all 0.2s;
}

.acc-link-wrap li a:hover,
.acc-link-wrap li.active a {
  background: #afc9d4;
  color: #fff;
}
.ctop-flex {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
}
.ctop-inps {
  width: calc(24%);
  margin: 0 0.5% 12px;
}
.logo-inps {
  width: calc(33.33%);
}
.filter-inps h2 {
  font-size: 17px;
  color: #fff;
  padding: 10px 15px;
  letter-spacing: 0.5px;
  background: #6093aa;
  margin-bottom: 0;
}
.filter-inps h2.back-purple {
  background: #a42998;
}
.filter-control {
  width: 100%;
  border: none;
  background: #fafafa;
  padding: 10px;
  color: #6093aa;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.ctop {
  margin-bottom: 40px;
}
.table-title {
  color: #fff;
  font-size: 18px;
  padding: 8px 13px;
  background: #a42998;
  margin-bottom: 12px;
}
.table-title span {
  font-weight: 700;
}
.table {
  width: 100%;
  text-align: left;
}
.table-header {
  color: #fff;
  font-size: 18px;
  padding: 8px 10px;
  background: #6093aa;
  margin-top: 10px;
}
.table-header span {
  font-weight: 400;
}
.table th {
  padding: 8px;
}
.table.table-stripe th {
  font-size: 18px;
  color: #475f7b;
  font-weight: 700;
}
.table-stripe tbody tr:nth-child(odd) td .gap-cell {
  background: #f6f6f6;
}
.table-stripe tr th,
td {
  border: none;
}

.table td {
  padding: 0 8px;
  color: #475f7b;
}
.table-responsive {
  overflow-x: hidden;
}
.content-bottom {
  display: flex;
  margin-top: 35px;
  align-items: center;
}
.c-format {
  background: #f6f6f6;
  padding: 9px 20px;
  color: #6093aa;
}
.select-format {
  border: none;
  background: transparent;
  padding: 0 80px 0 4px;
  font-size: 16px;
}
.content-bottom {
  display: flex;
  margin-top: 35px;
  align-items: center;
  gap: 19px;
}
.week-calendar-inp {
  border: none;
  background: transparent;
  font-size: 16px;
  text-align: center;
  width: 100%;
  color: #66aa60;
  font-weight: 700;
  letter-spacing: 1px;
}
.week-calendar .ctop-inps {
  position: relative;
}
.week-calendar .ctop-inps .cal-prev {
  position: absolute;
  left: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  width: 19px;
  border: none;
}
.week-calendar .ctop-inps .cal-next {
  position: absolute;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  width: 19px;
  border: none;
}
img {
  max-width: 100%;
}
.week-cal-label {
  font-size: 16px;
  text-align: center;
  color: #66aa60;
  font-weight: 700;
}
.week-calendar {
  margin: 6px 0;
}
.filter-inps h2.light-blue {
  background: #aec8d4;
}
.light-blue {
  background: #aec8d4;
}
.table-header-blue {
  background: #6093aa;
  color: #fff;
  padding: 11px;
}
.totalRow {
  background: #a42998;
}
.totalRow td {
  height: 46px;
}
.totalRow th {
  padding: 0 8px;
}
.totalRow .gap-cell {
  background: transparent !important;
  color: #fff;
}

.mb0 {
  margin-bottom: 0px;
}

.daysGridWrapper table th,
td {
  width: 12.5%;
}

.totalRow.totalRowBlue {
  background: #6093aa;
}

.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: 0 !important;
}
.mb20 {
  margin-bottom: 20px;
}
.f700 {
  font-weight: 700 !important;
}
.text-center {
  text-align: center !important;
}
.back-purple {
  background: #a42998 !important;
  color: #fff;
}
.cal-table .gap-cell {
  background: #f6f6f6;
  margin-top: 14px;
}

.header-column {
  display: flex;
  flex-direction: column;
}
.header-column .table-header {
  display: inline-flex;
  min-width: 24%;
  max-width: max-content;
}
.back-light-blue {
  background: #aec8d4 !important;
}
.back-blue {
  background: #6093aa !important;
}
.header-row {
  display: flex;
  flex-wrap: wrap;
}
.header-row .table-header {
  min-width: 24%;
  margin-right: 1%;
}
.header-row .table-header.w-32{
  min-width: 33%;
  vertical-align: middle;
}

.report-tbl tr td:first-of-type{
  padding-left: 0px;
}

.report-tbl tr td:last-of-type{
  padding-right: 0px;
}

.header-row .table-header.w-25{
  min-width: 26%;
  vertical-align: middle;
}


.color-red {
  color: #ef0909 !important;
}
.mt30 {
  margin-top: 30px;
}
.gap-cell.file {
  position: relative;
  padding-right: 50px;
}
.gap-cell.file .fileicon {
  position: absolute;
  max-width: 20px;
  right: 5px;
}

.table th,
.table td {
  border-top: none;
}

.nonChargeableReport th:nth-child(1),
.nonChargeableReport td:nth-child(1),
.nonChargeableReport th:nth-child(2),
.nonChargeableReport td:nth-child(2) {
  width: 20%;
}
.nonChargeableReport th:nth-child(3),
.nonChargeableReport td:nth-child(3) {
  width: 30%;
}
.nonChargeableReport th:nth-child(4),
.nonChargeableReport td:nth-child(4),
.nonChargeableReport th:nth-child(5),
.nonChargeableReport td:nth-child(5),
.nonChargeableReport th:nth-child(6),
.nonChargeableReport td:nth-child(6) {
  width: 10%;
}

.wipDetailReportGrid th:nth-child(1),
.wipDetailReportGrid td:nth-child(1),
.wipDetailReportGrid th:nth-child(2),
.wipDetailReportGrid td:nth-child(2) {
  width: 30%;
}
.wipDetailReportGrid th:nth-child(3),
.wipDetailReportGrid td:nth-child(3),
.wipDetailReportGrid th:nth-child(4),
.wipDetailReportGrid td:nth-child(4) {
  width: 20%;
}

.feeIncomeSummaryGrid {
  table-layout: fixed;
}
.feeIncomeSummaryGrid th,
.feeIncomeSummaryGrid td {
  width: 10%;
}
.feeIncomeSummaryGrid th:nth-child(1),
.feeIncomeSummaryGrid td:nth-child(1) {
  width: 17%;
}
.feeIncomeSummaryGrid th:nth-child(8),
.feeIncomeSummaryGrid td:nth-child(8) {
  width: 13%;
}

.empIncomeSummaryGrid th,
.empIncomeSummaryGrid td {
  width: 16.5%;
}
.empIncomeSummaryGrid th:nth-child(1),
.empIncomeSummaryGrid td:nth-child(1),
.empIncomeSummaryGrid th n:nth-child(2),
.empIncomeSummaryGrid td n:nth-child(2) {
  width: 25%;
}

.reportBody {
  .plm_cstpopupbox_wrap {
    padding: 0.375rem 0.75rem;
  }
  .popUpContainer {
    padding-bottom: 5px;
    max-height: 300px;
    min-width: 320px;
    max-width: 350px;
    margin-top: 0px;
    overflow: auto;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    padding-left: 0px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .tb-wrap {
      max-height: 250px;
      overflow-y: auto;
      margin-bottom: 0;
      padding: 0;
      margin: 0;
      .table {
        border-right: none;
        border-spacing: 5px 3px;
        border: none;
        border-collapse: separate;
        tr th {
          font-weight: 600;
          font-size: 11px;
          border: none;
          text-transform: capitalize;
          position: sticky;
          top: 0;
          background: #ffffff;
          z-index: 1;
        }
        th,
        td {
          letter-spacing: 1px;
          vertical-align: top;
          font-size: 11px;
          line-height: 16px;
          padding: 10px 5px;
          border: none;
          color: #475f7b;
          font-family: Verdana, sans-serif;
          word-break: break-word;
        }
        td {
          height: 36px;
          cursor: pointer;
        }
        tr:nth-of-type(odd) td {
          background: #eeeeee;
        }
        tr:nth-child(1) td {
          border-top: none !important;
        }
      }
    }
    .clientPopupGrid {
      table-layout: fixed;
      tr td:nth-child(1) {
        width: 14%;
      }
    }
  }
}

.wipDetailReportGrid tr th,
.wipDetailReportGrid tr td{
  border: none !important;
}

.header-row .table-header.w-40{
  min-width: 40%;
  vertical-align: middle;
}

.header-row .table-header.w-30{
  min-width: 30%;
  vertical-align: middle;
}

.feeIncomeSummaryGrid tr th,
.feeIncomeSummaryGrid tr td{
  border: none !important;
}

.empIncomeSummaryGrid tr th,
.empIncomeSummaryGrid tr td{
  border: none !important;
}

.header-row .table-header.w-20{
  min-width: 20%;
  vertical-align: middle;
}

.costingSummaryGrid tr th,
.costingSummaryGrid tr td{
  border: none !important;
}

.reportWrapper .totalRow {
  padding-left: 15px !important; 
  padding-right: 15px !important; 
}

.reportWrapper .costingSummaryWrap .tb-wrap .table .th{
  min-width: 160px !important;
  color: #fff !important;
  width: 12.5% !important;
}

.reportWrapper .costingSummaryWrap .reportTbl tr th,
.reportWrapper .costingSummaryWrap .reportTbl tr td{
  border: none !important;
}

.reportWrapper .costingSummaryWrap .tb-wrap .table td+td+td+td,
.reportWrapper .costingSummaryWrap .tb-wrap .table th+th+th+th{
  text-align: center !important;
}

.reportWrapper .costingSummaryWrap .reportTbl{
  margin-bottom: 0px !important;
  height: 30px !important;
}

.reportWrapper .costingSummaryWrap .reportTbl thead tr th .th-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: center !important;
  display: block;
}

.reportWrapper .costingSummaryWrap .col-12 {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-bottom: 20px !important;
}

.div_container_invoice .invTblWrap tr th,
.div_container_invoice .invTblWrap tr td{
  border: none !important;
}
.header-row .table-header.w-60{
  min-width: 60%;
  vertical-align: middle;
}

.div_container_invoice .futureReport .reportTbl tr th,
.div_container_invoice .futureReport .reportTbl tr td{
  border: none !important;
}

.reportWrapper .futureReport .reportTbl{
  margin-bottom: 0px !important;
  height: 20px !important;
}

.reportWrapper .futureReport .reportTbl tr th{
  border: none !important;
  color: white !important;
  width: 12.5% !important;
}

.reportWrapper .futureReport .totalRow .reportTbl thead tr th .th-cell{
  padding-left: 0px !important;
  display: block;
}

.reportWrapper .futureReport .reportTbl table tr th{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.timesheet_report_div .daysGridWrapper .totalRow {
  background: #a42998 !important;
}

.timesheet_report_div .totalRow * {
  font-size: 16px !important;
  min-height: initial !important;
  height: initial !important;
}

.timesheet_report_div .daysGridWrapper .totalRow * {
  background: #a42998 !important;
  
}

.timesheet_report_div .daysGridWrapper .totalRow.totalRowBlue * {
  background: #6093aa !important;
}

.timesheet_report_div .daysGridWrapper table, .timesheet_report_div .daysGridWrapper tbody, .timesheet_report_div .daysGridWrapper tr, .timesheet_report_div .daysGridWrapper th, .timesheet_report_div .daysGridWrapper td{
  border: none !important;
}
.timesheet_report_div .tdivexps_top {
  background: #ffffff !important;
}
.timesheet_report_div .totalRow .th-cell, .timesheet_report_div .totalRow .th-cell div {
  color: #fff !important;
}
.timesheet_report_div .totalRow th {
  vertical-align: middle !important;
}

.timesheet_report_div .table td, .timesheet_report_div .totalRow th {
  padding: 0 8px !important;
  height: 46px;
}

.timesheet_report_div .totalRow  .gap-cell {
  padding: 8px !important;
  min-height: initial !important;
}

table th {
  vertical-align: middle !important;
}

.non_submitted_report_header{
  padding-left: 15px !important;
}

.totalRow .th-cell {
  color: #fff;
}

.tsgridtbldiv .table thead tr th:nth-child(2) {
  width: 19%;
}
.tsgridtbldiv .table thead tr th:nth-child(3) {
  width: 19%;
}
.tsgridtbldiv .table thead tr th:nth-child(4) {
  width: 43%;
}

.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(1) {
  width: 19% !important;
}
.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(2) {
  width: 19%;
}
.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(3) {
  width: 19%;
}
.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(4) {
  width: 43%;
}
.timesheet_report_div  .table-stripe tbody tr:nth-child(even) td .gap-cell {
  background: #fff;
}
.timesheet_report_div .tsgridtbldiv .table td, .timesheet_report_div .tsgridtbldiv .table th{
  border: none !important;
}
.timesheet_report_div  .total_exp_coldiv .tb-wrap table tr td {
  vertical-align: middle;
}

.dataTables_length {
  display: none !important;
}

.tsgridtbldiv .table thead tr th:nth-child(2) {
  width: 30%;
}
.ctop-flex .ctop-inps {
  width: auto;
  flex: auto;
}
.feeIncomeSummaryGrid th:nth-child(1), .feeIncomeSummaryGrid td:nth-child(1) {
  width: 14%;
}
.wipReportGrid {
  width: 60%;
}
.wipDetailReportGrid {
  width: 60%;
}


.total_timeslot_content  table tr .th:nth-child(1), .total_timeslot_content   table tr .th:nth-child(3){
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important;
}

.plm_cmn_tbldiv table tr th:nth-child(1), 
.plm_cmn_tbldiv table tr th:nth-child(3) {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}

.plm_cmn_tbldiv table tr th:nth-child(2), 
.plm_cmn_tbldiv table tr th:nth-child(4), 
.plm_cmn_tbldiv table tr th:nth-child(5), 
.plm_cmn_tbldiv table tr th:nth-child(6), 
.plm_cmn_tbldiv table tr th:nth-child(7) {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important;
}

.feeIncomeSummaryGrid th:nth-child(1), .feeIncomeSummaryGrid td:nth-child(1) {
  width: 11%;
}
.feeIncomeSummaryGrid th:nth-child(2), .feeIncomeSummaryGrid td:nth-child(2) {
  width: 14% !important;
}

*{
  font-size: 13px;
  line-height: 1.2 !important;
  font-family: "Noto Sans", sans-serif !important;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.fa {
  font: normal normal normal 14px/1 FontAwesome !important;
}

table th, table td {
  font-family: "Noto Sans", sans-serif !important;
}

.App.PinkBlue .plm_layout_right {
  left: 235px;
  width: calc(100% - 235px);
  padding-right: 0 !important;
}
.reportHeader {
  & ~ .customerMainContentapp_inner_wrap {
    margin-top: 20px !important;
    .plm_layout_right {
      left: 235px;
      padding-right: 0 !important;
      width: calc(100% - 235px);
    }
  }
}
.ctop-inps {
  margin: 0 0.5% 0px !important;
}

.App.PinkBlue .reportHeader.navigation .sideBar ul li a .linkText, .App.PinkBlue .navigation.navigation .sideBar ul li a .linkText {
  font-size: 14px !important;
}

.reportHeaderWrap .imgLogo, .header_wrap .imgLogo {
  width: initial !important;
  height: initial !important;
}

.reportHeaderWrap, .header_wrap {
  height: initial !important;
}

.logo >td {
  padding: 0 !important;
}
.plm_leftnav_wrap ul.left_reportmenu > li > ul > li > a {
  font-size: 14px !important;
}
ul.list-unstyled.left_reportmenu li p{
  font-size: 14px !important;
}

.ctop {
  margin-bottom: 25px !important;
}
.table-header, .table-title {
  font-size: 14px;
}
.btn {
  padding: 8px 33px;
  font-size: 13px;
}
.plm_cmn_tbldiv table tbody tr td:first-child {
  width: 9% !important;
}
.timesheetReportWrap .table-header.light-blue {
  margin: 0;
}
.filter-inps h2 {
  font-size: 13px;
  padding: 8px 15px !important;
}
.reportBody .header-row:nth-child(2) .table-header {
  margin-top: 0;
}

.header-column .header-label{
  font-weight: 700;
}

table {
  table-layout: fixed;
}

.table .gap-cell {
  padding: 6px !important;
  min-height: 30px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 12px !important;
}

.totalRow td {
  height: 30px !important;
}

.week-cal-label {
  font-size: 13px !important;
}

.table-header-blue{
  padding: 8px !important;
}

.App.PinkBlue .adminMainContentapp_inner_wrap .tb-wrap .plm_cmn_tbldiv table th:nth-child(9), .App.PinkBlue .adminMainContentapp_inner_wrap .tb-wrap .plm_cmn_tbldiv table td:nth-child(9) {
  width: 14%;
}


.PinkBlue.App .reportMenuWrapper .left_reportmenu .rpt_title, .PinkBlue.App .reportMenuWrapper .left_reportmenu li a {
  padding: 8px 0.8rem !important;
  height: auto  !important;
  font-size: 13px !important;
}
.ctop-flex {
  gap: 5px;
}
.ctop-flex .ctop-inps {
  margin: 0 !important;
}
.table-header {
  padding: 8px 15px;
}
.table-title {
  padding: 8px 15px;
}

.table-header, .table-title {
  font-size: 13px;
}
.table-header-blue {
  padding: 8px 15px !important;
}
.table .gap-cell {
  padding: 8px 6px !important;
  min-height: 31.59px !important;
}
.table .totalRow .gap-cell {
  font-size: 13px !important;
  padding-left: 15px !important;
}
.totalRow .table .th-cell .gap-cell {
  padding: 8px 15px !important;
}
.timesheetReportWrap .totalRow th.th.totalFirstCol {
  width: 61.6%;
}
.filter-control {
  padding: 8px 10px !important;
}

.tblLabelHeader{
  margin-top: 10px !important;
}

.plm_cmn_tbldiv .tblLabelHeader tr th:nth-child(1)
{
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
  background: #6093aa !important;
  padding: 8px 15px !important;
  color: #fff !important;
  font-weight: 400 !important;
}

table tr td:last-child {
  padding-right: 0px !important;
}

.plm_cmn_tbldiv .tblLabelHeader.light-blue tr th:nth-child(1){
  background: #aec8d4 !important;
}

.plm_cmn_tbldiv .tblLabelTimesheetHeader{
  width: 50% !important;
}

.plm_cmn_tbldiv .tblLabelTimesheetHeader tr th:nth-child(1)
{
  width: 18.5% !important;
  min-width: 18.5% !important;
  max-width: 18.5% !important;
}

.timesheetReportWrap .totalRow .table .th-cell .gap-cell {
  padding: 8px 6px !important;
}

.timesheetReportWrap .totalRow .table th.th {
  padding: 0px 8px !important;
}

.timesheetReportWrap .totalRow .table th:first-child {
  padding-left: 0px !important;
}

.timesheetReportWrap .totalRow .table tr th:nth-child(1), 
.timesheetReportWrap .totalRow .table tr th:nth-child(3){
  width: 13% !important;
}

.timesheetReportWrap .totalRow .table tr th:nth-child(2), 
.timesheetReportWrap .totalRow .table tr th:nth-child(4),
.timesheetReportWrap .totalRow .table tr th:nth-child(5), 
.timesheetReportWrap .totalRow .table tr th:nth-child(6),
.timesheetReportWrap .totalRow .table tr th:nth-child(7){
  width: 16% !important;
}

.timesheetReportWrap .totalRow .table tr th.th.totalFirstCol {
  width: 26% !important;
  padding-left: 9px !important;
}

.nonSubmittedRpt .header-column .table-header{
  min-width: 29% !important;
  max-width: 29% !important;
  width: 29% !important;
}

.tdivexps_top {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.timesheetRpt .header-column .table-header {
  width: 20.6% !important;
  min-width: 20.6%;
  max-width: 20.6%;
}

.nonChargeableRpt .header-column .table-header {
  width: 30% !important;
  min-width: 30%;
  max-width: 30%;
}

.nonChargeableTotalRow th:nth-child(1) {
    width: 20%;
}

.nonChargeableTotalRow th:nth-child(2), 
.nonChargeableTotalRow th:nth-child(3),
.nonChargeableTotalRow th:nth-child(4) {
  width: 9%;
}

.nonChargeableTotalRow th:nth-child(4) {
  width: 9.5%;
}

.clientWipDetailsRpt .header-row .table-header:first-child {
  width: 20% !important;
  min-width: 20%;
  max-width: max-content;
}

.clientWipDetailsRpt .totalRow .table .th-cell .gap-cell {
  padding: 8px 15px !important;
}

.clientWipDetailsRpt th:nth-child(1) {
  width: 30.25%;
}



.clientWipDetailsRpt th:nth-child(3) {
  width: 20%;
}

.wipSummaryTotalRow th:nth-child(1) {
  width: 72%;
}

.clientWipSummaryRpt .header-column .table-header {
  width: 42% !important;
  min-width: 42%;
  max-width: max-content;
}

.totalWipDetailRpt .header-column .table-header, .totalWipDetailRpt .header-row .table-header:first-child {
  width: 19% !important;
  min-width: 19%;
  max-width: max-content;
}

.totalWipDetailRpt .header-column .table-header, .totalWipDetailRpt .header-row .table-header:nth-child(2) {
  width: 20% !important;
  min-width: 20%;
  max-width: max-content;
}

.totalWipDetailTotalRow th:nth-child(1) {
  width: 30.25%;
}

.totalWipDetailTotalRow th:nth-child(2) {
  width: 12%;
}

.totalWipDetailTotalRow th:nth-child(3) {
  width: 20%;
}


.feeIncomeSummaryTotalRow th:nth-child(1) {
  width: 20.2%;
}

.feeIncomeSummaryTotalRow th:nth-child(2) {
  width: 8.3%;
}

.feeIncomeSummaryTotalRow th:nth-child(3),
.feeIncomeSummaryTotalRow th:nth-child(4),
.feeIncomeSummaryTotalRow th:nth-child(5),
.feeIncomeSummaryTotalRow th:nth-child(6) {
  width: 13%;
}

.feeIncomeDetailTotalRow th:nth-child(1) {
  width: 24% !important;
}

.feeIncomeDetailTotalRow th:nth-child(2) {
  width: 9.8% !important;
}

.feeIncomeDetailTotalRow th:nth-child(3),
.feeIncomeDetailTotalRow th:nth-child(4),
.feeIncomeDetailTotalRow th:nth-child(5),
.feeIncomeDetailTotalRow th:nth-child(6) {
  width: 15.5% !important;
}

.empIncomeSummaryTotalRow th:nth-child(1){
  width: 25.5% !important;
}
.empIncomeSummaryTotalRow th:nth-child(2){
  width: 26% !important;
}

.App.PinkBlue .cardBox .card-head {
  padding: 9px 15px !important;
}
.plm_inv_wiz_step1 .row {
  margin: 0 !important;
}
.App.PinkBlue .cardBox .card-body .emp_topvaldiv .label-wraper {
  padding: 5px 15px !important;
}
.App.PinkBlue .cardBox .card-body .trow_time_bottom .totalRow table thead tr th {
  background: #a42998 !important;
}
.App.PinkBlue .cardBox .card-body .grand_total_div table tr th {
  background: #a42998 !important;
}@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.reportBody {
  font-family: "Noto Sans", sans-serif;
}

.btn {
  border: none;
  padding: 11px 33px;
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
}
.btn-purple {
  color: #fff;
  background: #a42998;
}

.btn-white {
  color: #a42998;
  background: #fff;
}
.header-buttons {
  display: flex;
  gap: 19px;
}
.header-buttons .btn {
  width: 116px;
  padding: 11px 10px;
  overflow: hidden;
  text-align: center;
}
.main-mav {
  display: flex;
  padding-left: 53px;
  background: #a42998;
}
.main-mav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-mav li {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
}
.main-mav li a {
  color: #fff;
  display: block;
  padding: 9px 27px;
  text-decoration: none;
  transition: all 0.2s;
}
.main-mav li.active a,
.main-mav li a:hover {
  background: #b565ac;
}

.wrapper {
  padding: 18px 22px;
}

.left-sidebar {
  width: 253px;
}
.wrapper {
  padding: 18px 22px;
  display: flex;
}
.left-sidebar {
  width: 253px;
}
.content-wrapper {
  width: calc(100% - 253px);
  padding-left: 20px;
}

.acc-link-wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.acc-link-wrap {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s;
}
.acc-open + .acc-link-wrap {
  max-height: 500px;
}
.acc-head {
  position: relative;
  background: #6093aa;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 9px 18px;
  cursor: pointer;
}
.acc-head span {
  display: block;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 6px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  background: #fff;
}

.acc-head span::before {
  content: "";
  position: absolute;
  width: 13px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #6093aa;
}
.acc-head span::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #6093aa;
  transform: rotate(90deg);
}

.acc-head span::after {
  display: block;
}

.acc-open.acc-head span::after {
  display: none;
}

.acc-link-wrap li a {
  display: block;
  text-decoration: none;
  padding: 8px 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  color: #6093aa;
  background: #eeecec;
  transition: all 0.2s;
}

.acc-link-wrap li a:hover,
.acc-link-wrap li.active a {
  background: #afc9d4;
  color: #fff;
}
.ctop-flex {
  display: flex;
  flex-wrap: wrap;
  /* gap: 20px; */
}
.ctop-inps {
  width: calc(24%);
  margin: 0 0.5% 12px;
}
.logo-inps {
  width: calc(33.33%);
}
.filter-inps h2 {
  font-size: 17px;
  color: #fff;
  padding: 10px 15px;
  letter-spacing: 0.5px;
  background: #6093aa;
  margin-bottom: 0;
}
.filter-inps h2.back-purple {
  background: #a42998;
}
.filter-control {
  width: 100%;
  border: none;
  background: #fafafa;
  padding: 10px;
  color: #6093aa;
  font-size: 16px;
  letter-spacing: 0.5px;
}
.ctop {
  margin-bottom: 40px;
}
.table-title {
  color: #fff;
  font-size: 18px;
  padding: 8px 13px;
  background: #a42998;
  margin-bottom: 12px;
}
.table-title span {
  font-weight: 700;
}
.table {
  width: 100%;
  text-align: left;
}
.table-header {
  color: #fff;
  font-size: 18px;
  padding: 8px 10px;
  background: #6093aa;
  margin-top: 10px;
}
.table-header span {
  font-weight: 400;
}
.table th {
  padding: 8px;
}
.table.table-stripe th {
  font-size: 18px;
  color: #475f7b;
  font-weight: 700;
}
.table-stripe tbody tr:nth-child(odd) td .gap-cell {
  background: #f6f6f6;
}
.table-stripe tr th,
td {
  border: none;
}

.table td {
  padding: 0 8px;
  color: #475f7b;
}
.table-responsive {
  overflow-x: hidden;
}
.content-bottom {
  display: flex;
  margin-top: 35px;
  align-items: center;
}
.c-format {
  background: #f6f6f6;
  padding: 9px 20px;
  color: #6093aa;
}
.select-format {
  border: none;
  background: transparent;
  padding: 0 80px 0 4px;
  font-size: 16px;
}
.content-bottom {
  display: flex;
  margin-top: 35px;
  align-items: center;
  gap: 19px;
}
.week-calendar-inp {
  border: none;
  background: transparent;
  font-size: 16px;
  text-align: center;
  width: 100%;
  color: #66aa60;
  font-weight: 700;
  letter-spacing: 1px;
}
.week-calendar .ctop-inps {
  position: relative;
}
.week-calendar .ctop-inps .cal-prev {
  position: absolute;
  left: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  width: 19px;
  border: none;
}
.week-calendar .ctop-inps .cal-next {
  position: absolute;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  width: 19px;
  border: none;
}
img {
  max-width: 100%;
}
.week-cal-label {
  font-size: 16px;
  text-align: center;
  color: #66aa60;
  font-weight: 700;
}
.week-calendar {
  margin: 6px 0;
}
.filter-inps h2.light-blue {
  background: #aec8d4;
}
.light-blue {
  background: #aec8d4;
}
.table-header-blue {
  background: #6093aa;
  color: #fff;
  padding: 11px;
}
.totalRow {
  background: #a42998;
}
.totalRow td {
  height: 46px;
}
.totalRow th {
  padding: 0 8px;
}
.totalRow .gap-cell {
  background: transparent !important;
  color: #fff;
}

.mb0 {
  margin-bottom: 0px;
}

.daysGridWrapper table th,
td {
  width: 12.5%;
}

.totalRow.totalRowBlue {
  background: #6093aa;
}

.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: 0 !important;
}
.mb20 {
  margin-bottom: 20px;
}
.f700 {
  font-weight: 700 !important;
}
.text-center {
  text-align: center !important;
}
.back-purple {
  background: #a42998 !important;
  color: #fff;
}
.cal-table .gap-cell {
  background: #f6f6f6;
  margin-top: 14px;
}

.header-column {
  display: flex;
  flex-direction: column;
}
.header-column .table-header {
  display: inline-flex;
  min-width: 24%;
  max-width: max-content;
}
.back-light-blue {
  background: #aec8d4 !important;
}
.back-blue {
  background: #6093aa !important;
}
.header-row {
  display: flex;
  flex-wrap: wrap;
}
.header-row .table-header {
  min-width: 24%;
  margin-right: 1%;
}
.header-row .table-header.w-32{
  min-width: 33%;
  vertical-align: middle;
}

.report-tbl tr td:first-of-type{
  padding-left: 0px;
}

.report-tbl tr td:last-of-type{
  padding-right: 0px;
}

.header-row .table-header.w-25{
  min-width: 26%;
  vertical-align: middle;
}


.color-red {
  color: #ef0909 !important;
}
.mt30 {
  margin-top: 30px;
}
.gap-cell.file {
  position: relative;
  padding-right: 50px;
}
.gap-cell.file .fileicon {
  position: absolute;
  max-width: 20px;
  right: 5px;
}

.table th,
.table td {
  border-top: none;
}

.nonChargeableReport th:nth-child(1),
.nonChargeableReport td:nth-child(1),
.nonChargeableReport th:nth-child(2),
.nonChargeableReport td:nth-child(2) {
  width: 20%;
}
.nonChargeableReport th:nth-child(3),
.nonChargeableReport td:nth-child(3) {
  width: 30%;
}
.nonChargeableReport th:nth-child(4),
.nonChargeableReport td:nth-child(4),
.nonChargeableReport th:nth-child(5),
.nonChargeableReport td:nth-child(5),
.nonChargeableReport th:nth-child(6),
.nonChargeableReport td:nth-child(6) {
  width: 10%;
}

.wipDetailReportGrid th:nth-child(1),
.wipDetailReportGrid td:nth-child(1),
.wipDetailReportGrid th:nth-child(2),
.wipDetailReportGrid td:nth-child(2) {
  width: 30%;
}
.wipDetailReportGrid th:nth-child(3),
.wipDetailReportGrid td:nth-child(3),
.wipDetailReportGrid th:nth-child(4),
.wipDetailReportGrid td:nth-child(4) {
  width: 20%;
}

.feeIncomeSummaryGrid {
  table-layout: fixed;
}
.feeIncomeSummaryGrid th,
.feeIncomeSummaryGrid td {
  width: 10%;
}
.feeIncomeSummaryGrid th:nth-child(1),
.feeIncomeSummaryGrid td:nth-child(1) {
  width: 17%;
}
.feeIncomeSummaryGrid th:nth-child(8),
.feeIncomeSummaryGrid td:nth-child(8) {
  width: 13%;
}

.empIncomeSummaryGrid th,
.empIncomeSummaryGrid td {
  width: 16.5%;
}
.empIncomeSummaryGrid th:nth-child(1),
.empIncomeSummaryGrid td:nth-child(1),
.empIncomeSummaryGrid th n:nth-child(2),
.empIncomeSummaryGrid td n:nth-child(2) {
  width: 25%;
}

.reportBody {
  .plm_cstpopupbox_wrap {
    padding: 0.375rem 0.75rem;
  }
  .popUpContainer {
    padding-bottom: 5px;
    max-height: 300px;
    min-width: 320px;
    max-width: 350px;
    margin-top: 0px;
    overflow: auto;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    padding-left: 0px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .tb-wrap {
      max-height: 250px;
      overflow-y: auto;
      margin-bottom: 0;
      padding: 0;
      margin: 0;
      .table {
        border-right: none;
        border-spacing: 5px 3px;
        border: none;
        border-collapse: separate;
        tr th {
          font-weight: 600;
          font-size: 11px;
          border: none;
          text-transform: capitalize;
          position: sticky;
          top: 0;
          background: #ffffff;
          z-index: 1;
        }
        th,
        td {
          letter-spacing: 1px;
          vertical-align: top;
          font-size: 11px;
          line-height: 16px;
          padding: 10px 5px;
          border: none;
          color: #475f7b;
          font-family: Verdana, sans-serif;
          word-break: break-word;
        }
        td {
          height: 36px;
          cursor: pointer;
        }
        tr:nth-of-type(odd) td {
          background: #eeeeee;
        }
        tr:nth-child(1) td {
          border-top: none !important;
        }
      }
    }
    .clientPopupGrid {
      table-layout: fixed;
      tr td:nth-child(1) {
        width: 14%;
      }
    }
  }
}

.wipDetailReportGrid tr th,
.wipDetailReportGrid tr td{
  border: none !important;
}

.header-row .table-header.w-40{
  min-width: 40%;
  vertical-align: middle;
}

.header-row .table-header.w-30{
  min-width: 30%;
  vertical-align: middle;
}

.feeIncomeSummaryGrid tr th,
.feeIncomeSummaryGrid tr td{
  border: none !important;
}

.empIncomeSummaryGrid tr th,
.empIncomeSummaryGrid tr td{
  border: none !important;
}

.header-row .table-header.w-20{
  min-width: 20%;
  vertical-align: middle;
}

.costingSummaryGrid tr th,
.costingSummaryGrid tr td{
  border: none !important;
}

.reportWrapper .totalRow {
  padding-left: 15px !important; 
  padding-right: 15px !important; 
}

.reportWrapper .costingSummaryWrap .tb-wrap .table .th{
  min-width: 160px !important;
  color: #fff !important;
  width: 12.5% !important;
}

.reportWrapper .costingSummaryWrap .reportTbl tr th,
.reportWrapper .costingSummaryWrap .reportTbl tr td{
  border: none !important;
}

.reportWrapper .costingSummaryWrap .tb-wrap .table td+td+td+td,
.reportWrapper .costingSummaryWrap .tb-wrap .table th+th+th+th{
  text-align: center !important;
}

.reportWrapper .costingSummaryWrap .reportTbl{
  margin-bottom: 0px !important;
  height: 30px !important;
}

.reportWrapper .costingSummaryWrap .reportTbl thead tr th .th-cell {
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-align: center !important;
  display: block;
}

.reportWrapper .costingSummaryWrap .col-12 {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-bottom: 20px !important;
}

.div_container_invoice .invTblWrap tr th,
.div_container_invoice .invTblWrap tr td{
  border: none !important;
}
.header-row .table-header.w-60{
  min-width: 60%;
  vertical-align: middle;
}

.div_container_invoice .futureReport .reportTbl tr th,
.div_container_invoice .futureReport .reportTbl tr td{
  border: none !important;
}

.reportWrapper .futureReport .reportTbl{
  margin-bottom: 0px !important;
  height: 20px !important;
}

.reportWrapper .futureReport .reportTbl tr th{
  border: none !important;
  color: white !important;
  width: 12.5% !important;
}

.reportWrapper .futureReport .totalRow .reportTbl thead tr th .th-cell{
  padding-left: 0px !important;
  display: block;
}

.reportWrapper .futureReport .reportTbl table tr th{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.timesheet_report_div .daysGridWrapper .totalRow {
  background: #a42998 !important;
}

.timesheet_report_div .totalRow * {
  font-size: 16px !important;
  min-height: initial !important;
  height: initial !important;
}

.timesheet_report_div .daysGridWrapper .totalRow * {
  background: #a42998 !important;
  
}

.timesheet_report_div .daysGridWrapper .totalRow.totalRowBlue * {
  background: #6093aa !important;
}

.timesheet_report_div .daysGridWrapper table, .timesheet_report_div .daysGridWrapper tbody, .timesheet_report_div .daysGridWrapper tr, .timesheet_report_div .daysGridWrapper th, .timesheet_report_div .daysGridWrapper td{
  border: none !important;
}
.timesheet_report_div .tdivexps_top {
  background: #ffffff !important;
}
.timesheet_report_div .totalRow .th-cell, .timesheet_report_div .totalRow .th-cell div {
  color: #fff !important;
}
.timesheet_report_div .totalRow th {
  vertical-align: middle !important;
}

.timesheet_report_div .table td, .timesheet_report_div .totalRow th {
  padding: 0 8px !important;
  height: 46px;
}

.timesheet_report_div .totalRow  .gap-cell {
  padding: 8px !important;
  min-height: initial !important;
}

table th {
  vertical-align: middle !important;
}

.non_submitted_report_header{
  padding-left: 15px !important;
}

.totalRow .th-cell {
  color: #fff;
}

.tsgridtbldiv .table thead tr th:nth-child(2) {
  width: 19%;
}
.tsgridtbldiv .table thead tr th:nth-child(3) {
  width: 19%;
}
.tsgridtbldiv .table thead tr th:nth-child(4) {
  width: 43%;
}

.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(1) {
  width: 19% !important;
}
.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(2) {
  width: 19%;
}
.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(3) {
  width: 19%;
}
.timesheet_report_div .tsgridtbldiv .table thead tr th:nth-child(4) {
  width: 43%;
}
.timesheet_report_div  .table-stripe tbody tr:nth-child(even) td .gap-cell {
  background: #fff;
}
.timesheet_report_div .tsgridtbldiv .table td, .timesheet_report_div .tsgridtbldiv .table th{
  border: none !important;
}
.timesheet_report_div  .total_exp_coldiv .tb-wrap table tr td {
  vertical-align: middle;
}

.dataTables_length {
  display: none !important;
}

.tsgridtbldiv .table thead tr th:nth-child(2) {
  width: 30%;
}
.ctop-flex .ctop-inps {
  width: auto;
  flex: auto;
}
.feeIncomeSummaryGrid th:nth-child(1), .feeIncomeSummaryGrid td:nth-child(1) {
  width: 14%;
}
.wipReportGrid {
  width: 60%;
}
.wipDetailReportGrid {
  width: 60%;
}


.total_timeslot_content  table tr .th:nth-child(1), .total_timeslot_content   table tr .th:nth-child(3){
  width: 13% !important;
  min-width: 13% !important;
  max-width: 13% !important;
}

.plm_cmn_tbldiv table tr th:nth-child(1), 
.plm_cmn_tbldiv table tr th:nth-child(3) {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}

.plm_cmn_tbldiv table tr th:nth-child(2), 
.plm_cmn_tbldiv table tr th:nth-child(4), 
.plm_cmn_tbldiv table tr th:nth-child(5), 
.plm_cmn_tbldiv table tr th:nth-child(6), 
.plm_cmn_tbldiv table tr th:nth-child(7) {
  width: 16% !important;
  min-width: 16% !important;
  max-width: 16% !important;
}

.feeIncomeSummaryGrid th:nth-child(1), .feeIncomeSummaryGrid td:nth-child(1) {
  width: 11%;
}
.feeIncomeSummaryGrid th:nth-child(2), .feeIncomeSummaryGrid td:nth-child(2) {
  width: 14% !important;
}

*{
  font-size: 13px;
  line-height: 1.2 !important;
  font-family: "Noto Sans", sans-serif !important;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.fa {
  font: normal normal normal 14px/1 FontAwesome !important;
}

table th, table td {
  font-family: "Noto Sans", sans-serif !important;
}

.App.PinkBlue .plm_layout_right {
  left: 235px;
  width: calc(100% - 235px);
  padding-right: 0 !important;
}
.reportHeader {
  & ~ .customerMainContentapp_inner_wrap {
    margin-top: 20px !important;
    .plm_layout_right {
      left: 235px;
      padding-right: 0 !important;
      width: calc(100% - 235px);
    }
  }
}
.ctop-inps {
  margin: 0 0.5% 0px !important;
}

.App.PinkBlue .reportHeader.navigation .sideBar ul li a .linkText, .App.PinkBlue .navigation.navigation .sideBar ul li a .linkText {
  font-size: 14px !important;
}

.reportHeaderWrap .imgLogo, .header_wrap .imgLogo {
  width: initial !important;
  height: initial !important;
}

.reportHeaderWrap, .header_wrap {
  height: initial !important;
}

.logo >td {
  padding: 0 !important;
}
.plm_leftnav_wrap ul.left_reportmenu > li > ul > li > a {
  font-size: 14px !important;
}
ul.list-unstyled.left_reportmenu li p{
  font-size: 14px !important;
}

.ctop {
  margin-bottom: 25px !important;
}
.table-header, .table-title {
  font-size: 14px;
}
.btn {
  padding: 8px 33px;
  font-size: 13px;
}
.plm_cmn_tbldiv table tbody tr td:first-child {
  width: 9% !important;
}
.timesheetReportWrap .table-header.light-blue {
  margin: 0;
}
.filter-inps h2 {
  font-size: 13px;
  padding: 8px 15px !important;
}
.reportBody .header-row:nth-child(2) .table-header {
  margin-top: 0;
}

.header-column .header-label{
  font-weight: 700;
}

table {
  table-layout: fixed;
}

.table .gap-cell {
  padding: 6px !important;
  min-height: 30px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 12px !important;
}

.totalRow td {
  height: 30px !important;
}

.week-cal-label {
  font-size: 13px !important;
}

.table-header-blue{
  padding: 8px !important;
}

.App.PinkBlue .adminMainContentapp_inner_wrap .tb-wrap .plm_cmn_tbldiv table th:nth-child(9), .App.PinkBlue .adminMainContentapp_inner_wrap .tb-wrap .plm_cmn_tbldiv table td:nth-child(9) {
  width: 14%;
}


.PinkBlue.App .reportMenuWrapper .left_reportmenu .rpt_title, .PinkBlue.App .reportMenuWrapper .left_reportmenu li a {
  padding: 8px 0.8rem !important;
  height: auto  !important;
  font-size: 13px !important;
}
.ctop-flex {
  gap: 5px;
}
.ctop-flex .ctop-inps {
  margin: 0 !important;
}
.table-header {
  padding: 8px 15px;
}
.table-title {
  padding: 8px 15px;
}

.table-header, .table-title {
  font-size: 13px;
}
.table-header-blue {
  padding: 8px 15px !important;
}
.table .gap-cell {
  padding: 8px 6px !important;
  min-height: 31.59px !important;
}
.table .totalRow .gap-cell {
  font-size: 13px !important;
  padding-left: 15px !important;
}
.totalRow .table .th-cell .gap-cell {
  padding: 8px 15px !important;
}
.timesheetReportWrap .totalRow th.th.totalFirstCol {
  width: 61.6%;
}
.filter-control {
  padding: 8px 10px !important;
}

.tblLabelHeader{
  margin-top: 10px !important;
}

.plm_cmn_tbldiv .tblLabelHeader tr th:nth-child(1)
{
  width: 25% !important;
  min-width: 25% !important;
  max-width: 25% !important;
  background: #6093aa !important;
  padding: 8px 15px !important;
  color: #fff !important;
  font-weight: 400 !important;
}

table tr td:last-child {
  padding-right: 0px !important;
}

.plm_cmn_tbldiv .tblLabelHeader.light-blue tr th:nth-child(1){
  background: #aec8d4 !important;
}

.plm_cmn_tbldiv .tblLabelTimesheetHeader{
  width: 50% !important;
}

.plm_cmn_tbldiv .tblLabelTimesheetHeader tr th:nth-child(1)
{
  width: 18.5% !important;
  min-width: 18.5% !important;
  max-width: 18.5% !important;
}

.timesheetReportWrap .totalRow .table .th-cell .gap-cell {
  padding: 8px 6px !important;
}

.timesheetReportWrap .totalRow .table th.th {
  padding: 0px 8px !important;
}

.timesheetReportWrap .totalRow .table th:first-child {
  padding-left: 0px !important;
}

.timesheetReportWrap .totalRow .table tr th:nth-child(1), 
.timesheetReportWrap .totalRow .table tr th:nth-child(3){
  width: 13% !important;
}

.timesheetReportWrap .totalRow .table tr th:nth-child(2), 
.timesheetReportWrap .totalRow .table tr th:nth-child(4),
.timesheetReportWrap .totalRow .table tr th:nth-child(5), 
.timesheetReportWrap .totalRow .table tr th:nth-child(6),
.timesheetReportWrap .totalRow .table tr th:nth-child(7){
  width: 16% !important;
}

.timesheetReportWrap .totalRow .table tr th.th.totalFirstCol {
  width: 26% !important;
}

.nonSubmittedRpt .header-column .table-header{
  min-width: 29% !important;
  max-width: 29% !important;
  width: 29% !important;
}

.tdivexps_top {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.timesheetRpt .header-column .table-header {
  width: 20.6% !important;
  min-width: 20.6%;
  max-width: 20.6%;
}

.nonChargeableRpt .header-column .table-header {
  width: 30% !important;
  min-width: 30%;
  max-width: 30%;
}

.nonChargeableTotalRow th:nth-child(1) {
    width: 20.3%;
}

.nonChargeableTotalRow th:nth-child(2)
,.nonChargeableTotalRow th:nth-child(3) {
    width: 9.2% !important;
}
 
.nonChargeableTotalRow th:nth-child(4) {
  width: 9.65%;
}

.clientWipDetailsRpt .header-row .table-header:first-child {
  width: 20% !important;
  min-width: 20%;
  max-width: max-content;
}

.clientWipDetailsRpt .totalRow .table .th-cell .gap-cell {
  padding: 8px 15px !important;
}

.clientWipDetailsRpt th:nth-child(1) {
  width: 30.3%;
}



.clientWipDetailsRpt th:nth-child(3) {
  width: 20.2%;
}

.wipSummaryTotalRow th:nth-child(1) {
  width: 72.5%;
}

.clientWipSummaryRpt .header-column .table-header {
  width: 42% !important;
  min-width: 42%;
  max-width: max-content;
}

.totalWipDetailRpt .header-column .table-header, .totalWipDetailRpt .header-row .table-header:first-child {
  width: 19% !important;
  min-width: 19%;
  max-width: max-content;
}

.totalWipDetailRpt .header-column .table-header, .totalWipDetailRpt .header-row .table-header:nth-child(2) {
  width: 20% !important;
  min-width: 20%;
  max-width: max-content;
}

.totalWipDetailTotalRow th:nth-child(1) {
  width: 30.5%;
}

.totalWipDetailTotalRow th:nth-child(2) {
  width: 12%;
}

.totalWipDetailTotalRow th:nth-child(3) {
  width: 20.25%;
}


.feeIncomeSummaryTotalRow th:nth-child(1) {
  width: 20.25%;
}

.feeIncomeSummaryTotalRow th:nth-child(2) {
  width: 8.1%;
}

.feeIncomeSummaryTotalRow th:nth-child(3),
.feeIncomeSummaryTotalRow th:nth-child(4),
.feeIncomeSummaryTotalRow th:nth-child(5),
.feeIncomeSummaryTotalRow th:nth-child(6) {
  width: 13%;
}

.feeIncomeDetailTotalRow th:nth-child(1) {
  width: 24.25% !important;
}

.feeIncomeDetailTotalRow th:nth-child(2) {
  width: 9.8% !important;
}

.feeIncomeDetailTotalRow th:nth-child(3),
.feeIncomeDetailTotalRow th:nth-child(4),
.feeIncomeDetailTotalRow th:nth-child(5),
.feeIncomeDetailTotalRow th:nth-child(6) {
  width: 15.65% !important;
}

.empIncomeSummaryTotalRow th:nth-child(1){
  width: 25.4% !important;
}
.empIncomeSummaryTotalRow th:nth-child(2){
  width: 26% !important;
}

.dataTables_wrapper .dataTables_filter {
  float: left !important;
  font-size: 0px;
}

.App.PinkBlue .cardBox .card-head {
  padding: 9px 15px !important;
}
.plm_inv_wiz_step1 .row {
  margin: 0 !important;
}
.App.PinkBlue .cardBox .card-body .emp_topvaldiv .label-wraper {
  padding: 5px 15px !important;
}
.App.PinkBlue .cardBox .card-body .trow_time_bottom .totalRow table thead tr th {
  background: #a42998 !important;
}
.App.PinkBlue .cardBox .card-body .grand_total_div table tr th {
  background: #a42998 !important;
}

.reportBody .table tr td:first-child .table tr th:first-child, 
.reportBody .table tr td:first-child .table tr td:first-child{
  padding-left: 15px !important;
}
.total_exp_coldiv .table tr th:first-child, .total_exp_coldiv .table tr td.td.td:first-child {
  padding-left: 15px !important;
}

.nonChargeableReport.table tr th:first-child, .nonChargeableReport.table tr td:first-child {
  padding-left: 15px !important;
}
.nonChargeableReport.nonChargeableTotalRow.table tr th:first-child, .nonChargeableReport.nonChargeableTotalRow.table tr td:first-child {
  padding-left: 0px !important;
}

.wipDetailReportGrid.wipReportGrid tr th:first-child, 
.wipDetailReportGrid.wipReportGrid tr td:first-child,
.wipDetailReportGrid.wipSummaryReportGrid tr th:first-child, 
.wipDetailReportGrid.wipSummaryReportGrid tr td:first-child,
.wipDetailReportGrid.wipTotalReportGrid tr th:first-child, 
.wipDetailReportGrid.wipTotalReportGrid tr td:first-child,
.feeIncomeSummaryGrid tr th.th.th:first-child, 
.feeIncomeSummaryGrid tr td.td.td:first-child,
.empIncomeSummaryGrid tr th.th.th:first-child, 
.empIncomeSummaryGrid tr td.td.td:first-child,

.table.invoiceRptGrid tr th:first-child, 
.table.invoiceRptGrid tr td:first-child{
  padding-left: 15px !important;
}
.feeIncomeSummaryGrid.feeIncomeDetailTotalRow tr th.th.th:first-child,
.empIncomeSummaryGrid.empIncomeSummaryTotalRow tr th.th.th:first-child {
  padding-left: 0 !important;
}

.App.PinkBlue .plm_layout_right .prn_row {
  margin-left: 0;
}

.plm_cmn_tbldiv table.feeIncomeSummaryGrid tr th:nth-child(1){
  width: 14% !important;
  min-width: 14% !important;
  max-width: 14% !important;
}
.plm_cmn_tbldiv table.feeIncomeSummaryGrid tr th:nth-child(2){
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important;
}

.reportDataContainer {
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.reportDataContainer .emp_topvaldiv .label-wraper{
  min-height: 10px !important;
}

.reportDataContainer table tr td {
  padding-right: 0px !important;
}

.reportDataContainer .plm_rp_rptort_col .label-wraper{
  margin-bottom: 0px !important;
}

.reportDataContainer .label-wraper label{
  padding: 0px !important;
}


.k-pdf-export .logo-inps {
  width: calc(32.33%);
  font-size: 14px !important;
}

.k-pdf-export *, .k-pdf-export .table .gap-cell{
font-size: 7px !important;
}
.k-pdf-export .table-title,
.k-pdf-export .header-column .table-header,
.k-pdf-export .header-row .table-header {
  padding: 4px 10px;
}
.k-pdf-export .table .gap-cell {
  padding: 4px 6px !important;
  min-height: 15px !important;
}

.k-pdf-export .plm_cmn_tbldiv .tblLabelHeader tr th:nth-child(1){
  padding: 4px 10px !important;
}
.k-pdf-export  .table td {
  padding: 0 3px;
}

.k-pdf-export .plm_cmn_tbldiv table tr th:nth-child(3) {
  width: 12% !important;
  min-width: 12% !important;
  max-width: 12% !important;
}

.k-pdf-export .table .totalRow .gap-cell {
  padding-left: 15px !important;
}

.k-pdf-export .back-purple {
   background: #a42998 !important;
   color: #fff !important;
}

.k-pdf-export .table td span{
  min-height: 20px !important;
  background: #f6f6f6 !important;
}

.k-pdf-export .table td .back-purple span{
  background: #a42998 !important;
}

.k-pdf-export .header-row .table-header.w-25{
  width: 23.5% !important;
  min-width: 23.5% !important;
  max-width: 23.5% !important;
}



.k-pdf-export table tr td {
  padding-right: 0px !important;
}

.k-pdf-export .plm_rp_rptort_col .label-wraper{
  margin-bottom: 0px !important;
}



.k-pdf-export .label-wraper label{
  padding: 0px !important;
  font-size: 8px !important;
}

.k-pdf-export .label-wraper span {
  font-size: 8px !important;
}

.timesheet_report_div .table td, .timesheet_report_div .totalRow th {
  padding: 0 3px 0px 0px !important;
}

.k-pdf-export .header-row .table-header.w-32 {
  width: 32% !important;
  min-width: 32% !important;
  max-width: 32% !important;
}

.k-pdf-export .nonChargeableRptContainer .header-row .table-header.w-32:nth-child(2) {
  min-width: 34% !important;
}

.k-pdf-export .totalWIPRptContainer .header-row .table-header.w-30:nth-child(1),
.k-pdf-export .clientWipSummaryRptContainer .header-row .table-header.w-30:nth-child(1) {
  min-width: 38% !important;
}

.k-pdf-export .feeIncomeSummaryRptContainer .header-row .table-header.w-32:nth-child(1){
  min-width: 34% !important;
}

.k-pdf-export .rwrapperFreeIncDetails .header-row .table-header.w-32:nth-child(1){
  min-width: 34% !important;
}

.k-pdf-export .emp_topvaldiv .label-wraper span{
  font-size: 8px !important;
}

.k-pdf-export .header-row{
  flex-wrap: nowrap !important;
}

.k-pdf-export .reportWrapper .totalRow {
  padding-left: 0px !important;
}

.k-pdf-export .totalRow .table .th-cell .gap-cell{
  padding: 8px 6px !important;
}

.k-pdf-export .nonChargeableTotalRow th:nth-child(1) {
  width: 28.15%;
}

.k-pdf-export .nonChargeableTotalRow th:nth-child(2)
,.k-pdf-export .nonChargeableTotalRow th:nth-child(3) {
  width: 11.5% !important;
}

.k-pdf-export .nonChargeableTotalRow th:nth-child(4) {
width: 9.65%;
}



.table .totalRow-wrap td{
  margin-top: 10px !important;
  color: #fff !important;
}

td.td.total.back-purple .gap-cell{
  background: #a42998 !important;
}

td.total.back-light-blue .gap-cell{
  background: #aec8d4 !important;
}

td.total.back-blue .gap-cell{
  background: #6093aa !important;
}

.totalRow-wrap td .gap-cell{
  font-weight: 700;
}

.nonChargeableRpt>tr, .wipDataRpt>tr, .clientWipSummaryRpt>tr, 
.totalWipDetailRpt>tr, .feeIncomeSummaryRpt>tr, .feeIncomeDetailRpt>tr,
.empWorkSummaryRpt>tr{
  display: none;
}

.wipDataRpt .header-row:nth-child(2) .table-header {
  margin-top: 10px !important;
}

.wipDetailReportGrid {
  width: 100% !important;
}

.wipDetailReportGrid tr th:nth-child(5), .wipDetailReportGrid tr td:nth-child(5){
  width: 50% !important;
  background: white;
}

.clientWipDetailsRpt .header-row .table-header{
  width: 32% !important;
  min-width: 32% !important;
  max-width: max-content;
}

.clientWipDetailsRpt .header-row .table-header:first-child{
  width: 22% !important;
  min-width: 22% !important;
  max-width: max-content;
}

.wipDetailReportGrid tr td:nth-child(5) .gap-cell{
  background: white !important;
}

.wipSummaryReportGrid tr th:nth-child(4), .wipDetailReportGrid tr td:nth-child(4){
  width: 60% !important;
}

.wipSummaryReportGrid tr td:nth-child(4) .gap-cell{
  background: white !important;
}

.reportDataContainer .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.reportDataContainer .nonChargeableReport.table tr th:first-child, 
.reportDataContainer .nonChargeableReport.table tr td:first-child {
  padding-left: 8px !important;
}

.reportDataContainer .nonChargeableReport.table tr.totalRow-wrap td:first-child  {
  padding-left: 4px !important;
}

.reportDataContainer .wipReportGrid.table tr th:first-child, 
.reportDataContainer .wipReportGrid.table tr td:first-child {
  padding-left: 10px !important;
}

.reportDataContainer .wipReportGrid.table tr.totalRow-wrap td:first-child  {
  padding-left: 4px !important;
}

.reportDataContainer .wipSummaryReportGrid.table tr th:first-child, 
.reportDataContainer .wipSummaryReportGrid.table tr td:first-child {
  padding-left: 10px !important;
}

.reportDataContainer .wipSummaryReportGrid.table tr.totalRow-wrap td:first-child  {
  padding-left: 4px !important;
}

.reportDataContainer .wipTotalReportGrid.table tr th:first-child, 
.reportDataContainer .wipTotalReportGrid.table tr td:first-child {
  padding-left: 10px !important;
}

.reportDataContainer .wipTotalReportGrid.table tr.totalRow-wrap td:first-child  {
  padding-left: 4px !important;
}

.reportDataContainer .reportWrapper.rwrapPdf table{
  margin-bottom: 10px !important;
}

.reportDataContainer .feeIncomeSummaryGrid.table tr th:first-child, 
.reportDataContainer .feeIncomeSummaryGrid.table tr td:first-child {
  padding-left: 10px !important;
}

.reportDataContainer .feeIncomeSummaryGrid.table tr.totalRow-wrap td:first-child  {
  padding-left: 4px !important;
}

.reportDataContainer .empIncomeSummaryGrid.table tr th:first-child, 
.reportDataContainer .empIncomeSummaryGrid.table tr td:first-child {
  padding-left: 10px !important;
}

.reportDataContainer .empIncomeSummaryGrid.table tr.totalRow-wrap td:first-child  {
  padding-left: 4px !important;
}

.k-pdf-export .table-header-blue{
  padding: 4px 10px !important;
}

.k-pdf-export table.table .th-cell>div{
  font-size: 7px !important;
}

.reportDataContainer .timesheetRpt table.table.table.table-stripe, 
.reportDataContainer .tdivexps_top .tb-wrap{
  margin-bottom: 5px !important;
}

.reportDataContainer .timesheetRpt table.table.table.table-stripe.mb0{
  margin-bottom: 0px !important;
}

.reportDataContainer .totalRow td > div.gap-cell,
.reportDataContainer .timesheet_report_div .totalRow .table .th-cell .gap-cell{
  padding: 4px 6px !important;
  min-height: 15px !important;
}

.reportDataContainer .timesheet_report_div .totalRow .table.mb20 tr th.th.totalFirstCol{
  width: 27% !important;
}

.reportDataContainer .timesheet_report_div .ctop {
  margin-bottom: 10px !important;
}

.reportDataContainer .mb20 {
  margin-bottom: 5px !important;
}

.reportDataContainer .timesheet_report_div .table td, 
.reportDataContainer .timesheet_report_div .totalRow th,
.reportDataContainer .timesheet_report_div .totalRow td{
  height: 15px !important;
}

.reportDataContainer .tdivexps_top .table td  {
  padding: 0 8px !important;
}

.reportDataContainer .total_exp_coldiv .totalRow .th-cell div{
  font-size: 7px !important;
}

.reportDataContainer .timesheetRpt .table tr td:first-child .table tr th:first-child, 
.reportDataContainer .timesheetRpt .table tr td:first-child .table tr td:first-child {
  padding-left: 9px !important;
}

.reportDataContainer .totalRow td:first-child div.gap-cell{
  padding-left: 9px !important;
}

.reportDataContainer .timesheet_report_div .header-row .table-header.w-32:nth-child(3){
  min-width: 35% !important;
}

.timesheet_report_div .totalRow .table th:first-child {
  padding-left: 8px !important;
}

.reportDataContainer .timesheet_report_div .totalRow .table .th-cell .gap-cell {
  padding: 4px 0px !important;
}

.costingSummaryGrid tr th, .costingSummaryGrid tr td {
  color: black !important;
  padding: 4px 0px !important;
}

.costingSummaryGrid tr th {
  text-align: left !important;
}

.costingSummaryGrid .costingSummaryHeader{
  padding: 0px !important;
}

.reportDataContainer .costingSummaryTotalRow{
  margin-bottom: 0px !important;
  text-align: left !important;
}

.reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow thead tr th .th-cell{
  display: table-cell;
}

.reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow th:nth-child(1){
  width: 49% !important;
}
.reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow th:nth-child(2){
  width: 26% !important;
}

.reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow th:nth-child(3){
  width: 13% !important;
}

.reportDataContainer .totalRow .tb-wrap .table.reportTbl.costingSummaryTotalRow{
  margin-bottom: 0px !important;
}

.reportDataContainer .totalRow .tb-wrap .table.reportTbl.costingSummaryTotalRow thead tr th{
  padding: 4px 1px !important;
}

.reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow thead{
  border: none !important;
}

.reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow tbody:empty {
  display: none !important;
}

.reportDataContainer .costingSummaryWrap .totalRow{
  margin-bottom: 0px !important;
}

.reportDataContainer table.reportTbl.costingSummaryGrid tr td:first-child {
  padding-left: 4px !important;
  width: 20% !important;
}

.dataTables_filter.row-wrap.fields_filter{
  gap: 8px !important;
  margin-top: 5px !important;
}

.dataTables_filter.row-wrap.fields_filter .form-control{
  border: none !important;
  background-color: #f6f6f6 !important;
  padding: 8px 4px !important;
}

.dataTables_filter.row-wrap.fields_filter .form-section:first-child 
.col-sm-12.col-xs-12.col-lg-12.px-0.input-wrap{
  padding-left: 13px !important;
}

.dataTables_filter.row-wrap.fields_filter .form-section 
.col-sm-12.col-xs-12.col-lg-12.px-0.input-wrap{
  padding-right: 10px !important;
}

#DataTables_Table_0_filter .form-control{
  background-image: url('/assests/img/thumb/Search-Icon.jpg');
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: right;
  min-height: 30px !important;
  background-size: 20px;
  padding-right: 5px;
  background-origin: content-box;
}

.reportDataContainer .invoiceGrid tr th, 
.reportDataContainer .invoiceGrid tr td {
  color: black !important;
  padding: 4px 6px !important;
  border: none !important;
}

.invoiceGrid {
  min-height: 20px !important;
  border: none !important;
}

.reportDataContainer .invoiceGrid.table thead tr th:first-child, 
.reportDataContainer .invoiceGrid.table tbody tr td:first-child,
.reportDataContainer .futureReportGrid.table thead tr th:first-child, 
.reportDataContainer .futureReportGrid.table tbody tr td:first-child{
  padding-left: 15px !important;

}

.reportDataContainer .invoiceGrid.table tbody tr:last-child{
  background-color: #a42998 !important;
  font-weight: bold;
}

.reportDataContainer .invoiceGrid.table tbody tr:last-child td{
  color: #fff !important;
}

.reportDataContainer .invoiceGrid.table tr:nth-last-child(3) td, 
.reportDataContainer .invoiceGrid.table tr:nth-last-child(2) td, 
.reportDataContainer .invoiceGrid.table tr:nth-last-child(1) td{
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important;
}

.reportDataContainer .futureReport .reportTbl.futureReportGrid tr th, 
.reportDataContainer .futureReport .reportTbl.futureReportGrid tr td {
  color: black !important;
  padding: 4px 0px !important;
}

.reportDataContainer .futureReport .totalRow .reportTbl.futureReportGrid tr th, 
.reportDataContainer .futureReport .totalRow .reportTbl.futureReportGrid tr td {
  border: none !important;
}

.reportDataContainer .futureReport {
  margin-top: 10px !important;
}

.reportDataContainer .futureReport .totalRow .futureReportTotalRow thead {
  border-bottom: none !important;
}

.reportDataContainer .invoice_report_table .header-row .table-header.w-32:nth-child(1) {
  min-width: 35% !important;
}

.reportDataContainer .invColor{
  background-color: #03C04A!important;
}

.reportDataContainer .futureReport .totalRow .tb-wrap {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.reportDataContainer .futureReport .totalRow .futureReportTotalRow tr th.futureReportHeader:nth-child(1){
  width: 72% !important;
  min-width: 72% !important;
  max-width: 72% !important;
}

.reportDataContainer .futureReport .totalRow .futureReportTotalRow tr th.futureReportHeader:nth-child(2) {
  width: 42% !important;
  min-width: 42% !important;
  max-width: 42% !important;
}

.reportDataContainer .futureReport .totalRow .futureReportTotalRow tr th.futureReportHeader:nth-child(4) {
  width: 11% !important;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid tr th, 
.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid tr td {
    border: none !important;
    padding: 4px 6px !important;
    text-align: left !important;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid .costingSummaryHeader{
  padding: 4px 6px !important;
  vertical-align: middle;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid thead tr th .th-cell{
  display: inline-block !important;
  text-align: left !important;
  padding-left: 0px !important;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid.table tbody tr td div{
  padding: 4px !important;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid.table tbody tr td div:empty{
  display: none !important;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid.table tbody tr:nth-child(odd) td div{
  background: #f6f6f6 !important;
}

.reportDataContainer .costingSummaryWrap .tb-wrap .reportTbl.costingSummaryGrid.table td + td + td + td, 
.reportDataContainer .costingSummaryWrap .tb-wrap .reportTbl.costingSummaryGrid.table th + th + th + th{
  text-align: left !important;
  max-width: 10% !important;
}

.reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryTotalRow {
  height: 18px !important;
}

.reportDataContainer .futureReport .reportTbl.futureReportGrid.table tbody tr td div{
  padding: 4px !important;
}

.reportDataContainer .futureReport .reportTbl.futureReportGrid.table tbody tr:nth-child(odd) td div{
  background: #f6f6f6 !important;
}

.reportDataContainer .futureReport .reportTbl.futureReportGrid.table tbody tr td div:empty{
  display: none !important;
}

.reportDataContainer .futureReport .tb-wrap .reportTbl.futureReportGrid.table td + td + td + td, 
.reportDataContainer .futureReport .tb-wrap .reportTbl.futureReportGrid.table th + th + th + th{
  text-align: left !important;
  max-width: 10% !important;
}

.reportDataContainer .ctop .ctop-flex .logo-inps:nth-child(3){
  padding-right: 10px !important;
}

.reportDataContainer .futureReport .tb-wrap .reportTbl.futureReportGrid.table tr th, 
.reportDataContainer .futureReport .tb-wrap .reportTbl.futureReportGrid.table tr td {
    border: none !important;
    padding: 4px 6px !important;
    text-align: left !important;
}

.reportDataContainer .futureReport .tb-wrap .reportTbl.futureReportGrid.table .futureReportHeader{
  padding: 4px 6px !important;
  vertical-align: middle;
}

.reportDataContainer .rwrapperFutTime .header-row .table-header.w-40 {
  min-width: 37% !important;
  max-width: 37% !important;
}

.reportDataContainer .rwrapperFutTime .totalRow{
  padding-left: 0px !important;
}

.reportDataContainer .futureReport .totalRow .tb-wrap{
  padding-left: 10px !important;
}

.reportDataContainer .futureReport .tb-wrap .table.futureReportTotalRow .futureReportHeader {
  padding: 6px 3px !important;
}

.reportDataContainer .futureReport .reportTbl.futureReportTotalRow {
  height: 12px !important;
  margin-bottom: 0px !important;
}




.print_invoice_report_container .reportDataContainer .div_container_invoice .invoiceGrid.table thead tr th{
  border-bottom: 3px solid black;
  font-weight: bold !important;
  padding: 4px 6px !important;
  color: black !important;
}

.print_invoice_report_container .reportDataContainer .div_container_invoice table.invoiceGrid.table tbody tr:nth-of-type(even) td{
  border: none !important;
  padding: 4px 6px !important;
  color: black !important;
}

.print_invoice_report_container .reportDataContainer .div_container_invoice table.invoiceGrid.table tbody tr:nth-of-type(odd) td{
  border: none !important;
  padding: 4px 6px !important;
  color: black !important;
}


.print_invoice_report_container .reportDataContainer .div_container_invoice .invoiceGrid.table tbody tr.tbody:last-child{
  background-color: #a42998 !important;
  font-weight: bold !important;
}

.print_invoice_report_container .reportDataContainer .div_container_invoice .invoiceGrid.table tbody tr:last-child td{
  color: #fff !important;
  background-color: #a42998 !important;
  font-weight: bold !important;
}

.print_invoice_report_container .reportDataContainer .div_container_invoice .invoiceGrid.table tbody tr:last-child td div{
  color: #fff !important;
}

.print_invoice_report_container .reportDataContainer .header-row .table-header.w-60{
  width: 57% !important;
  min-width: 57% !important;
  max-width: 57% !important;
}

.print_invoice_report_container .reportDataContainer .col-md-6.col6_custom,
.reportDataContainer .invoice_report_table  .col-md-6.col6_custom {
  padding-left: 10px !important;
}

.print_invoice_report_container .reportDataContainer .invoiceGrid tr:first-child, 
.print_invoice_report_container .reportDataContainer .invoiceGrid tr:nth-last-child(3), 
.print_invoice_report_container .reportDataContainer .invoiceGrid tr:last-child td:last-child {
  border: none !important;
}

.print_invoice_report_container .reportDataContainer .plm_cmn_tbldiv table.invoiceGrid tr th:nth-child(1), 
.print_invoice_report_container .reportDataContainer .plm_cmn_tbldiv table.invoiceGrid tr th:nth-child(3){
  width: 40% !important;
  max-width: 40% !important;
  min-width: 40% !important;
}


.print_invoice_report_container .reportDataContainer .costingSummaryWrap .reportTbl.costingSummaryGrid.table tbody tr:nth-child(odd) td div{
  background-color: #eeeeee !important;
}

.print_invoice_report_container .reportDataContainer .emp_topvaldiv{
  margin-top: 0px !important;
}

.print_invoice_report_container .reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow th:nth-child(1) {
  width: 52% !important;
}

.print_invoice_report_container .reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow th:nth-child(2){
  width: 27% !important;
}

.print_invoice_report_container .reportDataContainer .tb-wrap .table.reportTbl.costingSummaryTotalRow th {
  font-weight: bold !important;
}

.print_invoice_report_container .reportDataContainer .rwrapperClientCostSum .table-header {
  padding: 4px 15px !important;
}

.print_invoice_report_container .reportDataContainer .rwrapperClientCostSum .table-header .emp_topvaldiv .label-wraper{
  padding: 0px !important
}

.print_invoice_report_container .reportDataContainer .rwrapperFreeIncDetails .table-header .emp_topvaldiv .label-wraper{
  padding: 0px !important
}

.print_invoice_report_container .reportDataContainer .rwrapperClientCostSum .tb-wrap{
  margin-bottom: 0px !important;
}

.print_invoice_report_container .reportDataContainer  .costingSummaryWrap .col-12{
  margin-bottom: 5px !important;
}

.print_invoice_report_container .reportDataContainer .table-header .emp_topvaldiv .label-wraper span{
  font-size: 7px !important;
}

.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-child(odd) td div{
  background-color: #eeeeee !important;
}

.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(even) td.td.total.back-blue,
.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(even) td.td.total.back-blue div,
.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(odd) td.td.total.back-blue,
.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(odd) td.td.total.back-blue div{
  background-color: #6093aa !important;
  color: #fff !important;
}

.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(even) td.td.total.back-purple div,
.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(even) td.td.total.back-purple,
.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(odd) td.td.total.back-purple div,
.print_invoice_report_container .reportDataContainer .feeIncomeSummaryGrid.table tbody tr:nth-of-type(odd) td.td.total.back-purple{
  background-color: #a42998 !important;
  color: #fff !important;
}

.print_invoice_report_container .reportDataContainer .reportWrapper table.feeIncomeSummaryGrid.table tr th, 
.print_invoice_report_container .reportDataContainer .reportWrapper table.feeIncomeSummaryGrid.table tr td {
  padding: 4px 6px !important;
}

.print_invoice_report_container .reportDataContainer .reportWrapper table.feeIncomeSummaryGrid.table tr th:nth-child(1), 
.print_invoice_report_container .reportDataContainer .reportWrapper table.feeIncomeSummaryGrid.table tr td:nth-child(1){
  padding-left: 5px !important;
 }

 .print_invoice_report_container .reportDataContainer .reportWrapper table.feeIncomeSummaryGrid.table tr th .th-cell {
  padding-left: 5px !important;
  font-weight: bold !important;
 }

 .print_invoice_report_container .reportDataContainer .reportWrapper.rwrapperClientCostSum .costingSummaryWrap .tb-wrap 
 .costingSummaryGrid tr th .th-cell,
 .print_invoice_report_container .reportDataContainer .reportWrapper.rwrapperClientCostSum .costingSummaryWrap .tb-wrap 
 .costingSummaryTotalRow tr th:nth-child(1){
  padding-left: 4px !important;
  font-weight: bold !important;
}

.print_invoice_report_container .reportDataContainer .reportWrapper.rwrapperClientCostSum .costingSummaryWrap .tb-wrap 
 .costingSummaryGrid tr th:nth-child(1), 
 .print_invoice_report_container .reportDataContainer .reportWrapper.rwrapperClientCostSum .costingSummaryWrap .tb-wrap 
 .costingSummaryGrid tr td:nth-child(1) {
  padding-left: 11px !important;
 }

 .viewInvoices .plm_cmn_cardbox .col-6.col-6-invoicebtnContainer {
  display: flex !important;
  grid-gap: 5px !important;
  justify-content: space-between !important;
}