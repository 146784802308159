.feeincome_dtl_rowbox
{
    .plm_tsrept_top
    {
        height: calc(100% - 190px);
        .plm_cltrepo_top_inner
        {
            
                padding: 0 15px;
                .tblc_chklbl
                {
                    display: block;
                }
        }
    }
    
    .plm_cltrepo_btm
    {
        height: calc(100% - 210px) !important;
        position: relative;
        padding: 0 15px;
        .plm_cltrepo_btm_inrcontent 
        {
            overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
   
        }
    
    }
    
}

