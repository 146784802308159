.cltcost_sum_inner {
  .cst_tbox_popmdiv {
    .plm_cstpopupbox_wrap {
      .form-section {
        margin-bottom: 0px;
        .form-control {
          height: 30px;
        }
      }
      .popUpContainer {
        margin-top: 0px;
        overflow: auto;
        .tb-wrap {
          overflow-y: auto;
          padding: 0;
          height: 100%;
          margin: 0;
          min-height: 100%;
          max-height: 100%;
          .plm_cmn_tbldiv {
            height: 100%;
          }
          .table {
            border-right-width: 1px;
            border-right-style: solid;
            .tbody {
              > .td {
                height: 30px;
                min-width: 120px;
                > div {
                  height: 100% !important;
                  display: flex;
                  align-items: center;
                  padding-left: 8px;
                  padding-right: 8px;
                  position: relative;
                  .form-section {
                    .form-control {
                      border: 1px solid #ced4da;
                    }
                  }
                  .popUpContainer {
                    top: 30px;
                    left: 0;
                    overflow: auto;
                    width: 100%;
                    max-width: 100%;
                    // min-width: 100%;
                    min-width: 250px;
                    .table {
                      .tbody {
                        > .td {
                          height: 30px;
                          min-width: 70px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .plm_tsrept_top {
    height: calc(100% - 190px);
    .plm_cltrepo_top_inner {
      padding: 0 15px;
      .tblc_chklbl {
        display: block;
      }
    }
  }

  .plm_cltrepo_btm {
    height: calc(100% - 220px) !important;
    position: relative;
    padding: 0 15px;
    margin-top: 15px;
    .plm_cltrepo_btm_inrcontent {
      overflow-y: auto;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      padding: 0 15px;

      .cst_tbox_popmdiv {
        .plm_cstpopupbox_wrap {
          .form-section {
            margin-bottom: 0px;
            .form-control {
              height: 30px;
            }
          }
          .popUpContainer {
            margin-top: 0px;
            overflow: auto;
            .tb-wrap {
              overflow-y: auto;
              padding: 0;
              height: 100%;
              margin: 0;
              min-height: 100%;
              max-height: 100%;
              .plm_cmn_tbldiv {
                height: 100%;
              }
              .table {
                border-right-width: 1px;
                border-right-style: solid;
                .tbody {
                  > .td {
                    height: 30px;
                    min-width: 120px;
                    > div {
                      height: 100% !important;
                      display: flex;
                      align-items: center;
                      padding-left: 8px;
                      padding-right: 8px;
                      position: relative;
                      .form-section {
                        .form-control {
                          border: 1px solid #ced4da;
                        }
                      }
                      .popUpContainer {
                        top: 30px;
                        left: 0;
                        overflow: auto;
                        width: 100%;
                        max-width: 100%;
                        // min-width: 100%;
                        min-width: 250px;
                        .table {
                          .tbody {
                            > .td {
                              height: 30px;
                              min-width: 70px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .tb-wrap {
        overflow-y: auto !important;
        height: 100% !important;
        margin: 0 !important;
        min-height: 100% !important;
        max-height: 100% !important;
        .plm_cmn_tbldiv {
          height: 100%;
          .table {
            border-right-width: 1px;
            border-right-style: solid;
            border-left-width: 1px;
            border-left-style: solid;
            .th,
            .td {
              padding: 8px 3px !important;
            }
            .th .searchWrap > div > div {
              justify-content: flex-end;
            }
            .documentIc {
              width: 20px;
              height: 20px;
              position: absolute;
              right: 15px;
            }
          }
        }
      }
    }
  }
}
