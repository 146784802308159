.containerWrap{
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
}
.halfContainer{
    width: 50%;
    flex: 0 50%;
    max-width: 50%;
    padding: 0 15px;
}
.fullContainer{
    width: 100%;
    flex: 0 100%;
    max-width: 100%;
    padding: 0 15px;
}

.halfContainer .cardBox{
    width: 100%;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: none;
}
.full-container .cardBox{
    width: 100%;
    padding: 0;
}
.fullContainer .cardBox .card-body{
    overflow-y: auto;
    overflow-x: none;
    max-height: 500px;
}
.halfContainer .cardBox .card-head{
    padding: .5rem 1rem !important;
    border-bottom: 1px solid #dddddd;
}
.halfContainer .cardBox .card-title{
    margin-bottom: 0;
    font-size: 18px;
}
.halfContainer .cardBox .card-head,
.halfContainer .cardBox .card-body,
.halfContainer .cardBox .card-foot{

 padding: 1rem;
}

.form-tr{
    display: flex;
}
.form-td{
    flex: 1;
}

.searchWrap{
    .chWrap{
        display: flex;
        align-items: center;
        input[type="radio"], input[type="checkbox"]{
            width: 30px;
            height: 30px;
            margin: 5px 10px;
        }
    }
}
.form-section{
    margin-bottom: 1rem;
    label{
        margin-bottom: 0;
        font-size: 14px;
    }
}


.tDate{
    margin-bottom: 1rem;
}
.label-wraper{
    display: flex;
}


@media only screen and (max-width: 600px){
    .halfContainer{
        width: 100%;
        flex:0 100%;
        max-width: 100%;
    }
    .groupWrap{
        overflow: auto;
        margin-bottom: 1rem;
    }
    .rGroup{
        display: table-row;
        .col-3{
            display: table-cell;
        }
    }
    .listWrap{
        padding: .5rem !important;
    }
}