.plm_invwiz_stepw4
{
    .plm_inv_wiz_step4
    {
        .plm_addnarate
        {            
            position: absolute;
            top: 8px;
            right: 15px;
        }
    }
}
