@import "../../scss/variable";

.App.Dark {
  .listWrap .selected {
    background: #5c6bc0 !important;
    color: #ffffff !important;
  }
}

.App.Light .cardBox {
  .listWrap .selected {
    // background: $plm_thm_light_selectedRow_color !important;
    color: #ffffff !important;
  }
}
