.plm_authlist_div
{
    height: 100%;  
    .plm_cmn_cardbox
    {
        height: 100%;
        margin: 0px;
        border-radius: 5px;
        position: relative;
        .card-head
        {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;    
        }
        .card-body
        {
            height: calc(100% - 48px);
            padding: 0px;
            border: none;
            border-radius: 0px;
            
            .tb-wrap
            {
                overflow-y: auto;
                padding: 0;
                height: 100%;
                margin: 0;
                min-height: 100%;
                max-height: 100%;
                .plm_cmn_tbldiv
                {
                    height: 100%;
                }
            }
            .plm_cmn_cardbtn_div
            {
                position: absolute;
                top: 5px;
                right: 15px
            }
        }
        
    } 
    .plm_authrow
    {
        height: calc(100% - 10px);
        margin-top: 10px;
        margin-bottom: 20px;
        .plm_authcol_left
        {
            height: 100%;
            
            .plm_cmn_cardbox
            {
        height: 100%;
        margin: 0px;
        border-radius: 5px;
        position: relative;
        
        .card-head
        {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;    
        }
        .card-body
        {
            height: calc(100% - 48px);
            padding: 0px;
            border: none;
            border-radius: 0px;
            
            .tb-wrap
            {
                overflow-y: auto;
                padding: 0;
                height: calc(100% - 60px);
                margin: 0;
                min-height: calc(100% - 60px);
                max-height: calc(100% - 60px);
                .plm_cmn_tbldiv
                {
                    height: 100%;
                }
            }
            .card-foot
            {
                text-align: center;
            }
            .plm_cmn_cardbtn_div
            {
                position: absolute;
                top: 5px;
                right: 15px
            }
        }
        
            }
        }
        .plm_authcol_right
        {
            height: 100%;
            .vtime_cardiv
            {
                height: 100%;
                .form-group
                {
                    margin-bottom: 0px;
                    .form-section
                    {
                        margin-bottom: 5px;
                    }
                    .control-label
                    {
                        margin-bottom: 3px;
                    }
                }
                .vtime_topdiv
                {
                    // height: 50%;
                    .vtime_topsbox
                    {
                        display: flex;
                    }
                    .plm_cmn_cardbtn_alone_div
                    {
                        text-align: center;
                        margin-bottom: 5px;
                        button
                        {
                            border-radius: 4px !important;
                        }
                    }
                }
                .vtime_btmdiv
                {
                    height: calc(100% - 160px);   
                    .plm_vts_div
                    {
                        padding: 0 15px;
                        .weekBox
                        {
                            margin-bottom: 5px;
                        }
                        .plm_cmn_cardbtn_alone_div
                        {
                            text-align: center;
                        }
                    }
                }
                .plm_cmn_cardbox
                {
                height: 100%;
                margin: 0px;
                border-radius: 5px;
                position: relative;
                
                .card-head
                {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;  
                    padding: 5px 15px;  
                    
                }
                .card-body
                {
                    height: calc(100% - 48px);
                    padding: 0px;
                    border: none;
                    border-radius: 0px;
                    overflow: auto;
                    
                    
                }
                
                }
            }
            
           
            
        }
    }
    
}