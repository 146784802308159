.itemList {
  padding: 5px 10px;
  list-style-type: none;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* min-width: 250px; */
  /* max-width: 96%; */
  overflow-y: auto;
  margin-top: 10px;
  width: 100%;
  max-height: 200px;
}

.itemList > li:not(:last-child) {
  margin-bottom: 5px;
}

.dropdown {
  width: 100%;
  position: relative;
  cursor: pointer;
  display: flex;
}

.dropdownSelect {
  padding: 5px 15px;
  border-radius: 0px;
  background-color: #eeecec;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  height: 45px;
}

.dropdownList {
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  /* top: -160%; */
  top: -81px;
  left: 0%;
  right: 0;
  /* box-shadow: 0px 1px 2px 2px rgba(225, 225, 225, 0.4); */
  transition: opacity 0.2s linear, visibility 0.2s linear;
}

.dropdownSelect:hover .dropdownList {
  opacity: 1;
  visibility: visible;
}

.dropdownListItem {
  padding: 5px 15px;
  font-size: 16px;
  height: 40px;
  color: #000000;
}

