.plm_taxratelist_div
{
    height: 100%;  
    position: relative;
    .tax_rate_inner
    {
        height: 100%;
        margin: 0px;
        border-radius: 5px;        
        border: 1px solid #e6e6e6;
        .tb-wrap
        {
            overflow-y: auto;
            padding: 0;
            height: 100%;
            margin: 0;
            min-height: 100%;
            max-height: 100%;
            .plm_cmn_tbldiv
            {
                height: 100%;
                .table
                {                   
                    .th-cell 
                    {
                        span
                        {
                            margin-bottom: 0;
                            font-size: 1rem;
                            font-weight: 500;
                            color: #6600ff;
                            font-family: "Fira Sans", sans-serif;
                        }
                    }
                    .th
                    {
                        .searchWrap
                        {
                            div
                            {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }


        
      
        
    }
    .plm_cmn_nocard
    {
        position: absolute;
        top: 10px;
        right: 30px; 
    }
    .hr-divider
    {
        display: none;
    }
}


.App.PinkBlue{
    table{
        th{
            .th-cell span{
                color: #475F7B !important;
                &.sortIc{
                    position: relative;
                    height: 22px;
                    width: 20px;
                    .asc{
                     right: 5px;
                    }
                    .desc{
                        right: 5px;
                    }
                  }
            }
        }
        
    }
}
