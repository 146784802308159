.plm_leftnav_wrap {
  padding: 5px;
  border-radius: 5px;
  .rpt_title {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 5px;
  }
  ul.left_reportmenu {
    margin-bottom: 5px;
    line-height: 22px;
    .renamingBtnWrap {
      position: absolute;
      right: 20px;
      top: 5px;
    }
    > li {
      > .rpt_title {
        font-weight: 600;
        font-size: 16px;

        span i {
                 font-size: 32px;
                 position: absolute;
                 top: 3px;
                 right: 5px;
               }

      }
      > a {
        font-size: 16px;
        white-space: nowrap;
        width: 100%;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      > ul {
        margin-bottom: 3px;
        line-height: 22px;
        > li {
          > a {
            font-size: 16px !important;
            white-space: nowrap;
            width: 100%;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            
          }
          > a.active {
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
    }
  }
}

/*pinkblue theme*/
.App.PinkBlue {
  .plm_leftnav_wrap {
    .left_reportmenu {
      margin-bottom: 0px;
      li {
        position: relative;
        .left_reportmenu {
          li {
            a {
              padding: 10px 12px;
              font-size: 12px;
            }
          }
        }
        .renamingBtnWrap {
          top: 10px;
        }
        p {
          padding: 8px 10px;
          background: #eeecec;
          margin-bottom: 0px;
        }
        a {
          background: #eeecec;
          padding: 9px 10px !important;
          padding-left: .8rem !important;
          color: #6093AA !important;
          font-size: 16px;
          font-weight: 400;
          &.active {
            background: #6093AA;
            // background: #AFC9D4 !important;
            color: #ffffff !important;
            font-weight: 600;
          }
        }
        .renamingBtnWrap {
          .linkPrimary {
            background-color: transparent;
            color: #6093AA !important;
          }
        }
        a.active ~ .renamingBtnWrap {
          .linkPrimary {
            background-color: transparent;
            color: #ffffff !important;
          }
        }
      }
    }
  }
}

.PinkBlue.App{
  .reportMenuWrapper{
    .left_reportmenu{
      .rpt_title{
        //font-size: clamp(0.8em, 1vw, 1rem) !important;
        padding: .4rem .8rem !important;
        line-height: 1.7em;
        position: relative;
        height: 40px;
      }
     .circle-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 2rem;
      right: 5px;
      .material-icons{
        //font-size: 34px;
        font-size: 2rem;
      }
     }
      
      li{

        a{
          //font-size: clamp(0.8em, 1vw, 1rem) !important;
          padding: .4rem .8rem !important;
          line-height: 1.7em;
          height: 40px;
          &.active{
            background: #AFC9D4 !important;
            font-weight: 400 !important;
          }
        }
      }
    }
  }
}
