.tb-btn {
  display: flex;
  justify-content: flex-end;
}
.tb-btn button {
  margin-left: 10px;
}
table th,
table td {
  font-family: "Nunito", sans-serif;
}

.td-icon {
  display: flex;
  justify-content: left;
}

.reportWrapper {
  table.table {
    tbody {
      tr.tbody {
        td.td {
          &:nth-child(1) {
            &.selected {
              border-left: 5px solid #2c9cd5 !important;
            }
          }
        }
      }
    }
  }
}

table.table {
  .th-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form-section {
      margin: 0 auto;
    }
  }
  tbody {
    tr.tbody {
      td.td {
        &:nth-child(1) {
          &.selected {
            border-left: 5px solid #2c9cd5 !important;
          }
        }
      }
    }
  }
}
// table td:nth-child(1).selected{
//     width:5px;
//     background-color: #2c9cd5!important;
// }

table td input[type="checkbox"] {
  margin-right: 40px;
}

table td .gridInput {
  border: none;
}
