$topmenu-color: #2cc185;
$modal-bgcolor: #e5f3d5;

$btnH: #4b3168;
$sdBG2: #201e29;
$sdBG1: #fcfcfc;
$sdBG3: #5c6bc0;

// $sdBG3: #4b3168;
$sdBG: #161b2b;
$BGColor: #4b3168;
$sdAction: #ffb21d;
$success: #afca0b;
$successBG: #e9efc6;

/* Theme Light Color Code starts */
$plm_thm_light_basecolor: #5c6bc0;
$plm_thm_light_bgcolor: #ffffff;
$plm_thm_light_fontcolor: #212121;
$plm_thm_light_svgicon: #ffffff;
$plm_thm_light_alink: #405189;
$plm_thm_light_lnavbgcolor: #e1e1e1;
$plm_thm_light_menubgcolor: #5c6bc0;
$plm_thm_light_menulinkcolor: #ffffff;
$plm_thm_light_menulinkhoverfocuscolor: #212121;
$plm_thm_light_menubtnbgcolor: #303f94;
$plm_thm_light_menubtncolor: #ffffff;
$plm_thm_light_submenubgcolor: #ffffff;
$plm_thm_light_submenulinkcolor: #5c6bc0;
$plm_thm_light_submenulinkhoverfocus: #212121;
$plm_thm_light_btnbgcolor: #5c6bc0;
$plm_thm_light_btnbordercolor: #5c6bc0;
$plm_thm_light_btncolor: #ffffff;
$plm_thm_light_bordercolor: #e6e6e6;
$plm_thm_light_tablnkbgcolor: #949494;
$plm_thm_light_tablnkcolor: #ffffff;
$plm_thm_light_tablnk_actvbgcolor: #505886;
$plm_thm_light_tablnk_hovfcscolor: #ffffff;
$plm_thm_light_pageheadcolor: #ffffff;
$plm_thm_light_lstacv_bgcolor: #505886;
$plm_thm_light_lstacv_color: #ffffff;
$plm_thm_light_cardheadbgcolor: #e1e1e1;
$plm_thm_light_frmctrlbgcolor: #ffffff;
$plm_thm_light_frmctrlbordercolor: #ced4da;
$plm_thm_light_frmctrlcolor: #212121;
$plm_thm_light_frmctrlbgcolor_focus: #ffffff;
$plm_thm_light_frmctrlbordercolor_focus: #80bdff;
$plm_thm_light_tablebordercolor: #e6e6e6;
$plm_thm_light_tablebgcolor: #ffffff;
$plm_thm_light_table_thcolor: #212529;
$plm_thm_light_table_tdcolor: #212529;
$plm_thm_light_selectedRow_color: #949494;

/* Theme Light Color Code ends */

/* Theme Darkyl Color Code starts */
$plm_thm_darkyl_basecolor: #fda006;
$plm_thm_darkyl_logobg: #ffffff;
$plm_thm_darkyl_bgcolor: #222840;
$plm_thm_darkyl_svgicon: #ffffff;
$plm_thm_darkyl_alink: #405189;
$plm_thm_darkyl_fontcolor: #9c9fa6;
$plm_thm_darkyl_menubgcolor: #131633;
$plm_thm_darkyl_menulinkcolor: #d0cfcf;
$plm_thm_darkyl_menulinkhoverfocuscolor: #fda006;
$plm_thm_darkyl_menubtnbgcolor: #dd8010;
$plm_thm_darkyl_menubtncolor: #131633;
$plm_thm_darkyl_btnbgcolor: #fda006;
$plm_thm_darkyl_btnbordercolor: #fda006;
$plm_thm_darkyl_btncolor: #ffffff;
$plm_thm_darkyl_bordercolor: #313452;
$plm_thm_darkyl_tablnkbgcolor: #42455c;
$plm_thm_darkyl_tablnkcolor: #ffffff;
$plm_thm_darkyl_tablnk_actvbgcolor: #04040b;
$plm_thm_darkyl_tablnk_hovfcscolor: #ffffff;
$plm_thm_darkyl_pageheadcolor: #131633;
$plm_thm_darkyl_lstacv_bgcolor: #30324a;
$plm_thm_darkyl_lstacv_color: #ffffff;
$plm_thm_darkyl_cardheadbgcolor: #131633;
$plm_thm_darkyl_frmctrlbgcolor: #2b2e4c;
$plm_thm_darkyl_frmctrlbordercolor: #9c9fa6;
$plm_thm_darkyl_frmctrlcolor: #e9e6e6;
$plm_thm_darkyl_frmctrlbgcolor_focus: #2b2e4c;
$plm_thm_darkyl_frmctrlbordercolor_focus: #80bdff;
$plm_thm_darkyl_tablebordercolor: #313452;
$plm_thm_darkyl_tablebgcolor: #3c4062;
$plm_thm_darkyl_table_thcolor: #d0cfcf;
$plm_thm_darkyl_table_tdcolor: #d0cfcf;
/* Theme Darkyl Color Code ends */

$select_color: #008000;
