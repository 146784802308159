.totalwipdtl_rowbox
{
    .plm_cltrepo_top
    {
        .plm_cltrepo_top_inner
        {
            padding: 0 15px;
            .tblc_chklbl
            {
                display: block;
            }
        }
    }
    .plm_cltrepo_btm
    {
        height: calc(100% - 210px) !important;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        .plm_cltrepo_btm_inrcontent
        {           
            position: absolute;
            width: 100%;
            top: 0;
        
            .tb-wrap
            {
                
                overflow-y: auto;               
                height: 100%;
                margin: 0;
                min-height: 100%;
                max-height: 100%;
                .plm_cmn_tbldiv
                {
                    .table
                    {
                        border-right-width: 1px;
                        border-right-style: solid;
                        border-left-width: 1px;
                        border-left-style: solid;
                        .th, .td
                        {
                            min-width: 170px !important;
                            max-width: 170px !important;
                            padding: 8px 3px !important;
                        }
                    }
                }
            }
    }
    }
}