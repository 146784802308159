@import "../../../scss/variable";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@media print {
  body {
    width: 1200px;
  }
  div[class|="col-"] {
    float: left;
  }
  .col-sm-6 {
    width: 50%;
  }
}

.App.PinkBlue
  .cardBox
  .card-body
  table
  tbody
  tr:nth-of-type(even)
  td.SubmittedContainer,
.App.PinkBlue
  .cardBox
  .card-body
  table
  tbody
  tr:nth-of-type(even)
  td.AuthorisedContainer,
.SubmittedContainer,
.AuthorisedContainer {
  background-color: #0000ff !important;
  color: #ffffff !important;
  // height: 30px;
  // // width: 100px;
  // display: block;
}

.plm_rpscreen_list_div {
  height: 100%;
  .rpt_title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .left_reportmenu {
    > li {
      font-size: 13px;
    }
  }
  .plm_cmn_cardbox {
    height: 100%;
    margin: 0px;
    border-radius: 5px;
    position: relative;
    .card-head {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .card-body {
      height: calc(100% - 48px);
      padding: 0px;
      border: none;
      border-radius: 0px;
      .hr-divider {
        display: none;
      }
      .col_rps_innerright {
        .col_rps_inn_top {
          height: calc(100% - 55px);
          .pln_cmnreport_rowbox {
            .plm_rp_partner_col {
              .vtime_topsbox {
                display: flex !important;
              }
            }

            .plm_rp_client_col {
              height: calc(100% - 86px);
              .plm_clt_reprt_tbldiv {
                .emp_topvaldiv {
                  padding: 0 15px;
                }

                .plm_carep_btm {
                  height: calc(100% - 64px);
                  > div {
                    height: 100%;
                  }
                  .tb-wrap {
                    overflow-y: auto;
                    padding: 0;
                    height: 100%;
                    margin: 0;
                    min-height: 100%;
                    max-height: 100%;
                    .plm_cmn_tbldiv {
                      height: 100%;
                    }
                  }
                }
              }
            }
            .plm_rp_vboxes_col {
              .pln_vboxes_div {
                display: flex;
              }
            }
            .plm_rp_tsreport_col {
              .plm_rp_tsreport_btn_div {
                padding: 0 15px;
              }
            }
            .plm_rp_tsrepgrid_col {
              margin-top: 5px;
              height: calc(100% - 200px);

              .plm_rp_tsreport_idiv {
                padding: 0 15px;
                overflow-y: auto;

                .total_timeslot_content {
                  .plm_timrcoldiv {
                    .trow_time_bottom {
                      .totalRow {
                        padding: 0;
                        .plm_cmn_tbldiv {
                          .table {
                            .th {
                              min-width: 20px !important;
                              max-width: 20px !important;
                              .th-cell {
                                min-height: 40px;
                              }
                            }
                            .th:last-child {
                              max-width: 30px !important;
                              min-width: 30px !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .timesheet_report_rowbox {
            height: initial !important;

            .total_timeslot_content {
              .plm_timrcoldiv {
                .plm_cmn_tbldiv {
                  .table {
                    .th,
                    .td {
                      min-width: 20px !important;
                      padding: 10px 2px 5px 2px !important;
                    }
                  }
                }
              }
              .grand_total_div {
                .totalRow {
                  padding: 0;
                  .plm_cmn_tbldiv {
                    .table {
                      .th,
                      .td {
                        min-width: 20px !important;
                        padding: 10px 2px 5px 2px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .col_rps_inn_btm {
          margin-top: 30px;

          .plm_cmn_cardbtn_div {
            position: absolute;
            left: 0px;
            width: 100%;
            bottom: 45px;
            background: #ffffff;
            padding-bottom: 5px;
            padding-top: 5px;
            margin-top: 0px;
            .report_btn_rowdiv {
              display: flex;
              align-items: center;
              width: 100%;
              .form-group {
                margin-bottom: 0;
                display: flex;
                align-items: center;
                label,
                .form-section {
                  margin-bottom: 0;
                }
                .control-label {
                  margin-right: 5px;
                }
              }
              > div {
                &:not(:last-child) {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}
// table thead{
//     background: #e6c9ff;
// }
.th-cell {
  text-align: left;
}
td {
  padding: 5px 5px;
}
.plm_carep_top {
  margin: 15px 0;
}
.plm_carep_top .col-6:nth-child(1) {
  color: #008080;
  font-weight: 700;
}
.plm_carep_top .col-6:nth-child(2) {
  color: #908001;
  font-weight: 700;
}
.plm_rp_rptort_col .col-xs-4:nth-child(2) p {
  font-size: 30px;
  margin: 5px 0;
}
.plm_rp_rptort_col {
  padding: 15px;
  .label-wraper {
    margin-bottom: 10px;
  }
}
.totalRow .tb-wrap {
  min-height: 50px !important;
}

.plm_carep_btm table {
  width: 100% !important;
  // border-collapse: collapse;
  // border-top: 2px solid #808080;
  // border-bottom: 2px solid #808080;
}
.plm_carep_btm table thead,
.reportTbl thead,
.reportTbl .th {
  // background: #e6c9ff;
  border-bottom: 2px solid #808080;
}
.plm_carep_btm table thead th {
  padding: 0;
  vertical-align: top;
}
.rHeader {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}
.rHeader .head-box {
  width: 33%;
  min-width: 200px;
  display: block;
}

.th-cell {
  text-align: left;
}
.th-cell span {
  word-break: break-word;
}
.plm_carep_btm table td {
  padding: 10px 5px;
}
.plm_carep_btm table {
  table-layout: fixed;
}
// .plm_carep_btm table td:nth-child(2), .plm_carep_btm table td:nth-child(2),
// .plm_carep_btm table th:nth-child(2), .plm_carep_btm table th:nth-child(2)
// {
//   width: 15%; }

.plm_carep_top {
  margin: 15px 0;
}
.plm_carep_top .label-1 label,
.plm_carep_top .label-1 span.l-value-wrap,
.labelWrapper1 {
  color: #008080;
  font-weight: 700 !important;
}
.plm_carep_top .label-2 label,
.plm_carep_top .label-2 span.l-value-wrap,
.labelWrapper {
  color: #908001;
  font-weight: 700 !important;
}
.empLabel {
  font-size: 18px;
}
.plm_rp_rptort_col .col-xs-4:nth-child(2) p {
  font-size: 30px;
  margin: 5px 0;
}
.plm_rp_rptort_col {
  padding: 0;
  margin-left: 30px;
  margin-right: 30px;
  font-family: Arial, Helvetica, sans-serif;
}
.w-33 {
  width: 33% !important;
}
.weeklyTotal table tr th:nth-child(1) {
  min-width: 100px !important;
  min-width: 100px !important;
  width: 100px;
  padding: 10px 2px 5px 2px !important;
}

.plm_nonCh_grid .tb-wrap .table .th,
.plm_wip_grid .tb-wrap .table .th,
.wipHeader {
  min-width: 255px !important;
  max-width: 255px !important;
  width: 255px;
  padding: 8px 3px !important;
  word-break: break-word;
}

.reportContainer {
  .wipHeader {
    .th-cell {
      padding-left: 10px !important;
    }
  }
  .plm_nonCh_grid {
    margin-top: 0 !important;
    .lbl-min-width.label-2 {
      padding-right: 12px !important;
    }
    .totalRow {
      .table {
        margin-bottom: 0 !important;
      }
    }
  }
  .plm_wip_grid .tb-wrap .table {
    .th {
      &.blankCell {
        .th-cell {
          display: none;
        }
      }
    }
    td {
      &:nth-child(5) {
        span {
          display: none !important;
        }
      }
    }
  }
  .plm_nonCh_grid .tb-wrap .table .th,
  .wipHeader,
  .plm_nonCh_grid .tb-wrap .table .td {
    &:nth-child(1) {
      min-width: 18.66% !important;
      max-width: 18.66% !important;
      width: 18.66% !important;
    }
    &:nth-child(2) {
      min-width: 16.66% !important;
      max-width: 16.66% !important;
      width: 16.66% !important;
    }
    &:nth-child(3) {
      min-width: 16.66% !important;
      max-width: 16.66% !important;
      width: 16.66% !important;
    }
    &:nth-child(4) {
      min-width: 8.33% !important;
      max-width: 8.33% !important;
      width: 8.33% !important;
    }
    &:nth-child(5) {
      min-width: 16.66% !important;
      max-width: 16.66% !important;
      width: 16.66% !important;
    }
    &:nth-child(6) {
      min-width: 16.66% !important;
      max-width: 16.66% !important;
      width: 16.66% !important;
    }
    &:nth-child(7) {
      span {
        display: none !important;
      }
    }
    &.blankCell {
      span {
        display: none !important;
      }
    }
  }
}

.wipSummaryTbl .tb-wrap .table .th,
.wipTotalTbl .tb-wrap .table .th,
.wipSummaryHeader {
  min-width: 140px !important;
  max-width: 140px !important;
  width: 140px;
  padding: 8px 3px !important;
  word-break: break-word;
}

.wipTotalTbl {
  .lbl-min-width.label-2.col-md-2 {
    width: 25% !important;
  }
  .lbl-min-width.label-2 {
    &:nth-child(2) {
      padding-left: 0px !important;
    }
  }
  .totalRow ~ .lbl-min-width.label-2 {
    padding-right: 12px !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
    width: 25% !important;
  }
  .totalRow ~ .lbl-min-width.label-2 ~ .lbl-min-width.label-2 {
    padding-left: 8px !important;
  }
  .col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;

    &:not(.totalRow) {
      .tb-wrap {
        .table.reportTbl,
        table {
          border-spacing: 0 !important;
          margin-left: 0 !important;
          tr {
            td {
              &:nth-child(1) {
                padding-left: 0 !important;
              }
              &:nth-child(5) {
                span {
                  display: none !important;
                }
              }
              padding: 0 10px !important;
            }
            th {
              .th-cell {
                min-height: 40px;
                // padding-left: 10px !important;
              }
              &.wipSummaryHeader {
                &:nth-child(1) {
                  min-width: 16.66% !important;
                  width: 16.66% !important;
                  max-width: 16.66% !important;
                  &[colspan="2"] {
                    min-width: 33.32% !important;
                    width: 33.32% !important;
                    max-width: 33.32% !important;
                  }
                }
                &[colspan="2"] {
                  min-width: 33.32% !important;
                  width: 33.32% !important;
                  max-width: 33.32% !important;

                  & ~ &:nth-child(2) {
                    min-width: 8.33% !important;
                    width: 8.33% !important;
                    max-width: 8.33% !important;
                  }

                  & ~ &:nth-child(4) {
                    min-width: 41.69% !important;
                    width: 41.69% !important;
                    max-width: 41.69% !important;
                  }
                }

                &:nth-child(2) {
                  min-width: 16.66% !important;
                  width: 16.66% !important;
                  max-width: 16.66% !important;
                }
                &:nth-child(3) {
                  min-width: 8.33% !important;
                  width: 8.33% !important;
                  max-width: 8.33% !important;
                }
                &:nth-child(4) {
                  min-width: 16.66% !important;
                  width: 16.66% !important;
                  max-width: 16.66% !important;
                }
              }
            }
          }
        }
      }
    }

    &.totalRow {
      .tb-wrap {
        .table.reportTbl {
          tr {
            th.wipSummaryHeader {
              &:nth-child(1) {
                width: 33.33% !important;
                min-width: 33.33% !important;
                max-width: 33.33% !important;
              }
              &:nth-child(2) {
                width: 8.33% !important;
                min-width: 8.33% !important;
                max-width: 8.33% !important;
                .th-cell {
                  padding-left: 10px !important;
                }
              }
              &:nth-child(3) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
                .th-cell {
                  padding-left: 10px !important;
                }
              }
              &:nth-child(4) {
                width: 41.69% !important;
                min-width: 41.69% !important;
                max-width: 41.69% !important;
              }
            }
          }
        }
      }
      & + .lbl-min-width {
        &:nth-child(2) {
          padding-left: 0px !important;
        }
      }
    }
  }
}

.futureReport .tb-wrap .table .th,
.futureReportHeader {
  min-width: 150px !important;
  max-width: 150px !important;
  width: 150px !important;
  padding: 8px 3px !important;
  word-break: break-word;
}

.feeincome_dtl_rowbox .tb-wrap .table .th,
.feeincome_dtl_rowbox .tb-wrap .table .td {
  &:nth-child(1),
  &:nth-child(8) {
    min-width: 12% !important;
    width: 12%;
    max-width: 12% !important;
  }
  &:nth-child(10) {
    min-width: 6% !important;
    width: 6%;
    max-width: 6% !important;
  }
  min-width: 10% !important;
  width: 10%;
  max-width: 10% !important;
  padding: 8px 3px !important;
  word-break: break-word;
}

.costingSummaryWrap .tb-wrap .table .th,
.costingSummaryHeader {
  min-width: 160px !important;
  max-width: 160px !important;
  width: 160px;
  padding: 8px 3px !important;
  word-break: break-word;
}

.plm_wip_trow {
  width: 100%;
  border: 1px solid black;
}

.plm_income_grid .tb-wrap .table .incomeGridHeader,
.plm_income_grid .tb-wrap .table .td,
.incomeGridHeader {
  min-width: 70px !important;
  width: 70px;
  max-width: 70px !important;
  padding: 8px 3px !important;
  word-break: break-word;
}

.reportContainer {
  table.reportTbl thead {
    .incomeGridHeader {
      .th-cell {
        padding-left: 10px !important;
      }
    }
  }
}

.reportContainer .nonSubmittedGrid .tb-wrap {
  .plm_cmn_tbldiv {
    overflow: auto !important;
  }
  .reportTbl {
    .th,
    .td {
      min-width: 110px;
      width: 110px;
    }
  }
}

.invoiceGrid {
  border: 1px solid black;
  min-height: 1100px;
  tr:nth-last-child(3),
  tr:nth-last-child(2),
  tr:nth-last-child(1) {
    td {
      max-height: 20px;
      height: 20px;
      min-height: 20px;
    }
  }
  .td {
    border: none !important;
  }
  tr:first-child,
  tr:nth-last-child(3),
  tr:last-child td:last-child {
    border-top: 1px solid black !important;
  }
  tr td:nth-child(1),
  .invoiceGrid tr th:nth-child(1) {
    min-width: 500px;
    border-right: 1px solid black !important;
  }
}

.invoiceTitle {
  font-size: large;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
}

.borderWrapper {
  border: 1px solid black;
  margin: 2px 2px 2px 2px;
  padding: 5px;
}

.negativeCost {
  color: red;
}

.Dark {
  .reportTbl {
    .th {
      background: $plm_thm_darkyl_basecolor;
      color: $plm_thm_darkyl_fontcolor !important;
    }
  }
}

.Light {
  .reportTbl {
    .th {
      background: transparent !important;
      color: $plm_thm_light_fontcolor !important;
      border-color: $plm_thm_light_bordercolor !important;
    }
  }
}

.green {
  .reportTbl {
    .th {
      background: #4dff4d;
      color: #000000 !important;
    }
  }
}

.blue {
  .reportTbl {
    .th {
      background: #80dfff;
      color: #000000 !important;
    }
  }
}

.red {
  .reportTbl {
    .th {
      background: #ff4d4d;
      color: #000000 !important;
    }
  }
}

.rowMargin.row {
  margin-left: 0px;
  margin-right: 0px;
}

.PinkBlue {
  .sticky-top-box {
    background: #fff;
    // position: fixed;
    position: sticky;
    padding-bottom: 5px;
    top: 0;
    left: 255px;
    right: 45px;
    z-index: 2;

    .plm_cltrepo_top.row {
      margin-left: 0 !important;
    }

    .col-1 {
      flex: 0 0 calc(8.33333% + 10px);
      max-width: calc(8.33333% + 10px);
      padding-right: 10px;
      button {
        width: 100%;
      }
    }
  }
  .sticky-top-box ~ div {
    margin-top: 0px;
  }

  .cardBox .card-body .weeklyGrid .trow_time_top {
    & > table {
      tr {
        td[width="50%"] {
          &:nth-child(1) {
            width: 58.33% !important;
          }
          &:nth-child(2) {
            width: 41.66% !important;
          }
        }
      }
    }
  }

  .cardBox .card-body .weeklyGrid .trow_time_top,
  .tdivexps_top {
    min-height: initial !important;
  }

  .reportContainer {
    .weeklyGrid {
      .tdivexps_top {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .totalRow {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .tb-wrap {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      & > .col-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .tb-wrap {
      margin-bottom: 0 !important;
      table {
        th {
          position: relative !important;
          z-index: 0 !important;
          top: initial !important;
        }
      }
    }
  }

  .plm_cltrepo_top.row {
    .col-3 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
  .plm_cltrepo_top_inner {
    & > .row {
      margin-right: -10px !important;
      & > .col-6 {
        padding-left: 10px !important;
        padding-right: 10px !important;
        &.freeHeader-box {
          padding-right: 12px !important;
        }
        &:nth-child(1) {
          padding-left: 5px !important;
        }
        & > .row {
          margin-right: -10px !important;
          margin-left: -5px !important;
        }
      }
      & > .col-3 {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    .react-datepicker-wrapper {
      margin-bottom: 1rem !important;
    }
    .row {
      .col-12 {
        margin-top: 0 !important;
      }
    }
  }
  .plm_tsrept_top {
    height: initial !important;
  }
  tr.labelWrapper1 {
    background-color: initial;
  }
  .empLabel {
    margin-bottom: 10px;
  }
  &.row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .plm_wip_trow {
    border: none;
    margin: 10px -15px;
  }
  .plm_rp_client_col,
  .plm_cltrepo_btm,
  // .plm_rp_tsrepgrid_col,
  .plm_rp_tsreport_idiv {
    //min-height: 53vh;
    height: calc(100vh - 325px) !important;
    overflow-y: auto !important;
  }
  .tb-wrap {
    height: auto !important;
    min-height: initial !important;
  }

  .reportContainer {
    .bgColor {
      background-color: #6093aa;
      color: #ffffff;
    }
    .totalRow,
    .bgColorRow {
      table.reportTbl {
        tr {
          th,
          td {
            background-color: #6093aa !important;
            span {
              color: #ffffff !important;
              font-size: 11px !important;
              padding-left: 5px !important;
              font-weight: 900 !important;
              // white-space: nowrap !important;
            }
            .th-cell,
            .td-cell {
              min-height: 40px;
              span {
                color: #ffffff !important;
                // white-space: nowrap !important;
              }
            }
          }
        }
      }
    }
  }

  &.reportWrapper {
    color: #6093aa;

    .form-group,
    .form-section {
      margin-bottom: 20px;
    }

    .sticky-top-box ~ div {
      margin-top: 15px;
    }

    .plm_cstpopupbox_wrap {
      .gridInput.form-control {
        border: none !important;
      }
      .popUpContainer {
        table {
          tr {
            th,
            td {
              &:nth-child(1) {
                width: 80px;
              }
            }
          }
        }
      }
    }

    .plm_rp_tsrepgrid_col {
      margin-top: 20px !important;
      table {
        tr.labelWrapper {
          td {
            color: #6093aa !important;
            font-weight: 600;
            font-size: 14px;
            // color: #ffffff ;
          }
        }
      }
    }
    .plm_cltrepo_btm_inrcontent {
      position: relative;
    }
    .plm_income_grid {
      table {
        th,
        td {
          max-width: 0px !important;
        }
      }
    }
    label {
      // background-color: #6093AA;
      // color: #ffffff !important;
      font-weight: 600;
      width: 100%;
      padding: 8px;
      font-size: 16px;
    }

    .plm_hdng_styl5,
    .plm_hdng_styl4 {
      font-weight: 600;
      font-size: 15px;
      padding: 5px;
    }

    .label-wraper {
      padding: 0px 0px;
      width: 60% !important;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      label {
        font-weight: 600;
        font-size: 12px;
        padding: 8px 10px;
      }
    }
  }
  .label-2 {
    label {
      padding: 8px;
    }
  }
  .radio_inner {
    .tblc_chklbl {
      label {
        color: #6093aa !important;
        background: initial !important;
      }
    }
  }

  .reportTbl {
    .th {
      color: #6093aa;
      border-bottom: none;
    }
    .td {
      color: #6093aa;
    }
  }

  .daysGridWrapper {
    .reportTbl {
      table-layout: fixed;
    }
  }

  .label-wraper,
  .labelWrapper,
  .label-1 span.l-value-wrap,
  .label-2 span.l-value-wrap,
  .labelWrapper1 {
    // background: #6093AA !important;
    // color: #ffffff !important;
    color: #6093aa;
    h5,
    h4 {
      font-size: 14px !important;
      font-weight: 600 !important;
      padding-left: 10px !important;
      height: 40px;
      margin-bottom: 0 !important;
    }
    label {
      // color: #ffffff !important;
      color: #6093aa !important;
      padding: 8px;
    }
    // td {
    //   h4 {
    //     color: #ffffff !important;
    //   }
    // }
  }
}

.mb20 {
  margin-bottom: 20px;
}

.borderCntr {
  border: 1px solid black;
  padding: 5px;
}

.style0 {
  mso-number-format: General;
  text-align: general;
  vertical-align: bottom;
  white-space: nowrap;
  mso-rotate: 0;
  mso-background-source: auto;
  mso-pattern: auto;
  color: black;
  font-size: 11pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: Calibri, sans-serif;
  mso-font-charset: 0;
  border: none;
  mso-protection: locked visible;
  mso-style-name: Normal;
  mso-style-id: 0;
}

.label-2,
.labelWrapper {
  mso-style-parent: style0;
  // color: #908001;
  color: #6093aa;
  font-weight: 700 !important;
  mso-pattern: black none;
}
.label-2 {
  .labelWrapper {
    display: flex;
    align-items: center;
  }
  .label-wraper {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0 !important;
    }
  }
}

.PinkBlue {
  .plm_carep_top_first,
  .labelWrapper1 {
    // background-color: #6093AA;
    // color: #ffffff;
    color: #6093aa;
    font-weight: 700;
  }

  .plm_carep_top_second {
    // background-color: #6093AA;
    // color: #ffffff;
    color: #6093aa;
    font-weight: 700;
  }
}

.plm_carep_top_first,
.labelWrapper1 {
  // color: #008080;
  color: #6093aa;
  font-weight: 700;
}

.plm_carep_top_second {
  // color: #908001;
  color: #6093aa;
  font-weight: 700;
}
.SubmittedContainer {
  mso-style-parent: style0;
  background-color: #0000ff;
  color: red;
  mso-pattern: black none;
}

.bgColorRow {
  mso-style-parent: style0;
  background-color: #6093aa;
  color: #ffffff;
}
table td {
  mso-style-parent: style0;
  word-wrap: break-word;
  mso-pattern: black none;
}
.logo {
  mso-style-parent: style0;
  height: 80px;
  width: 140px;
  mso-pattern: black none;
}
.imgLogo {
  mso-style-parent: style0;
  width: 120;
  height: 80;
  mso-pattern: black none;
}

.PinkBlue.App {
  .plm_inv_wiz_step3 {
    .label-wraper {
      label {
        max-width: 195px;
      }
    }
  }
  .invoice-container-wrap {
    .form-section {
      max-width: 228px;
    }
  }
  .invoice-btn-left {
    .invoiceBtn {
      float: left !important;
    }
  }
  .col-6 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .left_reportmenu {
    li {
      p {
        background: #6093aa !important;
        color: #ffffff !important;
        span {
          i {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.App.PinkBlue {
  .emp_topvaldiv {
    .label-wraper {
      background: #6093aa !important;
      color: #ffffff !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 0px 0px;
      //display: flex !important;
      align-items: center;
      flex-wrap: nowrap;
      label,
      .l-value-wrap {
        //color: #ffffff !important;
      }
      span {
        font-size: 16px;
        //color: #ffffff !important;
      }
    }
  }
  .lbl-light-green {
    .emp_topvaldiv {
      .label-wraper {
        background: #afc9d4 !important;
      }
    }
  }
  .reportWrap {
    .cardBox {
      height: 100% !important;
      .card-body {
        height: 100% !important;
        .reportWrapper {
          height: 100% !important;
          .radio_ibox {
            .radio_inner {
              .tblc_chklbl {
                label {
                  color: #6093aa !important;
                }
              }
            }
          }
          & > .clientreport_rowbox {
            height: initial !important;
          }
          .nonsubm_rowbox {
            height: initial !important;
          }
          .plm_cltrepo_btm {
            height: initial !important;
          }
          .prn_row {
            height: initial !important;
            .tb-wrap {
              .plm_cmn_tbldiv {
                min-height: initial !important;
              }
            }
          }
        }
      }
    }
  }
  .reportWrapper {
    margin-bottom: 0px;
    //max-height: calc(100vh - 215px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    box-sizing: content-box;

    &.timesheetReportWrap {
      padding-right: 16px;
      width: calc(100% - 16px);
      .sticky-top-box {
        .plm_rp_tsreport_btn_div {
          .col-1 {
            padding-right: 10px !important;
          }
        }
      }
      //overflow-x: auto;
      .plm_rp_partner_col.col-6 {
        padding-right: 20px !important;
        & ~ .lbl-light-green {
          padding-left: 7px !important;
        }
      }
      .reportContainer {
        padding-bottom: 0 !important;
      }
    }

    .second-top-row {
      margin-left: 0px !important;
      margin-right: 0px !important;
      .col-3 {
        padding-right: 8px !important;
        padding-right: 12px !important;
        &:nth-child(1) {
          padding-left: 0 !important;
          padding-right: 12px !important;
        }
      }
    }

    .PinkBlue.btn-primary {
      min-height: 25px !important;
      // border-radius: 0.25rem !important;
    }
    .selectWrap {
      .tblc_chklbl {
        label {
          margin-bottom: 0;
        }
      }
    }
    .pln_vboxes_div {
      .row {
        .col-md-6 {
          .col-md-6 {
            padding-left: 15px !important;
            padding-right: 15px !important;
          }
        }
        .col-6,
        .col-md-6 {
          &:nth-child(1) {
            padding-left: 15px !important;
          }
          &:nth-child(2) {
            padding-right: 15px !important;
          }
        }
      }
      .ts-status {
        .emp_topvaldiv {
          margin-top: 0;
          .label-wraper {
            display: block;
            width: 100% !important;
            padding: 0 !important;
            label {
              display: block;
              font-weight: 600;
              width: 100%;
              padding: 8px;
              font-size: 14px;
              margin-bottom: 0.5rem;
              color: #ffffff !important;
              background: #6093aa !important;
            }
            .mx-1 {
              display: none;
            }
          }
        }
      }
    }
    .tblc_chklbl {
      label {
        color: #ffffff !important;
        background: #6093aa;
        // height: 40px;
        display: flex;
        align-items: center;
        // font-size: 16px !important;
        // padding: 6px 15px !important;
        padding: 0.4rem 0.8rem !important;
        line-height: 1.7em;
        height: 40px;
        margin-bottom: 0;
      }
      & ~ .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 20px !important;
        .react-datepicker__input-container {
          input {
            min-height: 40px;
          }
        }
      }
      label.lbl-pink {
        background: #a42998 !important;
        color: #fff;
        margin-bottom: 0;
      }
      label.lbl-green {
        background: #6093aa !important;
        color: #fff !important;
        margin-bottom: 0;
      }
      & ~ div {
        select {
          height: 40px !important;
          padding: 5px 10px !important;
        }
      }
    }
  }
  .reportContainer {
    .prn_row {
      margin-right: 0 !important;
    }

    .emp_topvaldiv {
      margin-top: 20px !important;

      .label-wraper {
        padding: 0.4rem 10px !important;
        line-height: 1.7em;
        height: initial !important;
        //align-items: flex-start !important;
        align-items: center !important;
        height: 40px !important;
        label {
          white-space: nowrap;
        }
        label,
        span {
          // font-size: clamp(0.8em, 1vw, 1rem) !important;
          padding: 0 !important;
          line-height: 1.7em;
        }
        .l-value-wrap {
          //font-size: clamp(0.8em, 1vw, 1rem) !important;
          // line-height: 24px;
          display: flex;
          align-items: center;
          //min-height: 24px;
          span {
            padding-left: 5px !important;
          }
        }
      }
    }
    .label-wraper {
      padding: 0px 0px;
      width: 60% !important;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 40px;
      label {
        font-weight: 600;
        font-size: 16px;
        padding: 8px 10px;
        padding-right: 0;
      }
      span.mx-1 {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    table.reportTbl {
      margin-top: 10px;
      overflow-x: auto;
      //border-spacing: 15px 5px;
      border-spacing: 0;
      border-collapse: separate;
      //margin-left: -15px;
      margin-left: 0;
      tbody tr {
        &:nth-of-type(odd) td {
          background: #ffffff !important;
          span {
            background: #eeeeee;
            //white-space: nowrap !important;
          }
        }
        &:nth-of-type(even) td {
          border: none !important;
          //padding: 7px 10px !important;
          span {
            background: #ffffff;
            //white-space: nowrap !important;
          }
        }
        td {
          color: #475f7b !important;
          font-size: 12px;
          //padding: 7px 10px !important;
          // padding: 0.4rem 10px !important;
          padding: 0 10px !important;
          line-height: 1.7em;
          border: none !important;
          &:nth-child(1) {
            padding-left: 0 !important;
          }
          &:nth-last-child(1) {
            padding-right: 0 !important;
          }
          span {
            // min-height: 35px;
            //padding: .4rem .3rem;
            display: flex;
            align-items: center;
            //font-size: 11px !important;
            font-size: 11px !important;
            line-height: 16px !important;
            //font-size: clamp(0.8em, 1vw, 0.8rem) !important;
            word-break: break-word;
            min-height: 40px;
            padding: 0 10px !important;
            //white-space: nowrap !important;
            //flex-wrap: nowrap !important;
            & > span {
              padding: 0 !important;
            }
          }
        }
      }
      thead th {
        font-size: 11px;
        font-weight: bold;
        color: #475f7b !important;
        padding: 0px 10px !important;
        border: none !important;
        &:nth-last-child(1) {
          padding-right: 0 !important;
        }
      }
    }
    .plm_wip_trow {
      width: auto !important;
      margin-right: -10px !important;
    }
    .tb-wrap {
      margin-left: 0;
      margin-right: 0;
    }
    .col-12.bgColorRow,
    .col-12.bgSib {
      margin-left: 0px;
      margin-right: 0px;
      width: calc(100% - 0px) !important;
      flex: 0 0 calc(100% - 0px);
      padding: 0;
      table {
        td {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }
    }
    .label-1 {
      .emp_topvaldiv {
        .label-wraper {
          background: #a42998 !important;
        }
      }
    }
    .label-3 {
      .emp_topvaldiv {
        .label-wraper {
          //background: #afc9d4 !important;
          background: rgba(96, 147, 170, 0.5) !important;
        }
      }
    }

    .emp_topvaldiv {
      .label-wraper {
        background: #6093aa !important;
        color: #ffffff !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 0px 0px;
        label,
        .l-value-wrap {
          color: #ffffff !important;
        }
        span {
          font-size: 16px;
        }
      }
    }

    .lbl-light-green {
      .emp_topvaldiv {
        .label-wraper {
          background: #afc9d4 !important;
        }
      }
    }
  }
  .weeklyGrid {
    margin-bottom: 10px;
    .tb-wrap {
      margin-right: -0.85rem;
      margin-left: -0.85rem;
    }
    .totalRow {
      table {
        th {
          &:not(:nth-child(1)) {
            .th-cell {
              padding-left: 0px !important;
              padding-right: 0px !important;
              span {
                width: 100%;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
    .daysGridWrapper {
      table {
        th {
          min-width: 40px !important;
          max-width: 60px !important;
          width: 40px !important;
          .th-cell {
            span {
              display: flex;
              width: 100%;
              justify-content: center;
              white-space: nowrap;
            }
          }
        }
        td {
          td {
            min-width: 40px !important;
            max-width: 60px !important;
          }
          span {
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  .rwrapPdf {
    .emp_topvaldiv {
      .label-wraper {
        color: #ffffff !important;
        display: flex !important;
        label,
        span {
          color: #ffffff !important;
        }
      }
    }
  }
}

/*style for dropdown*/

.dropdown_select {
  font-size: 16px !important;
  // font-size: clamp(0.8em, 1vw, 1rem) !important;
  padding: 0.4rem 0.8rem !important;
  line-height: 1.7em;
  height: 40px !important;
  span.select {
    color: #000000;
  }
  &:hover {
    .dropdown_list {
      opacity: 1;
      visibility: visible;
    }
  }
}

/*table styles*/

.App.PinkBlue .cardBox .card-body {
  /*
  .client-tbl {
    .tb-wrap {
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
    .reportTbl {
      border-spacing: 0px 0px !important;
      width: initial !important;
      margin-left: 0 !important;
    }
    table {
      min-width: 100%;
      tr {
        th {
          .th-cell {
            padding: 0.5rem 0 !important;
            padding-top: 0.2rem !important;
            padding-bottom: 20px !important;
            //  min-height: 40px;
            span {
              color: #475f7b !important;
              
            }
          }
          span {
            // font-size: 16px !important;
            //font-size: clamp(0.8em, 1vw, 1rem) !important;
            font-size: 11px !important;
            letter-spacing: 0;
          }
        }
        td {
          padding: 0 10px !important;
        }

        th,
        td {
          //font-size: clamp(0.8em, 1vw, 1rem) !important;
          //font-size: 16px !important;

          &:nth-child(1) {
            // width: 115px;
            width: 8.33%;
            max-width: 8.33%;
            min-width: 80px;
            padding-left: 0 !important;
            .th-cell {
              span {
                // white-space: nowrap;
              }
            }
          }
          &:nth-child(2) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(3) {
            width: 8.33%;
            max-width: 8.33%;
            min-width: 80px;
            //min-width: 92px;
            .th-cell {
              span {
                //white-space: nowrap;
              }
            }
          }
          &:nth-child(4) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(5) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(6) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(7) {
            width: 16.66%;
            max-width: 16.66%;
          }
        }
      }
    }
  }
  */

  /*
  .reportWrapper .weeklyGrid table td.dark-green {
    padding: 10px 10px 10px 10px !important;
    color: #ffffff !important;
    background-color: #6093aa !important ;
    font-weight: 600 !important;
    font-size: 16px !important;
    height: 40px;
  }
  */
}

.App.PinkBlue {
  font-family: Verdana, sans-serif !important;

  .client-tbl {
    .tb-wrap {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .reportTbl {
      border-spacing: 0px 0px !important;
      width: initial !important;
      margin-left: 0 !important;
    }
    table {
      min-width: 100%;
      tr {
        th {
          .th-cell {
            padding: 0.5rem 0 !important;
            padding-top: 0.2rem !important;
            padding-bottom: 20px !important;
            //  min-height: 40px;
            span {
              color: #475f7b !important;
              white-space: nowrap;
            }
          }
          span {
            // font-size: 16px !important;
            //font-size: clamp(0.8em, 1vw, 1rem) !important;
            font-size: 11px !important;
            letter-spacing: 0;
          }
        }
        td {
          padding: 0 10px !important;
        }

        th,
        td {
          //font-size: clamp(0.8em, 1vw, 1rem) !important;
          //font-size: 16px !important;

          &:nth-child(1) {
            // width: 115px;
            width: 8.33%;
            max-width: 8.33%;
            min-width: 80px;
            padding-left: 0 !important;
            .th-cell {
              span {
                // white-space: nowrap;
              }
            }
          }
          &:nth-child(2) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(3) {
            width: 16.66%;
            max-width: 16.66%;
            min-width: 80px;
            //min-width: 92px;
            .th-cell {
              span {
                //white-space: nowrap;
              }
            }
          }
          &:nth-child(4) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(6) {
            width: 16.66%;
            max-width: 16.66%;
          }
          &:nth-child(7),
          &:nth-child(5) {
            width: 12.66%;
            max-width: 12.66%;
          }
        }
      }
    }
  }
}

/*label styles*/

.lbl-min-width {
  // min-width: 385px;
  // max-width: 385px !important;
  padding-right: 8px !important;
  .tblc_chklbl {
    label {
      //font-size: clamp(0.8em, 1vw, 1rem) !important;
      font-size: 16px !important;
    }
  }

  &:nth-child(2) {
    padding-left: 12px !important;
    &.prc-8 {
      padding-left: 16px !important;
    }
    &.label-2 {
      padding-left: 0 !important;
      padding-right: 12px !important;
    }
  }
  &:nth-child(3) {
    padding-left: 12px !important;
    &.label-2 {
      padding-left: 8px !important;
      padding-right: 12px !important;
    }
  }
  &:nth-child(4) {
    padding-left: 12px !important;
    &.label-2 {
      padding-left: 8px !important;
      padding-right: 12px !important;
    }
  }
  // &.lbl-light-green {
  //   &:nth-child(1) {
  //     padding-right: 0px !important;
  //   }
  // }
  &.label-2 {
    padding-left: 0px !important;
    &.col-2 {
      width: 16.66% !important;
      max-width: 16.66% !important;
    }
    &.pad-l-15 {
      padding-left: 15px !important;
    }
    &.pad-r-10 {
      padding-right: 5px !important;
    }
  }
  &.label-3 {
    padding-left: 0px !important;
    padding-right: 12px !important;
    &.pad-l-15 {
      padding-left: 15px !important;
    }
    &.pad-r-0 {
      padding-right: 0 !important;
    }
    &.lbl-mt-10 {
      .emp_topvaldiv {
        margin-top: 10px !important;
      }
    }
  }
}
.report-lbl-min-width {
  // min-width: 385px;
  // max-width: 385px !important;
  padding-right: 10px !important;
  margin-bottom: 5px;
  .label-wraper {
    padding: 0.6rem 10px !important;
    height: initial !important;
    line-height: 1.7em;
    label,
    span {
      // font-size: clamp(0.8em, 1vw, 1rem) !important;
      padding: 0 !important;
    }
    .l-value-wrap {
      //font-size: clamp(0.8em, 1vw, 1rem) !important;
      line-height: 1.7em !important;
      min-height: initial;
    }
  }
}

/*report bottom btn*/

.report-bottom-btn {
  // .tbBtn{
  //   display: grid !important;
  //   grid-template-columns: repeat(12, 1fr);
  //   grid-column-gap: 20px;
  //   padding-left: 20px;
  //   .col-2, .col-10{
  //     display: grid;
  //     padding: 0 !important;
  //     max-width: initial !important;
  //   }
  //   .col-2{
  //     grid-column-start: 1;
  //     grid-column-end: 3;
  //   }
  //   .col-10{
  //     grid-column-start: 3;
  //     grid-column-end: 5;
  //     grid-template-columns: 1fr 1fr;
  //     grid-column-gap: 20px;
  //     button.PinkBlue{
  //       min-width: 115px;
  //       width: 100% !important;
  //       margin-right: 0 !important;
  //     }
  //   }
  // }
  .col-2 {
    //padding-right: 5px;
    padding-left: 20px;
  }
  .col-10 {
    padding-left: 20px;
    button {
      &.PinkBlue {
        margin-right: 20px !important;
        //  height: 40px;
        min-width: 115px;
        width: 8.33% !important;
        //border-radius: 0 !important;
        font-size: 12px !important;
        //font-size: 16px !important;
      }
    }
  }
}

/*styles for button*/
.reportWrapper {
  .sticky-top-box {
    margin-bottom: 0px;
    //margin-right: -45px;
  }
  button {
    &.PinkBlue {
      height: 40px;
      font-size: 16px !important;
      width: 115px;
      border-radius: 0 !important;
    }
  }
}

.App.PinkBlue .plm_layout_left {
  width: 244px !important;
  .plm_leftnav_wrap {
    padding: 0 !important;
  }
}
.App.PinkBlue .plm_layout_right {
  left: 259px;
  width: calc(100% - 259px);
  padding-right: 0 !important;
}
.reportHeader {
  & ~ .customerMainContentapp_inner_wrap {
    //height: calc(100% - 210px) !important;
    // height: calc(100% - 26vh) !important;
    margin-top: 20px !important;
    .plm_layout_right {
      left: 259px;
      padding-right: 0 !important;
      width: calc(100% - 259px);
    }
  }
}

// .PinkBlue.App .left_reportmenu{
//   li p{
//     &.rpt_title{
//       font-weight: 600 !important;
//       font-size: 16px;
//     }

//     span i {
//       font-size: 32px;
//       position: absolute;
//       top: 3px;
//       right: 5px;
//     }
//   }
// }
.timesheetReportWrap .report-bottom-btn {
  position: relative;
}
.report-bottom-btn {
  &.col_rps_inn_btm {
    margin-top: 0px !important;
  }
  height: 60px;
  bottom: 0;
  margin-right: 0px;
  position: sticky;
  //width: 100%;
  box-sizing: border-box;
  padding-right: 0px;
  z-index: 1;
  .tbBtn {
    padding-top: 20px !important;
    bottom: 0 !important;
    width: auto !important;
    left: 0px !important;
    min-width: calc(100% - 0px);
    margin-left: 0px;
    margin-right: -5px;
    position: relative !important;

    &.left-align-c {
      justify-content: flex-start !important;
      button {
        width: 100%;
      }
    }
    .col-2 {
      padding-left: 0px;
      width: 16.66% !important;
      padding-right: 10px !important;
      .text-green {
        color: #6093aa !important;
        padding-right: 5px;
      }
    }
    .col-1 {
      padding-right: 10px;
      padding-left: 10px;
      width: calc(8.33% + 0px) !important;
    }

    // .btn {
    //   //   min-height: initial !important;
    //   // padding: .4rem .8rem !important;
    //   // line-height: 1.7em !important;
    //   // height: initial !important;
    //   // font-size: clamp(0.8em, 1vw, 1rem) !important;
    //   border-radius: 0.25rem !important;
    // }
  }
}

@media (min-width: 768px) {
  .lbl-min-width {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .timesheet_report_rowbox,
  .nonsubm_rowbox,
  .nonchrg_rowbox,
  .flexWrap {
    .col-6.lbl-min-width {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }
}

.App.PinkBlue .cardBox .card-body {
  .timesheet_report_rowbox {
    .weekBox {
      color: $select_color;
      .btWrap {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .form-group {
        display: flex;
        justify-content: center;
      }
      select {
        border: none;
        color: $select_color !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
      }
    }
    .sts {
      display: flex;
      justify-content: center;
      margin-top: -12px;
      color: $select_color;
    }
  }
}

.App.PinkBlue .reportWrap .plm_cmn_cardbox {
  overflow: hidden;
  height: 100% !important;
}
.App.PinkBlue .reportWrap {
  .cardBox {
    border-radius: 0;
    .plm_rp_client_col {
      overflow-x: hidden;
      height: initial !important;
      //  max-height: calc(100vh - 325px) !important;
      max-height: initial !important;

      .reportContainer {
        padding-bottom: 0px !important;
        .lbl-min-width {
          .emp_topvaldiv {
            //margin-top: 0 !important;
          }
        }
        .wipSummaryTbl {
          .label-1.col-12 {
            .emp_topvaldiv {
              margin-top: 0 !important;
            }
          }
          .lbl-min-width {
            .emp_topvaldiv {
              margin-top: 20px !important;
            }
          }
        }
        .wipTotalTbl {
          table {
            table-layout: fixed;
            &.reportTbl {
              tr {
                .wipSummaryHeader {
                  span {
                    //white-space: normal !important;
                  }
                }
                td {
                  &:nth-child(2) {
                    span {
                      white-space: normal !important;
                      word-break: break-all !important;
                      flex-wrap: wrap !important;
                    }
                    max-width: 16.66% !important;
                    width: 16.66% !important;
                    min-width: 16.66% !important;
                  }
                }
              }
            }
          }
          .lbl-min-width {
            .emp_topvaldiv {
              margin-top: 20px !important;
            }
            &.lbl-mt-10 {
              .emp_topvaldiv {
                margin-top: 10px !important;
              }
            }
          }

          .totalRow {
            .tb-wrap {
              table {
                tr {
                  th {
                    &.wipSummaryHeader {
                      &[colspan="2"] {
                        width: 33.33% !important;
                        min-width: 33.33% !important;
                        max-width: 33.33% !important;
                      }
                      &:nth-child(1) {
                        &[colspan="2"] {
                          width: 33.33% !important;
                          min-width: 33.33% !important;
                          max-width: 33.33% !important;
                        }
                      }
                      &:nth-child(2) {
                        width: 8.33% !important;
                        min-width: 8.33% !important;
                        max-width: 8.33% !important;
                      }
                      &:nth-child(3) {
                        width: 16.66% !important;
                        min-width: 16.66% !important;
                        max-width: 16.66% !important;
                      }
                      &:nth-child(4) {
                        width: 41.68% !important;
                        min-width: 41.68% !important;
                        max-width: 41.68% !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .client-tbl {
          .tb-wrap {
            margin-left: 0px !important;
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}

.lbl-light-green {
  .tblc_chklbl {
    label {
      background: #afc9d4 !important;
    }
  }
}

.prc-8 {
  padding-right: 8px !important;
}
.prc-10 {
  padding-left: 10px !important;
}

table {
  th,
  td {
    &.td-col-2 {
      width: 16.66%;
      max-width: 16.66%;
    }
    &.td-col-1 {
      width: 8.33%;
      max-width: 8.33%;
    }
  }
}

.reportContainer {
  & > .row {
    & > .col-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.reportWrapper,
.App.PinkBlue .cardBox .card-body .reportWrapper {
  table {
    border-spacing: 0 !important;
    tr {
      th,
      td {
        padding-left: 10px !important;
        padding-right: 10px !important;
        &:nth-child(1) {
          padding-left: 0 !important;
        }
        &:nth-last-child(1) {
          padding-right: 0 !important;
        }
      }
      td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        span {
          width: 100% !important;
          min-height: 40px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          //white-space: normal !important;
          word-break: break-all;
        }
      }
      &:nth-of-type(odd) {
        td {
          // background: #ffffff !important;
          & > span {
            background: #eeeeee !important;
            width: 100% !important;
            min-width: 100%;
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 0 5px;
          }
        }
      }
      &:nth-of-type(even) {
        td {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          border: none !important;

          span {
            width: 100% !important;
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 0 5px;
            background: #fff !important;
          }
        }
      }
      &.bgColor {
        td {
          background-color: #6093aa !important;
          & > span {
            background-color: #6093aa !important;
          }
        }
      }
    }
  }

  .weeklyGrid {
    table {
      tr {
        td {
          &.dark-green {
            padding-left: 10px !important;
            padding-right: 10px !important;
            color: #fff !important;
            font-weight: 600 !important;
          }
        }
      }
    }
  }

  .tb-wrap {
    max-height: initial !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    table {
      border-spacing: 0 !important;
      tr {
        th,
        td {
          padding-left: 10px !important;
          padding-right: 10px !important;
          &:nth-child(1) {
            padding-left: 0px !important;
          }
          &:nth-last-child(1) {
            padding-right: 0 !important;
          }
        }
      }
    }
  }
  .plm_trans_cltcwip_invgrid_box {
    .tb-wrap {
      max-height: initial !important;
      table {
        border-spacing: 0 !important;
        tr {
          th,
          td {
            padding-left: 10px !important;
            padding-right: 10px !important;
            &:nth-child(1) {
              padding-left: 0 !important;
            }
          }
        }
      }
    }
  }

  .popUpContainer {
    .tb-wrap {
      table {
        tr {
          th,
          td {
            &:nth-child(1) {
              padding-left: 15px !important;
            }
            &:nth-last-child(1) {
              padding-right: 15px !important;
            }
          }
        }
      }
    }
  }
  .cwd-t-tbl {
    .totalRow {
      .tb-wrap {
        table {
          tr {
            th {
              &.wipHeader {
                &:nth-child(1) {
                  width: 20 * 3% !important;
                  min-width: 20 * 3% !important;
                  max-width: 20 * 3% !important;
                }
                &:nth-child(2),
                &:nth-child(3) {
                  width: 20% !important;
                  max-width: 20% !important;
                  min-width: 20% !important;
                  .th-cell {
                    padding-left: 10px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    table {
      tr {
        td {
          width: 20% !important;
          max-width: 20% !important;
          min-width: 20% !important;
        }
      }
    }
  }
  .cwd-i-tbl {
    table {
      tr {
        td {
          &:nth-child(1) {
            width: 16.66% !important;
            max-width: 16.66% !important;
          }
          &:nth-child(2) {
            width: 8.33% !important;
            max-width: 8.33% !important;
          }
          &:nth-child(3) {
            width: 8.33% !important;
            max-width: 8.33% !important;
          }
        }
      }
    }
  }
  .cws-tbl {
    .lbl-min-width.label-2.pad-r-10 {
      padding-right: 2px !important;
    }
    table,
    table {
      tr {
        // th.wipSummaryHeader {
        //   &:nth-child(1) {
        //     width: 8.33% !important;
        //     max-width: 8.33% !important;
        //     min-width: 8.33% !important;
        //   }
        // }
        td,
        th {
          &:nth-child(1) {
            width: 8.33% !important;
            max-width: 8.33% !important;
            min-width: 8.33% !important;
          }
          &:nth-child(2) {
            width: 16.66% !important;
            max-width: 16.66% !important;
            min-width: 16.66% !important;
          }
          &:nth-child(3) {
            width: 20% !important;
            max-width: 20% !important;
            min-width: 20% !important;
          }
          &:nth-child(4) {
            width: 54.68% !important;
            max-width: 54.68% !important;
            min-width: 54.68% !important;
            span {
              display: none !important;
            }
          }
        }
      }
    }
    .col-12 {
      .wipSummaryHeader {
        &:nth-child(1) {
          width: 8.33% !important;
          min-width: 8.33% !important;
          max-width: 8.33% !important;
        }
        &:nth-child(2) {
          width: 16.66% !important;
          min-width: 16.66% !important;
          max-width: 16.66% !important;
        }
        &:nth-child(3) {
          width: 8.33% !important;
          min-width: 8.33% !important;
          max-width: 8.33% !important;
        }
        &:nth-child(4) {
          width: 66.68% !important;
          min-width: 66.68% !important;
          max-width: 66.68% !important;
        }
      }
      &.totalRow {
        .reportTbl {
          tr {
            .wipSummaryHeader {
              &:nth-child(1) {
                width: 24.99% !important;
                min-width: 24.99% !important;
                max-width: 24.99% !important;
              }
              &:nth-child(2) {
                width: 8.33% !important;
                min-width: 8.33% !important;
                max-width: 8.33% !important;
                .th-cell {
                  padding-left: 10px !important;
                }
              }
              &:nth-child(3) {
                width: 66.68% !important;
                min-width: 66.68% !important;
                max-width: 66.68% !important;
              }
            }
          }
        }
      }
    }
  }
  .twd-tbl {
    table.table.reportTbl,
    .totalRow table {
      tr {
        td,
        th {
          &:nth-child(1) {
            width: 16.66% !important;
            max-width: 16.66% !important;
            min-width: 16.66% !important;
          }
        }
      }
    }
    .bgSib {
      table {
        tr {
          td {
            &:nth-child(1) {
              width: 16.66% !important;
              max-width: 16.66% !important;
              min-width: 16.66% !important;
            }
          }
        }
      }
    }
  }
  .cellBlock {
    background-color: #eeeeee;
    padding: 8px 8px;
    font-weight: 600;
    text-align: center;
    color: #6093aa;
    font-size: 14px;
  }
  .cellBlock_wrap {
    .AuthorisedContainer {
      background-color: #0000ff !important;
      color: #ffffff !important;
    }
    margin: 10px 0;
    padding-left: 10px !important;
    padding-right: 10px !important;
    &:nth-child(6n + 1) {
      padding-left: 0 !important;
    }
  }
}

.reportWrap {
  .nonchrg_rowbox {
    .freeHeader-box {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .plm_cltrepo_btm {
      .plm_cltrepo_btm_inrcontent {
        .plm_clt_reprt_tbldiv {
          .tb-wrap {
            .plm_cmn_tbldiv {
              .table {
                th {
                  .th-cell {
                    padding: 5px 10px !important;
                    span {
                      padding-left: 0 !important;
                    }
                  }
                }
                .th {
                  padding: 0 10px !important;
                  // min-width: initial !important;
                  &:nth-child(1) {
                    padding-left: 0 !important;
                  }
                }
                .td {
                  padding: 0 10px !important;
                  // min-width: initial !important;
                  &:nth-child(1) {
                    padding-left: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.reportWrapper {
  .sticky-top-box {
    select.form-input,
    .cst_tbox_popmdiv .form-control {
      border: none !important;
      background: #f6f6f6 !important;
    }
    .weekBox {
      select.form-input {
        background: #ffffff !important;
      }
    }
    .react-datepicker-wrapper .react-datepicker__input-container input {
      border: none !important;
      background: #f6f6f6 !important;
    }
  }
  .reportContainer {
    .weeklyGrid {
      margin-bottom: 20px !important;
      .total_timeslot_content {
        .trow_time_top {
          table {
            tr {
              td[width="50%"] {
                &:nth-child(1) {
                  width: 58.33% !important;
                  max-width: 58.33% !important;
                  padding-right: 0 !important;
                  .tsgridtbldiv {
                    .tb-wrap {
                      table {
                        &.reportTbl {
                          tr {
                            td,
                            th {
                              &:nth-child(1) {
                                width: 14.28% !important;
                                min-width: 14.28% !important;
                                max-width: 14.28% !important;
                              }
                              &:nth-child(2) {
                                width: 28.56% !important;
                                min-width: 28.56% !important;
                                max-width: 28.56% !important;
                              }
                              &:nth-child(3) {
                                width: 14.28% !important;
                                min-width: 14.28% !important;
                                max-width: 14.28% !important;
                              }
                              &:nth-child(4) {
                                width: 42.84% !important;
                                min-width: 42.84% !important;
                                max-width: 42.84% !important;
                                padding-right: 10px !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-child(2) {
                  padding-left: 0 !important;
                  width: 41.66%;
                  max-width: 41.66%;
                  min-width: 41.66%;
                  .tb-wrap {
                    table {
                      tr {
                        td {
                          &:nth-child(1) {
                            padding-left: 10px !important;
                          }
                          span {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                          }
                        }
                        th {
                          &:nth-child(1) {
                            padding-left: 10px !important;
                          }
                          .th-cell {
                            span {
                              padding-left: 0 !important;
                              padding-right: 0 !important;
                              // white-space: nowrap !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.daysGridWrapper {
                padding-left: 0 !important;
                width: 41.66% !important;
                max-width: 41.66% !important;
                .tb-wrap {
                  table {
                    tr {
                      td {
                        span {
                          white-space: nowrap;
                        }
                        &:nth-child(1) {
                          padding-left: 10px !important;
                        }
                        th {
                          width: calc(41.66% / 8) !important;
                          min-width: calc(41.66% / 8) !important;
                          max-width: calc(41.66% / 8) !important;
                          &:nth-child(1) {
                            padding-left: 10px !important;
                          }
                          .th-cell {
                            span {
                              word-break: initial !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .totalRow {
          .tb-wrap {
            table {
              &.reportTbl {
                tr {
                  th {
                    &.totalFirstCol {
                      min-width: 58.33% !important;
                      width: 58.33% !important;
                      max-width: 58.33% !important;
                      padding-left: 0 !important;
                      .th-cell {
                        padding-left: 5px !important;
                        span {
                          padding-left: 0 !important;
                        }
                      }
                      & ~ th {
                        width: calc(41.66% / 8) !important;
                        min-width: calc(41.66% / 8) !important;
                        max-width: calc(41.66% / 8) !important;
                        .th-cell {
                          span {
                            padding-left: 0 !important;
                            white-space: nowrap;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .total_expenses_content {
        .total_exp_coldiv {
          .totalRow {
            .tb-wrap {
              table {
                &.reportTbl {
                  th {
                    &:nth-child(1) {
                      padding-left: 0px !important;
                    }
                    .th-cell {
                      padding-left: 5px !important;
                    }
                  }
                }
              }
            }
          }
          .totalRow {
            .th {
              &.totalFirstCol {
                width: 58.31% !important;
                min-width: 58.31% !important;
                max-width: 58.31% !important;
              }
              &:nth-child(2) {
                width: 41.69% !important;
                min-width: 41.69% !important;
                max-width: 41.69% !important;
              }
            }
            .th {
              &:nth-child(2) {
                .th-cell {
                  span {
                    display: block;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
        .tdivexps_top {
          .tb-wrap {
            table {
              tr {
                th {
                  .th-cell {
                    padding-left: 10px !important;
                  }
                }
                td,
                th {
                  &:nth-child(1) {
                    width: 8.33% !important;
                    min-width: 8.33% !important;
                    max-width: 8.33% !important;
                  }
                  &:nth-child(2) {
                    width: 16.66% !important;
                    min-width: 16.66% !important;
                    max-width: 16.66% !important;
                  }
                  &:nth-child(3) {
                    width: 8.33% !important;
                    min-width: 8.33% !important;
                    max-width: 8.33% !important;
                  }
                  &:nth-child(4) {
                    width: 24.99% !important;
                    min-width: 24.99% !important;
                    max-width: 24.99% !important;
                  }

                  &:nth-child(5) {
                    width: 8.33% !important;
                    min-width: 8.33% !important;
                    max-width: 8.33% !important;
                  }
                  &:nth-child(6) {
                    width: 33.32% !important;
                    min-width: 33.32% !important;
                    max-width: 33.32% !important;
                    span {
                      display: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.wipSummaryTbl {
  .table-responsive.plm_cmn_tbldiv {
    table {
      th,
      td {
        &:nth-child(1) {
          min-width: 16.66% !important;
          width: 16.66% !important;
          max-width: 16.66% !important;
        }
        &:nth-child(2) {
          min-width: 16.66% !important;
          width: 16.66% !important;
          max-width: 16.66% !important;
        }
        &:nth-child(3) {
          min-width: 8.33% !important;
          width: 8.33% !important;
          max-width: 8.33% !important;
        }
        &:nth-child(4) {
          min-width: 58.35% !important;
          width: 58.35% !important;
          max-width: 58.35% !important;
        }
      }
    }
  }
  & > .col-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.reportWrapper {
  /*style for client cost summary or report invoice*/
  .cltcost_sum_rowbox {
    .cltcost_sum_inner {
      .plm_tsrept_top {
        //margin-right: -5px !important;
        .col-3 {
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
      }
      .plm_cltrepo_btm {
        .col-3 {
          &.lbl-min-width {
            &.label-2 {
              padding-right: 12px !important;
              padding-left: 8px !important;
              &:nth-child(1) {
                padding-left: 0 !important;
              }
            }
          }
        }
      }
      .plm_cltrepo_btm .plm_cltrepo_btm_inrcontent .tb-wrap .plm_cmn_tbldiv {
        table {
          tr {
            th {
              .searchWrap {
                & > div {
                  & > div {
                    height: 40px;
                    border-radius: 0px !important;
                    background: #f6f6f6 !important;
                    i {
                      color: #707070 !important;
                      font-size: 16px;
                    }
                  }
                }
              }
              .th-cell {
                i.fa-sort {
                  font-size: 16px !important;
                }
              }
            }
            th,
            td {
              padding-left: 10px !important;
              padding-right: 10px !important;
              &:nth-child(1) {
                padding-left: 0 !important;
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(2) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(3) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(4) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(5) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(6) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(7) {
                width: 1% !important;
                min-width: 1% !important;
                max-width: 1% !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  /*---*/

  .feeincome_dtl_rowbox .plm_cltrepo_btm {
    padding: 0px 0px !important;
  }
  .empwrk_sum_rowbox {
    .col-6 {
      .col-6.lbl-light-green.lbl-min-width {
        flex: 0 0 50% !important;
        max-width: 50% !important;
      }
    }
    .reportContainer {
      .plm_empwork_income_grid {
        table {
          tr {
            th,
            td {
              &.feeIncomeDetailHeader {
                &:nth-child(1) {
                  width: 16.66% !important;
                  min-width: 16.66% !important;
                  max-width: 16.66% !important;
                }
                &:nth-child(2) {
                  width: 16.66% !important;
                  min-width: 16.66% !important;
                  max-width: 16.66% !important;
                }
                &:nth-child(3) {
                  width: 8.33% !important;
                  min-width: 8.33% !important;
                  max-width: 8.33% !important;
                }
                &:nth-child(4) {
                  width: 16.66% !important;
                  min-width: 16.66% !important;
                  max-width: 16.66% !important;
                }
                &:nth-child(5) {
                  width: 16.66% !important;
                  min-width: 16.66% !important;
                  max-width: 16.66% !important;
                }
              }
              &:nth-child(6) {
                span {
                  display: none !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.App.PinkBlue {
  .reportContainer {
    .first-label {
      .emp_topvaldiv {
        margin-top: 0 !important;
      }
    }
  }
}
.reportContainer {
  .plm_income_grid .tb-wrap .table .incomeGridHeader,
  .plm_income_grid .tb-wrap .table .td {
    &:nth-child(1) {
      width: 6% !important;
      min-width: 6% !important;
      max-width: 6% !important;
    }
    &:nth-child(2) {
      width: 14% !important;
      min-width: 14% !important;
      max-width: 14% !important;
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      width: 10% !important;
      min-width: 10% !important;
      max-width: 10% !important;
    }
    &:nth-child(8) {
      & > span {
        width: 50% !important;
        max-width: calc(50% - 10px) !important;
      }
    }
    &:nth-child(10) {
      span {
        display: none !important;
      }
    }
  }
  .plm_income_grid .totalRow .tb-wrap .table .incomeGridHeader,
  .plm_income_grid .totalRow .tb-wrap .table .td {
    .th-cell {
      padding-left: 10px !important;
      padding-right: 5px !important;
    }
    &:nth-child(1) {
      width: 20% !important;
      min-width: 20% !important;
      max-width: 20% !important;
      span {
        white-space: inherit !important;
      }
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      width: 10% !important;
      min-width: 10% !important;
      max-width: 10% !important;
    }
  }

  .feeincome_dtl_rowbox {
    .reportTbl {
      th,
      td {
        &:nth-child(10) {
          span {
            display: none !important;
          }
        }
        &:nth-child(8) {
          span {
            span.negativeCost {
              width: 100% !important;
              min-width: 100% !important;
              padding: 0px 0px !important;
            }
          }
        }
        &.feeIncomeDetailHeader {
          &:nth-child(1),
          &:nth-child(8) {
            min-width: 12% !important;
            width: 12% !important;
            max-width: 12% !important;
          }
          &:nth-child(10) {
            min-width: 6% !important;
            width: 6% !important;
            max-width: 6% !important;
          }
          min-width: 10% !important;
          width: 10% !important;
          max-width: 10% !important;
        }
      }
    }
  }
}

/*income report css*/
.reportWrapper {
  .feeincome_sum_rowbox,
  .feeincome_dtl_rowbox,
  .empwrk_sum_rowbox {
    .sticky-top-box {
      .plm_tsrept_top {
        .plm_cltrepo_top_inner {
          padding-right: 1px !important;
        }
      }
    }
  }
  .feeincome_dtl_rowbox {
    .col-12 {
      .label-2.lbl-min-width {
        padding-right: 10px !important;
        .emp_topvaldiv {
          // margin-top: 10px !important;
        }
        &.mt-20 {
          .emp_topvaldiv {
            margin-top: 20px !important;
          }
        }
      }
      .label-3.lbl-min-width {
        padding-right: 10px !important;
        .emp_topvaldiv {
          margin-top: 10px !important;
        }
      }
    }
    .totalRow {
      .tb-wrap {
        table {
          tr {
            th {
              &.feeIncomeDetailHeader {
                &[colspan="2"] {
                  width: 22% !important;
                  min-width: 22% !important;
                  max-width: 22% !important;
                }
                &:nth-child(7) {
                  width: 12% !important;
                  min-width: 12% !important;
                  max-width: 12% !important;
                }
                &:nth-child(8) {
                  width: 10% !important;
                  min-width: 10% !important;
                  max-width: 10% !important;
                }
                &:nth-child(9) {
                  width: 6% !important;
                  min-width: 6% !important;
                  max-width: 6% !important;
                }
                .th-cell {
                  padding-left: 10px !important;
                  span {
                    white-space: initial !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cltcost_sum_inner {
    .plm_cltrepo_btm {
      .plm_cltrepo_btm_inrcontent {
        .tb-wrap {
          .plm_cmn_tbldiv {
            .table {
              .td {
                padding: 0 10px !important;
              }
            }
          }
        }
      }
    }
  }
  // .cltcost_sum_inner{
  //   .plm_cltrepo_btm{
  //     .plm_cltrepo_btm_inrcontent{
  //       .tb-wrap{
  //         .plm_cmn_tbldiv{
  //           .table{
  //             .th{

  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.plm_income_grid {
  .col-12.label-2.lbl-min-width {
    padding-right: 10px !important;
  }
}
.reportWrap .nonchrg_rowbox .freeHeader-box {
  padding-right: 12px !important;
}
.App.PinkBlue .reportContainer {
  .lbl-mt-10 {
    .emp_topvaldiv {
      margin-top: 10px !important;
    }
  }
  .label-1.pad-r-10 {
    padding-right: 10px !important;
  }
}

.PinkBlue.reportWrapper {
  .prn_row {
    &.cltcost_sum_inner {
      margin-right: -5px !important;
    }
    &.cltcost_sum_rowbox {
      margin-right: 0 !important;
    }
  }
  .sticky-top-box {
    .plm_cltrepo_top,
    .plm_tsrept_top {
      .plm_cltrepo_top_inner {
        padding: 0 !important;
        & > .row {
          margin-left: 0 !important;
          margin-right: 0 !important;
          & > .col-12,
          & > .col-6 {
            padding-left: 0 !important;
            padding-right: 0 !important;
            & .row {
              margin-left: 0 !important;
              margin-right: 0 !important;
              & > .col-6 {
                &:nth-child(1) {
                  padding-left: 0 !important;
                  padding-right: 10px !important;
                }
                &:nth-child(2) {
                  padding-left: 10px !important;
                  padding-right: 10px !important;
                }
                &.lbl-min-width {
                  &:nth-child(1) {
                    padding-left: 0 !important;
                    padding-right: 10px !important;
                  }
                  &:nth-child(2) {
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                  }
                }
              }
            }
          }
        }
        .lbl-min-width {
          .dateWrapper .dateTitle {
            width: 115px;
          }
          .react-datepicker-wrapper {
            margin-bottom: 0 !important;
            min-height: 40px;
          }
        }
      }
    }
  }
}

.lbl-width-auto {
  max-width: initial !important;
  flex: 0 0 50% !important;
}

.App.PinkBlue
  .reportContainer
  .weeklyGrid
  .totalRow
  table
  th:not(:nth-child(1))
  .th-cell
  span {
  padding-left: 0 !important;
}

.timesheetReport {
  .label-2 {
    padding-left: 0 !important;
    margin-bottom: 10px;

    &:nth-of-type(odd) {
      padding-right: 0 !important;
      padding-left: 10px !important;
      &:nth-child(1) {
        padding-left: 0 !important;
      }
    }
    &:nth-last-child(1) {
      &:nth-of-type(even) {
        padding-right: 0 !important;
        padding-left: 10px !important;
        margin-bottom: 0 !important;
      }
    }
  }
  .lbl-light-green .emp_topvaldiv .label-wraper {
    background: #afc9d4 !important;
  }
}

.reportWrapper .weeklyGrid table td.dark-green {
  padding: 10px 10px 10px 10px !important;
  color: #ffffff !important;
  background-color: #6093aa !important ;
  font-weight: 600 !important;
  font-size: 16px !important;
  height: 40px;
  &:nth-child(1) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.App.PinkBlue {
  .totalRow {
    table {
      &.table.reportTbl {
        margin-top: 0 !important;
        tr {
          th {
            &.light-green {
              background-color: #afc9d4 !important;
            }
            &.dark-green {
              background-color: #6093aa !important;
            }
            &.dark-pink {
              background-color: #a42998 !important;
            }
            &.td-col-1 {
              width: 8.33% !important;
              max-width: 8.33% !important;
            }
            &.td-col-2 {
              width: 16.66% !important;
              max-width: 16.66% !important;
            }
            background-color: #a42998 !important;
          }
        }
      }
    }
  }
}
.reportContainer {
  .plm_wip_grid {
    table {
      tr,
      th {
        &:nth-child(4) {
          width: 20% !important;
          min-width: 20% !important;
          max-width: 20% !important;
        }
      }
      margin-bottom: 0 !important;
    }
  }
  .nonSubmittedGrid {
    .rowMargin.row.mx-0 {
      .cellBlock_wrap {
        width: 16.66%;
        & ~ .col-2.col-md-2.col-xs-2 {
          width: 16.66%;
        }
        .cellBlock {
          color: #6093aa;
        }
      }
    }
  }
}

.report-wrap-p {
  .row {
    & > .col-6 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}
.rwrapper-summary {
  .cws-tbl {
    & > .row {
      .head-box {
        &:nth-child(1) {
          padding-left: 0 !important;
        }
      }
    }
    & > .plm_pdf_report_wrap {
      & > .row {
        &:nth-child(1) {
          .col-xs-6.col-md-6.col-lg-6.col-sm-6 {
            &:nth-child(1) {
              padding-left: 0 !important;
            }
            &:nth-child(2) {
              padding-right: 0 !important;
            }
          }
        }
      }
      .wipSummaryTbl {
        .label-1 {
          .label-wraper {
            margin-bottom: 0 !important;
          }
        }
        table {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .wipSummaryTbl {
    &:not(.totalRow) {
      table {
        td {
          &:nth-child(1) {
            width: 8.33% !important;
            min-width: 8.33% !important;
            max-width: 8.33% !important;
          }
          &:nth-child(2) {
            width: 16.66% !important;
            min-width: 16.66% !important;
            max-width: 16.66% !important;
          }
          &:nth-child(3) {
            width: 20% !important;
            min-width: 20% !important;
            max-width: 20% !important;
          }
          &:nth-child(4) {
            width: 66.68% !important;
            min-width: 66.68% !important;
            max-width: 66.68% !important;
          }
        }
      }
    }
  }
  .wipSummaryTbl {
    // table{
    //   th{
    //     &.wipSummaryHeader{
    //       &:nth-child(1){
    //         width: 8.33% !important;
    //         min-width: 8.33% !important;
    //         max-width: 8.33% !important;
    //       }
    //     }
    //   }
    // }
    .totalRow {
      th.wipSummaryHeader {
        &[colspan="2"] {
          width: 24.99% !important;
          min-width: 24.99% !important;
          max-width: 24.99% !important;
        }
        &:nth-child(1) {
          width: 24.99% !important;
          min-width: 24.99% !important;
          max-width: 24.99% !important;
        }
      }
    }
  }
}
.rwrapperWiptbl {
  .head-box {
    &:nth-child(1) {
      padding-left: 0 !important;
    }
  }
  .twd-tbl {
    & > .row {
      & > .col-xs-6.col-md-6.col-lg-6.col-sm-6 {
        &:nth-of-type(odd) {
          padding-left: 0 !important;
        }
      }
    }
  }
  .reportContainer table {
    margin-bottom: 0;
  }
  .totalRow {
    .tb-wrap {
      table {
        tr {
          th {
            &.wipSummaryHeader {
              &[colspan="2"] {
                width: 41.33% !important;
                min-width: 41.33% !important;
                max-width: 41.33% !important;
              }
              &:nth-child(2) {
                width: 8.33% !important;
                min-width: 8.33% !important;
                max-width: 8.33% !important;
              }
              &:nth-child(3) {
                width: 16.66% !important;
                min-width: 16.66% !important;
                max-width: 16.66% !important;
              }
              &:nth-child(4) {
                width: 40% !important;
                min-width: 40% !important;
                max-width: 40% !important;
              }
            }
          }
        }
      }
    }
  }
}
.rwrapperFreeIncSum {
  table {
    margin-bottom: 0 !important;
  }
  .head-box {
    &:nth-child(1) {
      padding-left: 0 !important;
    }
  }
  & > .row {
    & > .col-xs-6.col-md-6.col-lg-6.col-sm-6 {
      &:nth-of-type(odd) {
        padding-left: 0 !important;
      }
    }
    & > .col-xs-6 {
      .emp_topvaldiv {
        .label-wraper {
          margin-bottom: 20px !important;
        }
      }
    }
  }
}
.rwrapperFreeIncDetails {
  table {
    margin-bottom: 0 !important;
  }
  .head-box {
    &:nth-child(1) {
      padding-left: 0 !important;
    }
  }
  & > .row {
    & > .col-xs-6.col-md-6.col-lg-6.col-sm-6 {
      &:nth-of-type(odd) {
        padding-left: 0 !important;
      }
    }
    & > .col-xs-6 {
      .emp_topvaldiv {
        .label-wraper {
          margin-bottom: 20px !important;
        }
      }
    }
  }
}

.reportWrapper {
  .empwrk_sum_rowbox {
    &.plm_empwork_income_grid {
      .reportTbl {
        td,
        th {
          &.feeIncomeDetailHeader {
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
              min-width: 16.66% !important;
              width: 16.66% !important;
              max-width: 16.66% !important;
            }
          }
        }
      }
      .totalRow {
        th {
          &.feeIncomeDetailHeader {
            &:nth-child(1) {
              width: 34.1% !important;
              min-width: 34.1% !important;
              max-width: 34.1% !important;
            }
            &:nth-child(2) {
              width: 31.1% !important;
              min-width: 31.1% !important;
              max-width: 31.1% !important;
            }
            &:nth-child(3) {
              width: 31% !important;
              min-width: 31% !important;
              max-width: 31% !important;
            }
          }
        }
      }
    }
  }
}
.rwrapperEmpWorkSum {
  .reportContainer .feeincome_dtl_rowbox {
    &.plm_empwork_income_grid {
      .reportTbl {
        td,
        th {
          &.feeIncomeDetailHeader {
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
              min-width: 16.66% !important;
              width: 16.66% !important;
              max-width: 16.66% !important;
            }
          }
          &:nth-last-child(1) {
            span {
              display: none !important;
            }
          }
        }
      }
      .totalRow {
        th {
          &.feeIncomeDetailHeader {
            &:nth-child(4) {
              width: 33.36% !important;
              min-width: 33.36% !important;
              max-width: 33.36% !important;
            }
            &[colspan="2"] {
              &:nth-child(1) {
                width: 33.32% !important;
                min-width: 33.32% !important;
                max-width: 33.32% !important;
              }
              &:nth-child(2) {
                width: 24.99% !important;
                min-width: 24.99% !important;
                max-width: 24.99% !important;
              }
            }
          }
        }
      }
    }
    .totalRow {
      th {
        &.feeIncomeDetailHeader {
          &:nth-child(4) {
            width: 33.36% !important;
            min-width: 33.36% !important;
            max-width: 33.36% !important;
          }
          &[colspan="2"] {
            &:nth-child(1) {
              width: 33.32% !important;
              min-width: 33.32% !important;
              max-width: 33.32% !important;
            }
            &:nth-child(2) {
              width: 24.99% !important;
              min-width: 24.99% !important;
              max-width: 24.99% !important;
            }
          }
        }
      }
    }
  }
}

.rwrapperFutTime,
.rwrapperClientCostSum {
  .col-lg-3.col-md-3.col-xs-3.col-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
    &:nth-child(1) {
      padding-left: 15px !important;
    }
  }
  .label-wraper {
    label {
      width: auto !important;
    }
  }
}
.rwrapperClient {
  .label-2.report-lbl-min-width {
    padding-right: 5px !important;
  }
}

/*
.reportWrapper.timesheetReport{
  .weeklyGrid{
    .total_expenses_content{
      .total_exp_coldiv{
        .totalRow 
        table.reportTbl thead tr{
          .th{
            &.totalFirstCol{
              width: 58.31% !important;
            }
            width: 41.69% !important;
            .th-cell{
              justify-content: left !important;
            }
          }
         }
        
      } 
    } 
  } 

}
*/

.reportWrapper {
  table tr th {
    font-size: 9px !important;
    .th-cell {
      font-size: 9px !important;
      span {
        // white-space: nowrap !important;
        // flex-wrap: nowrap !important;
      }
    }
  }
  .totalRow {
    table.reportTbl tr th .th-cell span {
      white-space: initial !important;
    }
  }
  &.rwrapPdf {
    .label-wraper {
      .l-value-wrap {
        font-weight: 400 !important;
      }
    }
    .reportContainer {
      .totalRow {
        .th-cell {
          display: flex !important;
          align-items: center !important;
          padding: 5px 10px !important;
          span {
            font-size: 11px !important;
            font-weight: 900 !important;
            white-space: nowrap;
          }
        }
        table.reportTbl {
          tr {
            th {
              span {
                font-size: 11px !important;
                // white-space: nowrap !important;
              }
            }
          }
        }
      }
    }
  }
}

.reportWrapper {
  &.rwrapPdf {
    table {
      margin-bottom: 0 !important;
    }
    .plm_carep_top {
      .emp_topvaldiv {
        .label-wraper {
          display: flex !important;
          align-items: center !important;
        }
      }
    }
    .reportContainer {
      .wipHeader,
      .wipSummaryHeader,
      .incomeGridHeader {
        &:nth-child(1) {
          padding-left: 0 !important;
        }
      }
      .reportContainer .emp_topvaldiv .label-wraper {
        align-items: center !important;
        label {
          margin-bottom: 0 !important;
        }
      }
      .bgColorRow,
      .totalRow {
        table.reportTbl {
          tr {
            th,
            td {
              &:nth-child(1) {
                .th-cell,
                .td-cell {
                  padding-left: 0 !important;
                }
                padding-left: 10px !important;
              }
            }
          }
        }
      }
    }
  }

  .emp_topvaldiv {
    .label-wraper {
      min-height: 40px;
      label {
        margin-bottom: 0 !important;
        width: auto !important;
      }
    }
  }
  .totalRow {
    .reportTbl {
      thead {
        tr {
          th {
            .th-cell {
              padding-left: 10px !important;
              span {
                padding-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
.reportContainer {
  .wipTotalTbl {
    table.reportTbl {
      tr {
        th,
        td {
          &:nth-child(2) {
            max-width: 16.66% !important;
            width: 16.66% !important;
            min-width: 16.66% !important;
          }
        }
        td {
          &:nth-child(2) {
            span {
              white-space: normal !important;
              flex-wrap: wrap !important;
              word-break: normal !important;
            }
          }
        }
      }
    }
  }
  .wipSummaryTbl {
    .tb-wrap table tr {
      td {
        &:nth-child(3) {
          white-space: nowrap !important;
          flex-wrap: nowrap !important;
        }
      }
    }
  }
}

.reportWrapper.timesheetReport {
  .reportContainer {
    .weeklyGrid {
      .total_expenses_content {
        .totalRow {
          table.reportTbl {
            thead {
              tr {
                th {
                  &.totalFirstCol {
                    width: 58.8% !important;
                    min-width: 58.8% !important;
                    max-width: 58.8% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.weeklyGrid {
  .total_expenses_content {
    .totalRow {
      table.reportTbl {
        thead {
          tr {
            th {
              &.totalFirstCol {
                width: 58.8% !important;
                min-width: 58.8% !important;
                max-width: 58.8% !important;
              }
            }
          }
        }
      }
    }
  }
  table {
    tr {
      td {
        &.dark-green {
          font-weight: 700 !important;
        }
      }
    }
  }
}
.jobTypeF {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  padding-right: 0 !important;
  &.lbl-min-width.label-3 {
    padding-right: 0 !important;
  }
}
.rwrapPdf {
  .jobTypeF {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    padding-right: 15px !important;
    &.lbl-min-width.label-3 {
      padding-right: 15px !important;
    }
  }
}

.invBorderWrap {
  border: 1px solid black;
  margin: 0px;
  padding: 5px;
}

.invColor {
  background-color: lightgreen;
}

.invTblWrap {
  table {
    th {
      background-color: lightgreen;
    }
  }
}
