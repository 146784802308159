.plmclt_dtl_rowbox
{
    .cst_tbox_popmdiv {
        .plm_cstpopupbox_wrap {
            .form-section {
                margin-bottom: 0px;
                .form-control {
                    height: 30px;
                }
            }
            .popUpContainer {
                margin-top: 0px;
                overflow: auto;
                .tb-wrap {
                    overflow-y: auto;
                    padding: 0;
                    height: 100%;
                    margin: 0;
                    min-height: 100%;
                    max-height: 100%;
                    .plm_cmn_tbldiv {
                        height: 100%;
                    }
                    .table {
                        border-right-width: 1px;
                        border-right-style: solid;
                        .tbody {
                            >.td {
                                height: 30px;
                                min-width: 120px;
                                >div {
                                    height: 100% !important;
                                    display: flex;
                                    align-items: center;
                                    padding-left: 8px;
                                    padding-right: 8px;
                                    position: relative;
                                    .form-section {
                                        .form-control {
                                            border: 1px solid #ced4da;
                                        }
                                    }
                                    .popUpContainer {
                                        top: 30px;
                                        left: 0;
                                        overflow: auto;
                                        width: 100%;
                                        max-width: 100%;
                                        // min-width: 100%;
                                        min-width: 250px;
                                        .table {
                                            .tbody {
                                                >.td {
                                                    height: 30px;
                                                    min-width: 70px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .plm_cltrepo_top
    {
        .plm_cltrepo_top_inner
        {
            padding: 0 15px;
            .form-sectopm
            {
                .tblc_chklbl
                {
                    display: block;
                }
            }
           
            .radio_ibox {
                display: flex;

               .radio_inner {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                    &:not(:last-child)
                    {
                        
                            margin-right: 10px;
                       
                    }
                    label
                    {
                        margin-bottom: 0;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .plm_cltrepo_btm
    {
        height: calc(100% - 210px) !important;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        .plm_cltrepo_btm_inrcontent
        {           
            position: absolute;
            width: 100%;
            top: 0;
        
            
    }
    }
}