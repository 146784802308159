.clientreport_rowbox
{
    .plm_cltrepo_top
    {
        .plm_cltrepo_top_inner
        {
            padding: 0 15px;
        }
    }
    .plm_cltrepo_btm
    {
        overflow: auto;
        > .plm_clt_reprt_tbldiv
        {
            height: auto;
            &:not(:last-child)
            {
                margin-bottom: 10px;
            }
            .plm_carep_btm 
            {
                .tb-wrap
                {
                    .plm_cmn_tbldiv
                    {
                        .table
                        {
                            border-right-width: 1px;
                            border-right-style: solid;
                            border-left-width: 1px;
                            border-left-style: solid;
                        }
                    }
                }
            }  
        }

    }
}

.PinkBlue.reportWrapper{
    .clientreport_rowbox{
        .plm_cltrepo_top_inner{
            padding: 0 10px !important;
            .row{
                .col-4{
                    &:nth-child(1){
                        padding-left: 5px !important;
                    }
                    
                }
            }
        }
    }
    .plm_cltrepo_top,.plm_tsrept_top{
        .plm_rp_partner_col,.plm_cltrepo_top_inner{
            padding: 0 10px !important;
            .row{
                .col-4{
                    &:nth-child(1){
                        padding-left: 5px !important;
                    }
                }
            }
        }
    }
}