@import "../../scss/variable.scss";

// .sideBar{
//     width: 250px;
//     height: calc(100% - 0px);
//     position: absolute;
//     left: 0;
//     top: 80px;
//     z-index: 1;
//     overflow-y: auto;
//     padding-bottom: 50px;
//     box-shadow: 1px 3px 10px 4px rgba(0,0,0,.2);

//     > ul{
//         margin: 0 10px;
//     }
//     ul {
//         list-style-type: none;
//         padding-left: 0;
//         padding-top: 5px;
//         li{
//             margin: 2px 0;
//             a{
//                 text-decoration: none;
//                 color: #ffffff;
//                 display: flex;
//                 align-items: center;
//                 padding: 10px 15px;

//                 .linkIc{
//                     margin-right: 10px;
//                     width: 30px;
//                     height: 30px;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                 }
//                 .linkText{
//                     display: flex;
//                     justify-content: space-between;
//                     width: calc(100% - 40px);
//                 }
//                 &:hover{
//                     .linkIc{
//                         svg{
//                             color: #5c6bc0;
//                         }
//                     }
//                 }
//             }
//             a.active, a:hover{
//                 background: $sdBG1;
//                 color: #000;
//                 border-radius: 5px;
//                 cursor: pointer;
//             }
//         }
//     }
//     .active{
//         ul{
//             margin: 0 5px;
//             li a{

//                 justify-content: center;
//                 span.linkIc{
//                     margin-right: 0;
//                 }
//                 span.linkText{
//                     display: none;
//                 }
//             }
//         }
//     }
// }

/*new style sidebar*/
.navigation {
  // z-index: 999;
  // width: 100%;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  // top: 85px;
  // height: 100px;
  // padding-left: 15px;
  // background-color: transparent !important;

  .sideBar {
    border-radius: 5px;
    // margin-right: 15px;
    margin-top: 10px;
    display: flex;
    padding: 10px 15px;
    // overflow-y: auto;
    .navtgldiv {
      padding: 8px 0px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      /* li[data-menuname="mnm_Setup"] {
               .LinkIcRight
               {
                   display: none;
               }
            }*/
      li[data-menuname="mnm_Authorisation"] {
        .LinkIcRight {
          display: none;
        }
      }
      li[data-menuname="mnm_Time and Expenses"] {
        .LinkIcRight {
          display: none;
        }
      }
      //  li[data-menuname="mnm_Invoices"] {
      //     .LinkIcRight
      //     {
      //         display: none;
      //     }
      //  }
      li {
        a {
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // padding: 20px;
          // font-size: 12px;
          // font-weight: 400;
          // transition: color 0.3s;
          // text-decoration: none;
          // border-radius: 0.8rem;
          // margin: 10px;
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
          .linkText {
            text-align: center;
            display: flex;
            align-items: center;
            .LinkIcRight {
              margin-left: 3px;
            }
          }
        }
        a.active,
        a:hover {
          color: #000000;
          //  background: $sdBG1;
        }
        a .linkIc {
          background: rgba(255, 255, 255, 0.1);
          display: block;
          text-align: center;
          width: 50%;
          padding: 10px 0;
          border-radius: 0.8rem;
          transition: background 0.3s;
          display: none;
        }

        a.active .linkIc {
          background: rgba(255, 255, 255, 0.9);
          svg {
            color: #5c6bc0;
          }
        }
        a:hover .linkIc {
          background: rgba(255, 255, 255, 0.3);
        }
      }
      li:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
  .subMenuList {
    position: absolute;
    z-index: 100;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f1f1f1;
    box-shadow: 0 0 2px #ccc;
    border: 1px solid #ccc;
    min-width: 150px;
    top: 44px;
    max-width: 160px;

    > li {
      margin-right: 0px !important;
      position: relative;
      a {
        padding: 5px;
        display: block;
        .linkIc {
          display: none;
        }
        &:hover {
          text-decoration: none;
        }
      }
      > ul {
        top: 0;
        right: -150px;
      }
    }
  }
}

.App.Light {
  .navigation {
    .sideBar {
      .navtgldiv {
        .navbar-toggler {
          background-color: $plm_thm_light_menubtnbgcolor;
          color: $plm_thm_light_menubtncolor;
        }
      }
    }
  }
}
.App.Dark {
  .sideBar {
    background: #2a2e31 !important;
    scrollbar-color: #2a2e31;
    scrollbar-width: thin;
    // overflow-y: auto;
    a.active,
    a:hover {
      color: #ffffff !important;
      background: #5c6bc0 !important;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #404040;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #404040;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1a1a1a;
    border-radius: 0.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .navigation {
    .sideBar {
      a {
        margin: 5px !important;
      }

      .linkIc {
        margin-bottom: 0px;
      }
    }
  }
  .adminMainContent {
    margin-left: 60px;
    width: calc(100% - 60px);
    .cardBox {
      margin: 15px 0;
    }
  }
}

/*green theame*/
.App.green {
  .sideBar {
    background: #ccffcc !important;
    scrollbar-color: #ccffcc;
    scrollbar-width: thin;
    // overflow-y: auto;
    a {
      color: #000000;
    }
    a.active,
    a:hover {
      color: #ffffff !important;
      background: #00cc00 !important;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ccffcc;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccffcc;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #00cc00;
    border-radius: 0.8rem;
  }
}

/*blue theame*/
.App.blue {
  .sideBar {
    background: #80dfff !important;
    scrollbar-color: #80dfff;
    scrollbar-width: thin;
    // overflow-y: auto;
    a {
      color: #000000;
    }
    a.active,
    a:hover {
      color: #ffffff !important;
      background: #00aaff !important;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #e6f9ff;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e6f9ff;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #00aaff;
    border-radius: 0.8rem;
  }
}

/*red theame*/
.App.red {
  .sideBar {
    background: #ff6666 !important;
    scrollbar-color: #ff6666;
    scrollbar-width: thin;
    // overflow-y: auto;
    a {
      color: #000000;
    }
    a.active,
    a:hover {
      color: #ffffff !important;
      background: #ff0000 !important;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ffe6e6;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffe6e6;
    border-radius: 0.8rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ff0000;
    border-radius: 0.8rem;
  }
}

/* Media Queries Starts Here */
@media screen and (max-width: 767px) {
  .navigation .sideBar .navtgldiv {
    padding: 0px;
    width: 100%;
  }
  .navigation .sideBar ul {
    flex-direction: column;
    position: static;
    li {
      a {
        padding: 10px 15px;
        margin: 0px !important;
        display: block;
      }
    }
    li:not(:last-child) {
      margin-right: 0px;
    }
  }
}
/* Media Queries Ends Here */

/*pinkBlue theme*/
.App.PinkBlue {
  .navigation {
    .sideBar {
      .navtgldiv{
        padding: 2px 0px;
        padding-bottom: 0;
      }
      ul {
        li {
          padding: 8px 15px;
          border-top-left-radius: 10px !important;
          border-top-right-radius: 10px !important;
          &:not(:last-child){
            margin-right: 5px;
          }
        }
        li.active {
          // background: #d336b9 !important;
          // padding-left: 2px;
          // padding-right: 2px;
          opacity: .5;
        }
      }
      ul.inner_navmenu{
        li{
          
          background: rgba(167, 37, 146, .6);
          opacity: .8;
          &:hover{
            opacity: 1;
            background: rgba(167, 37, 146, 1);
            transform: scale(1.05);
          }
        }
        li.active{
          background: #a72592;
          opacity: 1;
        }
      }
    }
  }
 
 /* style according to xd file*/

 .reportHeader, .navigation{
    &.navigation{
      background: #A42998;
      .sideBar {
        padding: 0 0px;
        .navtgldiv{
          padding: 0 0;
        }
        ul li a .linkText{
           font-size: 16px !important;
          //font-size: clamp(0.8em, 1vw, 1rem) !important;
          font-weight: 600;
          text-decoration: none;
        }
        ul.inner_navmenu li{
          opacity: 1;
          // min-width: 160px;
          display: flex;
          justify-content: center;
          // padding: initial;
          padding: .5rem 1.6rem;
          // height: 40px;
          align-items: center;
          &.active{
            background: rgba(255, 255, 255, 0.3);
            border-radius: 0 !important;
          }
          &:hover{
            transform: scale(1);
            a{
              text-decoration: none;
            }
          }
          
        }
      } 
      
    }
  }
  
}


