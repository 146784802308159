.App.Dark {
  .cardBox {
    background: #2a2e31 !important;
    border-color: #323639 !important;

    .card-head {
      background: #2a2e31 !important;
      border-color: #323639 !important;
      .card-title {
        color: #ffffff;
      }
    }
  }
}

.App.PinkBlue {
  .plm_layout_right {
    left: 235px;
    width: calc(100% - 235px);
  }

  .plm_invwiz_stepw2 {
    color: #6093aa;
  }
  .tax_rate_inner {
    border: none;
  }
  .plm_invwiz_stepw2 {
    .plm_cmn_cardbox {
      min-height: calc(100vh - 215px);
    }
  }
  .plm_cmn_cardbox,
  .plm_inv_wiz_step5 {
    height: initial !important;
  }
  .plm_inv_wiz_step5 {
    min-height: 400px;
  }
  .plm_inv_wiz_step4 {
    .plm_addnarate {
      top: -5px;
      button {
        background: #ffffff !important;
        color: #b7c2cb !important;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .btext {
          display: none;
        }
      }
    }
  }
}

.App.PinkBlue {
  .form-control {
    border-radius: 0 !important;
    min-height: 36px;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    border-radius: 0 !important;
    min-height: 36px;
  }
  .cardBox .btn,
  .cardBox button {
    font-size: 12px;
  }

  .col_rps_inn_top {
    height: calc(100vh - 200px) !important;
  }

  // .plm_creat_inv_div{
  //   table{
  //     th,td{
  //       min-width: initial !important;
  //       &:nth-child(1){
  //         width: 9%;
  //       }
  //       &:nth-child(2){
  //         width: 9%;
  //       }
  //       &:nth-child(3){
  //         width: 9%;
  //       }
  //       &:nth-child(4){
  //         width: 9%;
  //       }
  //       &:nth-child(5){
  //         width: 16%;
  //       }
  //       &:nth-child(6){
  //         width: 7%;
  //       }
  //       &:nth-child(7){
  //         width: 9%;
  //         padding: 5px 5px !important;
  //       }
  //       &:nth-child(8){
  //         width: 8%;
  //       }
  //       &:nth-child(9){
  //         width: 8%;
  //       }
  //       &:nth-child(10){
  //         width: 11%;
  //       }
  //       &:nth-child(11){
  //         width: 5%;
  //       }
  //     }
  //   }
  // }

  .plm_authcol_left {
    table {
      th,
      td {
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 15%;
        }
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 20%;
        }
        &:nth-child(6) {
          width: 20%;
        }
      }
    }
  }

  .plm_clients_list_div {
    table {
      th,
      td {
        &:nth-child(1) {
          width: 10%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 20%;
        }
        &:nth-child(6) {
          width: 10%;
        }
      }
    }
  }
  .plm_user_list_div {
    table {
      th,
      td {
        &:nth-child(1) {
          width: 13%;
        }
        &:nth-child(2) {
          width: 14%;
        }
        &:nth-child(3) {
          width: 13%;
        }
        &:nth-child(4) {
          width: 13%;
        }
        &:nth-child(5) {
          width: 14%;
        }
        &:nth-child(6) {
          width: 13%;
        }
        &:nth-child(7) {
          width: 9%;
        }
        &:nth-child(8) {
          width: 10%;
        }
      }
    }
  }

  .prn_colh {
    padding-left: 0;
  }

  .plm_floatbtn_div.plm_cmn_cardbtn_alone_div {
    .PinkBlue.btn {
      background: #a42998;
      color: #ffffff;
      padding: 2px 10px;
    }
    bottom: 10px !important;
  }

  .tbBtn.plm_cmn_cardbtn_alone_div {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: #fff;
    padding-top: 5px;
    width: 100%;
  }
  .PinkBlue.btn {
    .btext {
      white-space: nowrap;
    }
  }
  .col-2.prn_colh {
    padding-left: 15px !important;
  }
  .plm_cmn_tabs {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
  }
  .plm_cmn_wiz_step {
    margin-top: 0 !important;
  }

  .adminMainContentapp_inner_wrap {
    .headerSearch {
      width: 380px;
      top: 8px;
      right: 50px;
      input {
        font-size: 12px;
        padding: 5px 15px;
        height: 30px;
        min-height: initial !important;
      }
      input[type="text"]:focus {
        border: 1px solid #a42998;
        outline: none;
      }
    }
    .tb-wrap .plm_cmn_tbldiv {
      margin-top: 30px;
      //max-height: 400px;
      max-height: calc(100vh - 322px);
      overflow: auto !important;
      table {
        td {
          &:nth-child(7) {
            padding: 0 5px !important;
          }
        }
        th,
        td {
          &:nth-child(1) {
            width: 15%;
          }
          &:nth-child(2) {
            width: 15%;
          }
          &:nth-child(3) {
            width: 16%;
          }
          &:nth-child(4) {
            width: 11%;
          }
          &:nth-child(5) {
            width: 10%;
          }
          &:nth-child(6) {
            width: 10%;
          }
          &:nth-child(7) {
            width: 5%;
          }
          &:nth-child(8) {
            width: 9%;
          }
          &:nth-child(9) {
            width: 9%;
            padding: 0 5px !important;
          }
        }
      }
    }
  }
  .plm_addnarate {
    top: -5px;
    button {
      background: #ffffff !important;
      color: #b7c2cb !important;
      width: 20px;
      height: 20px;
      display: flex;
      border-radius: 50%;
      min-width: initial !important;
      justify-content: center;
      align-items: center;
      .btext {
        display: none;
        font-family: Verdana, sans-serif;
      }
    }
  }
  .btn-primary {
    background: #a42998;
    color: #ffffff;
    border: none;
    padding: 4px 10px;
    font-size: 12px;
    line-height: initial !important;

    span.btext {
      white-space: nowrap;
    }
    &:focus {
      background: #a42998;
    }
    &:focus-within {
      background: #a42998;
    }
  }

  label {
    font-size: 14px;
  }
  select {
    font-size: 13px;
  }
  .total_timeslot_content h5 {
    font-weight: 600;
    color: #475f7b;
  }
  .total_timeslot_content {
    table {
      tr {
        td {
          .plm_cstpopupbox_wrap {
            .form-section {
              width: 98%;
            }
          }
        }
      }
    }
  }
  .tb-wrap {
    margin-bottom: 10px !important;
    overflow: initial !important;
    .plm_cmn_tbldiv {
      overflow: initial !important;
    }
  }
  .pdf-textWrap {
    .form-group {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      .tblc_chklbl {
        margin: 0 5px;
      }
    }
  }

  .cardBox {
    background: #ffffff !important;
    border: none;
    margin: 0;
    .card-head {
      //background: #6093AA !important;
      background: #6093aa !important;
      border-radius: 0;
      padding: 10px 15px !important;
      padding-left: 20px;

      .card-title {
        color: #ffffff !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .card-body {
      height: initial !important;
      padding: 0;

      .label-1 {
        .emp_topvaldiv {
          padding: 0 0 !important;
        }
      }

      .plm_cmn_wiz_content {
        overflow: initial !important;
        margin-bottom: 30px;
      }

      .emp_tscol {
        padding: 0;
        .emp_topvaldiv {
          .label-wraper {
            background: transparent !important;
            color: #6093aa !important;
            label {
              color: #475f7b !important;
            }
          }
        }
      }

      .tab-content {
        height: auto !important;
      }
      .tab-content .tab-pane {
        overflow: initial !important;
      }

      .clientreport_rowbox {
        .form-control {
          min-height: unset;
        }
        button {
          background: #a42998;
          color: #ffffff;
          padding: 4px 30px;
          font-size: 16px;
          line-height: initial;
          min-height: 25px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          //border-radius: 0.25rem !important;
        }
        padding: 0px 0;
        select {
          background: #f6f6f6;
          border: none;
          height: 30px;
          padding: 5px 5px;
        }
        .tblc_chklbl {
          label {
            // background-color: #6093AA;
            // color: #ffffff !important;
            // color: #6093AA !important;
            font-weight: 600;
            width: 100%;
            padding: 6px;
            font-size: 12px;
            margin-bottom: 0px;
          }
        }
      }

      .plm_inv_wiz_step1 {
        .react-datepicker-wrapper {
          .react-datepicker__input-container {
            input {
              background: #eeecec;
              border: none;
            }
          }
        }
        .cst_tbox_popmdiv {
          .form-control {
            background: #eeecec;
            border: none;
          }
        }
        .tblc_chklbl {
          display: block;
          margin-top: 5px;
          label {
            color: #6093aa;
            font-weight: 600;
          }
        }
        .plm_cmn_cardbtn_alone_div {
          flex-direction: row-reverse;
          button {
            margin-left: 0;
            margin-right: 0;
            width: calc(8.33% - 20px);
            &:nth-child(2) {
              border: 1px solid #eeecec;
              background: #ffffff;
              color: #a42998;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            &:nth-child(1) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }
      .trow_time_top {
        min-height: calc(100vh - 450px) !important;
      }
      .trow_time_top {
        .trow_time_tp_left {
          table {
            th,
            td {
              min-width: initial !important;
              .popUpContainer {
                table {
                  tr {
                    th,
                    td {
                      &:nth-child(1) {
                        width: 25% !important;
                      }
                      &:nth-child(2) {
                        width: 75% !important;
                      }
                    }
                  }
                }
              }
              &:nth-child(1) {
                width: 13%;
              }
              &:nth-child(2) {
                width: 20%;
              }
              &:nth-child(3) {
                width: 13%;
              }
              &:nth-child(4) {
                width: 41%;
              }
              &:nth-child(5) {
                width: 13%;
              }
            }
          }
        }
      }

      .total_expenses_content {
        table {
          th,
          td {
            min-width: initial !important;
            .popUpContainer {
              table {
                tr {
                  th,
                  td {
                    &:nth-child(1) {
                      width: 10px !important;
                    }
                  }
                }
              }
            }
            &:nth-child(1) {
              width: 10%;
              min-width: 120px !important;
            }
            &:nth-child(2) {
              width: 10%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 10%;
            }
            &:nth-child(5) {
              width: 40%;
            }
            &:nth-child(6) {
              width: 10%;
            }
          }
        }
      }

      .trow_time_top,
      .tdivexps_top {
        height: initial !important;
        overflow: initial !important;
        .tb-wrap {
          overflow: initial !important;
          .table-responsive.plm_cmn_tbldiv {
            overflow: initial !important;
            height: initial !important;
          }
        }
      }
      .trow_time_tp_right {
        table {
          border-spacing: 0 3px;
          table-layout: fixed;
          tr {
            &:nth-of-type(even) {
              td {
                border: none !important;
              }
            }
            th {
              .th-cell {
                span {
                  white-space: nowrap;
                  display: flex;
                  justify-content: center;
                  width: 100%;
                }
              }
            }
            td,
            th {
              width: calc(100% / 7) !important;
              &:nth-last-child(1) {
                width: 80px !important;
                min-width: 80px !important;
              }
              & > span {
                display: flex;
                justify-content: center;
                width: 100%;
              }
              .input-wrap {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
      .trow_time_bottom {
        position: sticky;
        background: #ffffff;
        z-index: 10;
        bottom: 90px;
        margin-left: -15px;
        margin-right: -15px;
        .row {
          background: #ffffff !important;
          padding-top: 5px;
          margin-left: 0;
          margin-right: 0;
        }
        .totalRowHead {
          margin-bottom: 0px;
          height: 36px;
          display: flex;
          align-items: center;
          width: 120px;
        }
        .totalRow {
          padding: 0;
          .tb-wrap {
            margin-bottom: 0 !important;
          }
          table {
            border-spacing: 0 3px;
            table-layout: fixed;
            thead {
              tr {
                th {
                  background: #eeecec !important;
                  padding: 5px 5px !important;
                  width: calc(100% / 7) !important;
                  &:nth-last-child(1) {
                    width: 80px !important;
                    min-width: 80px !important;
                  }
                  .th-cell {
                    span {
                      display: flex;
                      justify-content: center;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .grand_total_div {
        position: sticky;
        background: #ffffff;
        z-index: 10;
        bottom: 40px;
        margin-top: 0 !important;
        .row {
          background: #ffffff !important;
        }
        .totalRowHead {
          margin-bottom: 10px;
          height: 36px;
          display: flex;
          align-items: center;
          width: 120px;
        }
        .totalRow {
          padding: 0;
        }
        table {
          border-spacing: 0px 3px;
          table-layout: fixed;
          tr {
            th {
              padding: 5px 5px !important;
              max-width: initial !important;
              width: calc(100% / 7) !important;
              &:nth-last-child(1) {
                width: 80px !important;
                min-width: 80px !important;
              }
              .th-cell {
                span {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
      table {
        border-spacing: 5px 3px;
        border: none !important;
        tr {
          th {
            font-weight: 600 !important;
            font-size: 11px !important;
            border: none;
            text-transform: capitalize;
          }
          th,
          td {
            font-size: 11px;
            // color: #6093AA !important;
            input[type="checkbox"] {
              width: 15px;
              height: 15px;
            }
            button {
              font-size: 16px;
              padding: 3px 10px;
              height: 40px !important;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0;
            }
          }
        }

        tbody {
          tr {
            &:nth-of-type(odd) {
              td {
                background: #eeeeee;
              }
            }
            &:nth-of-type(even) {
              td {
                background: #ffffff !important;
                border: 1px solid #eeeeee !important;
                padding: 9px 5px !important;
              }
            }
          }
        }
      }

      .plm_inv_wiz_step2 {
        table {
          th,
          td {
            &:nth-child(7) {
              max-width: 120px !important;
            }
          }
        }
      }

      .card-foot {
        .hrDivider {
          display: none !important;
        }
        button {
          background: #a42998;
          width: 100%;
          color: #ffffff;
          margin-top: 10px;
          border-radius: 0;
        }
      }
      .plm_authrow {
        margin-left: 0;
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px;
      }

      .form-control,
      .react-datepicker-wrapper input {
        color: #6093aa !important;
        font-family: Verdana, sans-serif;
      }
      label.control-label {
        color: #6093aa;
        font-family: Verdana, sans-serif;
      }
      .label-wraper {
        label {
          // color: #ffffff !important;
        }
      }

      .plm_cmn_tbldiv {
        table {
          border-right: none !important;
          tr {
            &:nth-of-type(even) {
              td {
                background: #eeeeee;
              }
            }
          }
        }
      }
      table {
        th {
          color: #6093aa !important;
        }
        th,
        td {
          border: none !important;
        }
      }

      .card-foot {
        .hrDivider {
          display: none !important;
        }
        button {
          background: #a42998;
          width: 100%;
          color: #ffffff;
          margin-top: 10px;
          height: 40px;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px;
        }
      }
      .plm_authrow {
        margin-left: 0;
        margin-right: 0;
      }

      .vtime_cardiv {
        .vtime_topdiv {
          .card-head {
            display: inline-block;
            padding: 15px 15px;
          }
        }
        .vtime_topsbox {
          padding: 15px 0;
          margin-left: -10px !important;
          .clientreport_rowbox {
            padding: 0 15px !important;
          }
          button {
            background: #a42998;
            color: #ffffff;
            min-height: 25px;
            padding: 2px 10px;
            font-size: 16px;
            height: 40px;
            border-radius: 0;
          }
        }
      }
      .vtime_btmdiv {
        .card-head {
          display: inline-block;
          padding: 10px 15px !important;
        }
        .plm_vts_div {
          padding: 15px 0 !important;
          button {
            background: #a42998;
            color: #ffffff;
            min-height: 25px;
            padding: 2px 10px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            border-radius: 0;
          }
        }
      }
      .plm_authcol_left {
        .tb-wrap {
          height: auto !important;
          max-height: initial !important;
          min-height: initial !important;
        }
        .card-head {
          display: inline-block;
          padding: 10px 15px !important;
          border-radius: 0 !important;
        }
        table {
          tr {
            &:nth-of-type(odd) {
              td {
                background: #eeecec;
              }
            }
            table {
              tr {
                &:nth-of-type(odd) {
                  td {
                    background: #eeecec;
                  }
                }
              }
              th {
                border-top: none !important;
                border-bottom: none !important;
                border: none;
                .th-cell span {
                  color: #475f7b !important;
                }
              }
              td {
                border: none;
                border-top: none !important;
                border-bottom: none !important;

                button {
                  background: #a42998;
                  color: #ffffff;
                  border: none;
                }
              }
            }
          }
        }
      }

      .emp_topvaldiv {
        margin-top: 10px;
        .label-wraper {
          color: #6093aa;
          padding: 5px 10px;
          display: flex;
          align-items: center;
          label {
            font-weight: 600;
            width: auto !important;
            margin-bottom: 0;
          }
          span {
            font-weight: 400;
          }
        }
      }

      .plm_inv_wiz_step2 {
        padding-top: 5px;
        .emp_topvaldiv {
          .label-wraper {
            background: transparent !important;
            color: #6093aa !important;
            label {
              color: #6093aa !important;
            }
          }
        }
      }
      .tbBtn {
        height: auto;

        .btn.btn-primary {
          background: #a42998;
          font-size: 16px;
          line-height: initial;
          padding: 4px 10px;
          min-height: 25px;
          height: 40px;
          border-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        button.roundBtn {
          background: #ffffff !important;
          color: #b7c2cb !important;
          width: 20px;
          height: 20px !important;
          display: flex;
          min-height: initial !important;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          span {
            padding-top: 0px;
          }
        }
      }
      .listWrap {
        padding: 0 !important;
        li {
          padding: 7px 10px !important;
          color: #475f7b !important;
          padding-left: 20px !important;
          border-bottom: 1px solid #eeeeee;
          font-size: 14px;
          &:nth-of-type(even) {
            background: #eeeeee !important;
          }
          &.selected {
            border-left: 5px solid #2c9cd5 !important;
          }
          .linkPrimary {
            background: transparent;
            color: #bebebe;
            font-size: 14px;
          }
        }
      }
      .plm_cmn_cardbtn_alone_div {
        margin-top: 15px;
        margin-bottom: 5px;
      }
      .plm_cmn_cardbtn_alone_div.plm_addnarate {
        // margin-top: 20px;
      }

      .nav-tabs {
        border-bottom: none;
        a {
          background: rgba(90, 147, 168, 0.7);
          color: #ffffff;
          border-top-left-radius: 10px !important;
          border-top-right-radius: 10px !important;
          padding: 8px 15px !important;
          &.active {
            background: #6093aa !important;
          }
        }
      }
      .tab-content {
        .tsgridtbldiv {
          table {
            border-right: none !important;
            tr {
              td {
                height: 36px !important;
              }
            }
          }
        }
        .tab-content {
          .tsgridtbldiv {
            table {
              border-right: none !important;
              tr {
                td {
                  height: 42px !important;
                }
              }
            }
          }
          .plm_cmn_tbldiv {
            table {
              border-left: none !important;
              tr {
                &:nth-of-type(even) {
                  td {
                    background: #eeeeee;
                  }
                }
              }
            }
          }
        }
      }

      .vtime_topsbox,
      .plm_vts_div {
        margin-left: 0;
        margin-right: 0;
        .weekBox {
          padding: 0 !important;
        }
        .tblc_chklbl {
          label {
            color: #6093aa;
            font-weight: 600;
          }
        }
        select {
          border: none !important;
          background: transparent;
          border-bottom: 1px solid #eeecec !important;
          color: #6093aa;
        }
      }

      table {
        border-spacing: 5px 3px;
        border: none !important;
        th {
          font-weight: 600 !important;
          font-size: 11px !important;
          border: none;
          text-transform: capitalize;
          word-break: break-word;
        }
        th,
        td {
          font-size: 11px;
          line-height: 16px;
          padding: 10px 5px !important;
          color: #475f7b !important;
          font-family: Verdana, sans-serif;
          // min-width: initial !important;
          input[type="checkbox"] {
            width: 15px;
            height: 15px;
            font-family: Verdana, sans-serif;
          }
          .form-control {
            font-size: 12px !important;
            font-family: Verdana, sans-serif;
          }
        }
        td {
          .linkPrimary {
            background: transparent;
            color: #666666;
          }
          .popUpContainer {
            .tb-wrap {
              overflow-y: auto !important;
            }
            table {
              tr {
                &:nth-of-type(odd) {
                  td {
                    background: #eeecec !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.App.PinkBlue {
  .card-body {
    table {
      tr {
        th,
        td {
          .form-control {
            min-height: initial !important;
          }
        }
      }
    }
  }

  .plm_creat_inv_div {
    table {
      th,
      td {
        min-width: initial !important;

        .form-control {
          height: 28px;
          padding: 5px 5px !important;
        }
        .form-section {
          padding: 0 !important;
          width: 100%;
        }

        &:nth-child(1) {
          width: 9%;
        }
        &:nth-child(2) {
          width: 9%;
        }
        &:nth-child(3) {
          width: 9%;
        }
        &:nth-child(4) {
          width: 9%;
        }
        &:nth-child(5) {
          width: 16%;
        }
        &:nth-child(6) {
          width: 7%;
        }
        &:nth-child(7) {
          width: 9%;
        }
        &:nth-child(8) {
          width: 8%;
        }
        &:nth-child(9) {
          width: 8%;
        }
        &:nth-child(10) {
          width: 11%;
        }
        &:nth-child(11) {
          width: 5%;
        }
      }
      td {
        &:nth-child(7) {
          padding: 4px 5px !important;
          span {
            padding: 0 5px;
          }
          .form-section {
            padding: 0 !important;
            span {
              padding: 0 !important;
              span.txtSuffix {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  .plm_authcol_left {
    table {
      tr {
        td {
          &:nth-child(6) {
            padding: 5px 5px !important;
          }
        }
      }
    }
  }
}

/*pinkblue theme end*/

.cardBox {
  padding: 0;
  display: inline-block;
  // box-shadow: 1px 3px 10px 4px rgba(0,0,0,.2);
  margin: 15px 0;
  border-radius: 0.8rem;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  border-radius: 0.8rem;
  width: 100%;
  position: relative;
  flex: 1;
  z-index: 1;
  .Dark {
    background: #5c6bc0 !important;
    border: 1px solid #5c6bc0 !important;
  }
  .btn,
  button {
    font-size: 11px;
    border-radius: 0.25rem;
  }
  .card-head {
    padding: 12px 15px;
    background: #f2f2f2;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
    .card-title {
      margin-bottom: 0;
      font-size: 1rem;
      // color: #5c6bc0;
      color: #6600ff;
    }
  }
  .card-body {
    padding: 0 15px 10px;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    .tb-wrap {
      margin-left: -0.85rem;
      margin-right: -0.85rem;
      position: relative;
      min-height: 300px;
      overflow: auto;
      max-height: 400px;
      padding: 0;
      table {
        // position: relative;
        // overflow-y: auto;
        border-collapse: separate;
        border-spacing: 0;
        th {
          position: sticky;
          top: 0;
          background: #ffffff;
          z-index: 1;
          border-bottom: 1px solid #e6e6e6;
        }
        tr {
          &:nth-child(1) {
            td {
              border-top: none !important;
            }
          }
        }
      }
    }
    .hr-divider {
      border-top: 1px solid #e6e6e6;
      margin-bottom: 10px;
      margin-left: -0.8rem;
      margin-right: -0.8rem;
    }
    table {
      tr {
        // &:nth-of-type(odd){
        //     background-color: #ffffff;
        // }
        th,
        td {
          border-left: 1px solid #e6e6e6;
          border-top: 1px solid #e6e6e6 !important;
          border-bottom: none;
          font-family: "Fira Sans", sans-serif;
          // font-family: 'Oswald', sans-serif;
          line-height: 1.6;
          letter-spacing: 1px;

          .form-section {
            margin-bottom: 0;
          }
          &:nth-child(1) {
            border-left: 0;
            padding-left: 15px;
          }
        }
        &:nth-last-child(1) {
          td {
            border-bottom: 1px solid #e6e6e6 !important;
          }
        }
      }

      th {
        font-size: 11px;
        padding-top: 10px;
        padding-bottom: 5px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        vertical-align: top;
        color: #a7abc3 !important;
        border-top: none;
        min-width: 70px;
        input[type="radio"],
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          margin: auto;
        }
        .searchWrap {
          background: #e6e6e6;
          border-radius: 10px;
          margin: 5px 0;
          & > div {
            position: relative;
            & > div {
              border: none !important;
              height: 22px;
              align-items: center;
              display: flex;
              i {
                margin: 0 8px;
              }
            }
          }

          ul {
            top: 22px;
            //   max-width: initial;
            color: #000000;
          }
        }
      }
      td {
        font-size: 11px;
        font-weight: 500;
        min-width: 70px;
        line-height: 1.6;
        vertical-align: middle;
        padding: 0.5rem 0.75rem;
        color: #212529;
        input[type="radio"],
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          margin: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .card-body {
    .tb-wrap {
      table {
        th {
          font-size: 1rem;
        }
        td {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
  .cardBox .btn,
  .cardBox button {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .card-foot {
    padding: 0.5rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 960px) {
  .App.PinkBlue .tb-wrap .plm_cmn_tbldiv {
    overflow: auto !important;
  }
}
