.navigation{
    // z-index: 999;
    // width: 100%;
    // position: fixed;
    // left: 0;
    // bottom: 0;
    // top: 85px;
    // height: 100px;
    // padding-left: 15px;
    // background-color: white;

    .sidebar{
        border-radius: 5px;
        background-color: #5c6bc0;
        margin-top: 10px;
        display: flex;
        padding: 10px 15px;
        ul{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            li{               
                a{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.8);                   
                    font-size: 12px;
                    font-weight: 400;
                    transition: color 0.3s;
                    text-decoration: none;
                }
                a.active{
                    color: #ffffff;
                }
                a .linkIc{
                    background: rgba(255, 255, 255, 0.1);                   
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    /* font-size: 15px; */
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 5px;
                }
                a.active .linkIc{
                    background: rgba(255, 255, 255, 0.9);
                    svg{
                        color: #5c6bc0;
                    }
                }
                a:hover .linkIc{
                    background: rgba(255, 255, 255, 0.9);
                    svg{
                        color: #5c6bc0;
                    }
                }
               
            }
            li:not(:last-child)
            {
                margin-right:15px;
            }
        }
    }
}



@media only screen and (max-width: 600px){
    .navigation{       
        .sidebar{           
            a{
                padding: 0 !important;
            }
            .linkText{
                display: none;
            }
            .linkIc{
               margin-bottom: 0px;
            }
        }
    }
    .adminMainContent{
        margin-left: 60px;
        width: calc(100% - 60px);
        .cardBox{
            margin: 15px 0;
        }
    }
}

.App.PinkBlue{
    .navigation{
        .ANavigation{
            background: #6093AA;
            margin-left: -15px;
            margin-right: -15px;
            padding: 8px 30px;
            color: #ffffff;
            font-weight: 600;
            ul{
                margin: 0;
                padding-left: 0;
                li{
                    list-style-type: none;
                    font-size: 16px;
                }
            }
        }
    }
    header{
        color: #6093AA;
        .header_rlist{  
            li{
                button{
                    font-size: 14px;
                    color: #6093AA;
                    padding: 5px 20px;
                    &:nth-child(1){
                      color: #6093AA ;
                    }
                }
            }
          }
          .header_rlist > li{
              a{
                .pro-name{
                    color: #6093AA;
                }
              }
            
        }
    }
    

   

  
    
}