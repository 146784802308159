.plm_empwork_income_grid
{
    .tb-wrap
    {overflow-y: auto !important;               
        height: 100% !important;
        margin: 0 !important;
        min-height: 100% !important;
        max-height: 100% !important;
       .table
       {
        border-right-width: 1px;
        border-right-style: solid;
        border-left-width: 1px;
        border-left-style: solid;        
        .th, .td
        {
            min-width: 70px !important;
            max-width: 70px !important;
            padding: 8px 3px !important;
            word-break: break-word;
            
        }
       }
    }
}