.plm_timeexpnse_main_div {
  height: 100%;
  .plm_cmn_cardbox {
    height: 100%;
    margin: 0px;
    border-radius: 5px;
    position: relative;
    .card-head {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .card-body {
      height: calc(100% - 48px);
      padding: 0px;
      border: none;
      border-radius: 0px;
      .groupWrap {
        padding: 0 15px;
        .emp_tsrow {
          margin-top: 5px;
        }
        .emp_tscol:not(:last-child) {
          margin-bottom: 10px;
        }
        .emp_tscol {
          .emp_topvaldiv {
            font-weight: 400;
            font-size: 15px;
            .label-wraper {
              label {
                margin-bottom: 0px;
                font-weight: 400;
                font-size: 15px;
              }
            }
          }
          .wkrowdiv {
            .weekBox {
              margin-bottom: 0px;
            }
            .tDate {
              margin-bottom: 0px;
            }
          }
        }
      }
      .plm_cmn_tabs {
        height: calc(100% - 115px);
        .total_timeslot_content {
          height: 100%;
          //margin: 0 -15px;
          h5 {
            margin-bottom: 0px;
            font-size: 1rem;
          }
          .plm_timrcoldiv {
            height: calc(100% - 39px);
            .trow_time_top {
              height: calc(100% - 33px);
              overflow: auto;
              .trow_time_tp_left {
                padding-left: 0px;
                .tsgridtbldiv {
                  height: 100%;
                  .tb-wrap {
                    overflow-y: auto;
                    padding: 0;
                    height: 100%;
                    margin: 0;
                    min-height: 100%;
                    max-height: 100%;
                    .plm_cmn_tbldiv {
                      height: 100%;
                    }
                    .table {
                      border-right-width: 1px;
                      border-right-style: solid;
                      .tbody {
                        > .td {
                          height: 30px;
                          min-width: 120px;
                          > div {
                            height: 100% !important;
                            display: flex;
                            align-items: center;
                            padding-left: 8px;
                            padding-right: 8px;
                            position: relative;
                            .form-section {
                              position: absolute;
                              .form-control {
                                border: 1px solid #ced4da;
                                height: 28px;
                              }
                            }
                            .popUpContainer {
                              top: 30px;
                              left: 0;
                              overflow: auto;
                              width: 100%;
                              max-width: 100%;
                              z-index: 200 !important;
                              // min-width: 100%;
                              min-width: 250px;
                              max-height: 120px;
                              padding-bottom: 40px;
                              //   .popupGridAction {
                              //     bottom: 0;
                              //   }
                              .table {
                                .tbody {
                                  > .td {
                                    height: 30px;
                                    min-width: 120px;
                                  }
                                }
                              }
                            }
                          }
                          &:nth-last-child(1) {
                            .popUpContainer {
                              left: auto;
                              right: 5px;
                            }
                          }
                          &:nth-last-child(2) {
                            .popUpContainer {
                              left: -10px;
                            }
                          }
                        }

                        tr {
                          &:nth-last-child(1),
                          &:nth-last-child(2) {
                            td {
                              .popUpContainer {
                                top: initial;
                                bottom: 0;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .trow_time_tp_right {
                padding-right: 0px;
                padding-left: 0px;
                .tb-wrap {
                  overflow: auto;
                  min-height: 100%;
                  max-height: 100%;
                  height: 100%;
                  .plm_cmn_tbldiv {
                    height: 100%;
                    .table {
                      border-left-width: 1px;
                      border-left-style: solid;

                      .thead {
                        .th {
                          min-width: 40px;
                          padding: 8px 2px;
                        }
                      }
                      .tbody {
                        .td {
                          min-width: 40px;
                          padding: 4px 5px !important;
                          .form-section {
                            .form-control {
                              width: 30px;
                              padding: 5px 2px;
                              text-align: center;
                              height: 28px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .trow_time_bottom {
              > .row {
                align-items: center;
              }
              .totalRow {
                .tb-wrap {
                  max-height: initial;
                  min-height: initial !important;
                  .plm_cmn_tbldiv {
                    .table {
                      border-left-width: 1px;
                      border-left-style: solid;
                      border-right-width: 1px;
                      border-right-style: solid;
                      .thead {
                        .th {
                          min-width: 35px;
                          padding: 8px 2px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .grand_total_div {
            margin-top: 5px;
            > .row {
              align-items: center;
              .tb-wrap {
                max-height: initial;
                min-height: initial !important;
                .table {
                  border-left-width: 1px;
                  border-left-style: solid;
                  border-right-width: 1px;
                  border-right-style: solid;
                  .thead {
                    .th {
                      min-width: 35px;
                      padding: 8px 2px;
                    }
                  }
                }
              }
            }
          }
        }
        .total_expenses_content {
          height: 100%;
          .total_exp_coldiv {
            padding: 0px;
            height: 100%;
          }
          .tdivexps_top {
            padding: 0px;
            height: calc(100% - 24px);
            .react-datepicker-wrapper input {
              font-size: 11px;
            }
            .tb-wrap {
              overflow-y: auto;
              padding: 0;
              height: 100%;
              margin: 0;
              min-height: 100%;
              max-height: 100%;
              .plm_cmn_tbldiv {
                height: 100%;
                .table {
                  border-right-width: 1px;
                  border-right-style: solid;
                  .tbody {
                    > .td {
                      height: 30px;
                      min-width: 120px;
                      > div {
                        height: 100% !important;
                        display: flex;
                        align-items: center;
                        padding-left: 8px;
                        padding-right: 8px;
                        position: relative;
                        .form-section {
                          position: absolute;
                          .form-control {
                            border: 1px solid #ced4da;
                            height: 100%;
                          }
                        }
                        .popUpContainer {
                          top: 30px;
                          left: 0;
                          overflow: auto;
                          width: 100%;
                          max-width: 100%;
                          // min-width: 100%;
                          min-width: 250px;
                          max-width: 350px;
                          .table {
                            th {
                              &:nth-child(1) {
                                width: 30% !important;
                                max-width: 30% !important;
                              }
                              &:nth-child(2) {
                                width: 70% !important;
                                max-width: 70% !important;
                              }
                            }
                            .tbody {
                              > .td {
                                height: 30px;
                                min-width: 120px;
                                &:nth-child(1) {
                                  width: 30% !important;
                                  max-width: 30% !important;
                                }
                                &:nth-child(2) {
                                  width: 70% !important;
                                  max-width: 70% !important;
                                }
                              }
                            }
                          }
                        }
                      }
                      > .react-datepicker-wrapper {
                        height: 0px !important;
                      }
                      > .react-datepicker__tab-loop {
                        height: 0px !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .tdivexps_btm {
            .tdivexps_btm_inner {
              justify-content: flex-end;
              font-weight: 500;
              font-size: 1rem;
              display: flex;
            }
          }
        }
      }

      .plm_cmn_cardbtn_alone_div {
        margin-top: 0px !important;
        padding-top: 15px !important;
        margin-bottom: 0px !important;
        padding-bottom: 5px !important;
        margin-right: 0px;
        // position: sticky;
        position: fixed;
        padding-left: 15px !important;
        padding-right: 40px !important;
        bottom: 0px;
        z-index: 10;
        min-height: 38px;
        background: #ffffff;
      }
    }
  }
}

.totalRow .tb-wrap {
  min-height: 50px !important;
}

.popupGridAction {
  margin-top: 20px;
  margin-left: 15px;
}

.plm_tabs_typ1 .tab-content .tab-pane {
  min-height: 300px;
}

.plm_timeexpnse_main_div
  .plm_cmn_cardbox
  .card-body
  .plm_cmn_tabs
  .total_timeslot_content
  .plm_timrcoldiv
  .trow_time_top {
  min-height: 200px;
}

.clientPopupGrid {
  tr {
    td:nth-child(1) {
      min-width: 30px !important;
    }
    th,
    td {
      &:nth-child(2) {
        width: 75% !important;
      }
    }
  }
}

.weekSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none !important;
}

.App.PinkBlue {
  .total_expenses_content {
    table {
      tr {
        td {
          .react-datepicker {
            font-size: 0.7rem !important;
            .react-datepicker__day-name,
            .react-datepicker__day,
            .react-datepicker__time-name {
              width: 1.2rem;
              line-height: 1.2rem;
            }
            .react-datepicker__current-month,
            .react-datepicker-time__header,
            .react-datepicker-year-header {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
  .total_exp_coldiv {
    table {
      tr {
        td {
          &:nth-child(1) {
            padding: 4px 5px !important;
            min-width: 120px !important;
          }
          &:nth-child(6) {
            padding: 4px 5px !important;
          }
          .react-datepicker__input-container input {
            min-height: initial !important;
            height: 28px;
          }
          .form-control {
            height: 28px;
          }
        }
      }
    }
  }
  .cardBox {
    .popUpContainer {
      padding-bottom: 5px !important;
      max-height: 300px !important;
      min-width: 320px !important;
      max-width: 350px;
      .tb-wrap {
        max-height: 250px !important;
        overflow-y: auto !important;
        margin-bottom: 0 !important;
      }
      table.clientPopupGrid {
        table-layout: fixed;
        tr {
          th,
          td {
            &:nth-child(1) {
              width: 14%;
            }
          }
        }
      }
      .popupGridAction {
        margin-top: 20px !important;
        margin-left: 30px;
        display: inline-block;
        min-width: 100px;
        max-width: 110px;
        .btext {
          display: block !important;
        }
      }
      button {
        background: #a42998 !important;
        color: #ffffff !important;
        height: 40px;
      }
    }
  }
}
