.viewInvoices {
  .popUpContainer {
    display: block !important;
  }
  .cardBox .btn, .App.PinkBlue .cardBox button{
    min-height: 25px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }
}
