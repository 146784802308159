.plm_addedit_inv_div
{
    .plm_feditrow
    {
        align-items: center;
        label
        {
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 400;

        }
        .tblc_chklbl
        {
            margin-right: 15px;
        }
        .plm_cst_lbl
        {
            width: 200px;
            padding-left: 15px;
        }
        .form-section
        {
            margin-bottom: 0px;
        }
        .form-group
        {
            margin-bottom: 0px;
        }
        .form-control
        {
            margin-bottom: 0px;
        }
        &:not(:last-child)
        {
            margin-bottom: 13px;
        }
        .mform-inline
        {
           .form-group
           {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            .tblc_chklbl
            {
                width: 190px;
            }
            .form-section
            {
                width: 200px;
            }
           }
            
        }
        .plm_fedit_col
        {
            .form-section__field
            {
                > div
                {
                    display: flex;
                    align-items: center;
                    .tblc_chklbl
                    {
                        margin-right: 15px;
                        width: 190px;
                    }
                    
                }
            }
        }
    }
    
    
    
}
