@import "./variable";
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600;700&display=swap");

html,
body {
  height: 100%;
  font-family: "Fira Sans", sans-serif;
  line-height: 1.5;
  overflow: hidden;
}

button:focus,
button:active,
input[type="button"]:active,
input[type="submit"]:active {
  outline: 0;
  box-shadow: none !important;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner {
  border: 0;
  outline: 0;
}

body {
  #root {
    height: 100%;
    .App {
      height: 100%;
      // overflow: auto;
      // padding-bottom: 200px;
    }
  }
}

.customerMainContent {
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
  overflow: auto;
  height: 100%;
  top: 200px;
  position: relative;
}

.adminMainContent {
  display: inline-block;
  width: 100%;
  padding: 10px 10px 15px 10px;
  overflow: auto;
  height: 100%;
  top: 220px;
  position: relative;
}

.hrDivider {
  border-top: 2px solid;
  margin: 10px 0;
}
.listWrap {
  padding: 1rem;
  ul {
    padding-left: 0;
    li {
      list-style-type: none;
      padding: 0 10px;
    }
  }
}
.listWrap .selected {
  color: #ffffff;
}

.Dark {
  background: $sdBG2 !important;
  color: #ffffff;
  border-color: $sdBG2 !important;
}

// .Light{
//     background: $plm_thm_light_bgcolor !important;
//     .Light{
//         background: $plm_thm_light_basecolor !important;
//     color: #ffffff;
//     border-color: $plm_thm_light_basecolor !important;
//     }
// }
.blue {
  background: #e6f7ff !important;
  .blue {
    background: #00aaff !important;
    color: #ffffff;
    border-color: #00aaff !important;
  }
}
.green {
  background: #ccffcc !important;
  .green {
    background: #00cc00 !important;
    color: #ffffff;
    border-color: #00cc00 !important;
  }
}

.toastSuccessContainer {
  background: $sdBG3 !important;
  color: #ffffff;
  border-color: $sdBG3 !important;
}

.asterix {
  color: red;
}
.modal-header {
  background: #f2f2f2;
}

/*dark theme*/

.App.Dark {
  background: #000000 !important;
  .pHead {
    background: #2a2e31 !important;
    border-color: #323639 !important;
  }
}

@media only screen and (max-width: 600px) {
  html,
  body {
    overflow: hidden;
    .App {
      overflow: auto;
    }
  }
  .customerMainContent {
    width: calc(100% - 70px);
    margin-left: 70px;
  }
}
/*Light theme*/
.App.Light {
  background: $plm_thm_light_bgcolor;
  color: $plm_thm_light_fontcolor;
  .navigation {
    .sideBar {
      background: $plm_thm_light_menubgcolor;
      .inner_navmenu {
        > li {
          > a {
            color: $plm_thm_light_menulinkcolor;
          }
        }
      }
    }
  }
  .plm_tabs_typ1 {
    > .nav {
      > a {
        color: $plm_thm_light_tablnkcolor;
        background-color: $plm_thm_light_tablnkbgcolor;
        &:hover {
          color: $plm_thm_light_tablnk_hovfcscolor;
          background-color: $plm_thm_light_tablnk_actvbgcolor;
        }
        &:focus {
          color: $plm_thm_light_tablnk_hovfcscolor;
          background-color: $plm_thm_light_tablnk_actvbgcolor;
        }
      }
      a.active {
        color: $plm_thm_light_tablnk_hovfcscolor;
        background-color: $plm_thm_light_tablnk_actvbgcolor;
      }
    }
  }
  .plm_cmn_nocard,
  .plm_cmn_cardbtn_alone_div {
    .btn,
    .btn-disabled {
      background: $plm_thm_light_btnbgcolor !important;
      border-color: $plm_thm_light_btnbordercolor !important;
      color: $plm_thm_light_btncolor !important;
    }
    .btn {
      opacity: 1;
    }
    .btn-disabled {
      opacity: 0.5;
    }
  }

  .cardBox {
    background: $plm_thm_light_bgcolor !important;
    border-color: $plm_thm_light_bordercolor !important;
    .card-head {
      background: $plm_thm_light_cardheadbgcolor !important;
      border-color: $plm_thm_light_bordercolor !important;
      .card-title {
        color: $plm_thm_light_basecolor;
      }
    }
    .btn,
    .btn-disabled {
      background: $plm_thm_light_btnbgcolor !important;
      border-color: $plm_thm_light_btnbordercolor !important;
      color: $plm_thm_light_btncolor !important;
    }
    .btn {
      opacity: 1;
    }
    .btn-disabled {
      opacity: 0.5;
    }
    .hrDivider {
      border-color: $plm_thm_light_bordercolor !important;
    }
    .listWrap .selected {
      background: $plm_thm_light_lstacv_bgcolor !important;
      color: $plm_thm_light_lstacv_color;
    }
    .tb-wrap {
      table {
        th {
          background: $plm_thm_light_basecolor;
          color: $plm_thm_light_fontcolor !important;
        }
      }
    }
  }
}

/*green theame*/
.App.green {
  .cardBox {
    background: #ccffcc !important;
    border-color: #ccffcc !important;
    .card-head {
      background: #00cc00 !important;
      border-color: #00cc00 !important;
      .card-title {
        color: #ffffff;
      }
    }
    .btn,
    .btn-disabled {
      background: #00cc00 !important;
      border-color: #00cc00 !important;
    }
    .btn {
      opacity: 1;
    }
    .btn-disabled {
      opacity: 0.5;
    }
    .hrDivider {
      border-color: #00cc00 !important;
    }
    .listWrap .selected {
      background: #00cc00 !important;
    }
    .tb-wrap {
      table {
        th {
          background: #4dff4d;
          color: #000000 !important;
        }
      }
    }
  }
}

/*blue theame*/
.App.blue {
  .cardBox {
    background: #e6f9ff !important;
    border-color: #00aaff !important;
    .card-head {
      background: #00aaff !important;
      border-color: #00aaff !important;
      .card-title {
        color: #ffffff;
      }
    }
    .btn,
    .btn-disabled {
      background: #00aaff !important;
      border-color: #00aaff !important;
    }
    .btn {
      opacity: 1;
    }
    .btn-disabled {
      opacity: 0.5;
    }
    .hrDivider {
      border-color: #00aaff !important;
    }
    .listWrap .selected {
      background: #00aaff !important;
    }
    .tb-wrap {
      table {
        th {
          background: #80dfff;
          color: #000000 !important;
        }
      }
    }
  }
}

/*red theame*/
.App.red {
  .cardBox {
    background: #ffe6e6 !important;
    border-color: #ff0000 !important;
    .card-head {
      background: #ff0000 !important;
      border-color: #ff0000 !important;
      .card-title {
        color: #ffffff;
      }
    }
    .btn,
    .btn-disabled {
      background: #ff0000 !important;
      border-color: #ff0000 !important;
    }
    .btn {
      opacity: 1;
    }
    .btn-disabled {
      opacity: 0.5;
    }
    .hrDivider {
      border-color: #ff0000 !important;
    }
    .listWrap .selected {
      background: #ff0000 !important;
    }
    .tb-wrap {
      table {
        th {
          background: #ff4d4d;
          color: #000000 !important;
        }
      }
    }
  }
}

/*green theame*/
.App.green {
  .pHead {
    background: #00cc00 !important;
    color: #ffffff !important;
  }
  .modal-footer button.btn {
    background: #00cc00 !important;
  }
}

/*blue theame*/
.App.blue {
  .pHead {
    background: #00aaff !important;
    color: #ffffff !important;
  }
  .modal-footer button.btn {
    background: #00aaff !important;
  }
}

/*red theame*/
.App.red {
  .pHead {
    background: #ff0000 !important;
    color: #ffffff !important;
  }
  .modal-footer button.btn {
    background: #ff0000 !important;
  }
}

/*pinkblue theme*/
.App.PinkBlue {
  .navigation {
    .sideBar {
      margin-top: 0;
      padding: 2px 15px;
      .inner_navmenu {
        > li {
          > a {
            color: $plm_thm_light_menulinkcolor;
          }
        }
      }
    }
  }
  .customerMainContentapp_inner_wrap {
    //height: calc(100% - 150px);
    height: calc(100% - 215px);
  }
  .wkrowdiv {
    .weekBox {
      .btWrap {
        display: flex;
        align-items: center;
        color: #475f7b;
        span.PinkBlue.btn-blue {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #fafbfb;
        }
      }
      .selectWrap {
        text-align: center;
        max-width: 240px;
        color: #6093aa;
        font-weight: 600;
        span {
          font-size: 14px;
        }
        select {
          color: #6093aa;
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
  .plm_layout_right {
    padding-left: 5px;
    .prn_row {
      margin-right: -5px;
    }
    .col-md-6 {
      padding-left: 0px;
      padding-right: 5px;
      &:nth-child(1) {
        padding-right: 20px;
      }
    }
  }
  .plm_leftnav_wrap {
    padding-top: 0;
  }
}

.icon-pink {
  color: #a42998;
}

.td-pad-0 {
  padding: 0 0 !important;
}

.txtPrefix {
  position: absolute;
  top: 20%;
  padding-left: 2px;
  color: #6093aa;
}
