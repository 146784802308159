.App.Dark {
  .modal-content {
    color: #000000 !important;
  }
}

.popUpContainer {
  position: absolute;
  z-index: 100;
  background: #ffffff;
  padding-left: 0px !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-height: 300px;
  min-width: 250px;
  max-width: 96%;
  overflow-y: scroll;
  margin-top: 10px;
  
}

.modal-header {
  padding: 10px 1rem;
  .modal-title {
    font-size: 16px;
  }
}

.modal-body {
  padding: 5px 1rem;
  #orgLogo {
    padding: 3px 3px;
  }
}

.modal-footer {
  padding: 5px 1rem;
  button {
    font-size: 11px;
    background: #5c6bc0 !important;
    border: none !important;
  }
}

.largeModal {
  width: 99%;
  max-width: none !important;
}

.clearIc {
  position: absolute;
  top: 10px;
  left: 180px;
}


/*pinkblue theme*/
.modal-body{
  .card-body{
    .tbBtn.plm_cmn_cardbtn_alone_div{
      position: sticky !important;
    }
    .total_exp_coldiv{
      min-height: calc(100vh - 350px) !important;
    }
    .tdivexps_top{
      min-height:initial !important;
    }
    .grand_total_div{
      bottom: 0 !important;
      height: 50px;
    }
    .trow_time_bottom{
      height: 50px;
    }
    
  }
}
.modal-dialog{
  color: #74869b !important;
  font-family: Verdana,sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 530px !important;

  &.largeModal{
    max-width: initial !important;
    width: 99% !important;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    color: #74869b !important;
  }
  a.nav-link{
    color: #74869b !important;
  }
  .cardBox .card-body .nav-tabs a{
    color: #ffffff !important;
  }
  .col-12{
    .radio_ibox{
      display: flex;
      align-items: center;
      .radio_inner{
        margin-right: 10px;
        .tblc_chklbl{
          vertical-align: bottom;
          label{
            margin-right: 10px;
          }
        }
      }
    }
  }
  .modal-footer{
    button{
      background: #6dbf0d !important;
      color: #ffffff !important;
      padding: 3px 15px;
    }
  }
  
  .modal-body .react-datepicker-wrapper .react-datepicker__input-container input{
    border: 1px solid #999999;
  }
  
  .btn-P,.btn-M{
    background: #ffffff !important;
    color: #b7c2cb !important;
    width: 25px;
    height: 25px;
    display: flex;
    border: 1px solid #dddddd;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .btext{
      display: none;
    }
  }
  .mar-top-30{
    margin-top: 30px;
  }
  input[type="radio"], input[type="checkbox"]{
    width: 25px;
    height: 25px;
  }
  .form-section label{
    margin-bottom: 0.5rem;
  }
  .modal-footer{
    border-top: none;
    justify-content: center;
    padding: 2rem 0.75rem;
  }
  h4{
    font-size: 16px;
    font-weight: 600;
  }
  .form-control,label{
    color: #74869b !important;
    font-size: 14px;
  }
  .chWrap{
    display: flex;
    span{
      margin-left: 5px;
    }
  }
  
  .form-control{
    border: 1px solid #999999;
  }

  .PinkBlue.btn-primary{
    background: #6dbf0d !important;
    min-height: 25px;
    font-size: 12px;
  }

  .PinkBlue.btn-primary.btn-P,.PinkBlue.btn-primary.btn-M{
    background: #ffffff !important;
  }

  .btn.btn-secondary{
    background: #e2ecff !important;
    color: #5d8fee !important;
    min-height: 25px;
    font-size: 12px;
  }
  .modal-header{
    background: #ffffff !important;
    border: none !important;
    margin-bottom: 1rem;
    .modal-title{
      color: #647a90 !important;
      font-weight: 400;
      font-size: 14px;
    }

    .close{
      background: #999999 !important;
      width: 30px;
      font-weight: 100;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    position: relative;
    top: 20px;
    right: 20px;
    span{
      position: absolute;
      top: 2px;
    }
    }
  }
  .rGroup{
    max-width: 320px;
    margin: 0 auto;
    input{
      text-align: center;
    }
  }
}
