.react-datepicker-wrapper {
  max-width: 100%;
  width: 100%;
  .react-datepicker__input-container {
    input {
      border-radius: 0.25rem;
      max-width: 100%;
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}

.modal-body {
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      input {
        border-radius: 0.25rem;
        max-width: 100%;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .react-datepicker-wrapper {
    max-width: 100%;
    width: 100%;
    .react-datepicker__input-container {
      input {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.dateWrapper {
  display: flex;
  align-items: center;
  background: #f6f6f6 !important;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .dateTitle {
    width: 50%;
    border: none !important;
    padding-left: 5px;
  }
}
