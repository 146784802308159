.nonsubm_rowbox
{
    .plm_cltrepo_top
    {
        .plm_cltrepo_top_inner
        {
            padding: 0 15px;
            .tblc_chklbl
            {
                display: block;
            }
        }
    }
    .plm_cltrepo_btm
    {
        height: calc(100% - 200px) !important;
        overflow: auto;
        position: relative;
        .plm_cltrepo_btm_inrcontent
        {           
            position: absolute;
            width: 100%;
            top: 0;
        .plm_ts_sheet_row
        {
            display: flex;
            padding: 0 15px;
            p:not(:last-child)
            {
                margin-bottom: 5px;
            }
            .plm_ts_sheet_left
            {
                margin-right: 5px;
            }
        }
        .plm_clt_reprt_tbldiv
        {         
           
           .tb-wrap
                {
                    overflow-y: auto;
                    padding: 0;
                    height: 100%;
                    margin: 0;
                    min-height: 100%;
                    max-height: 100%;
                    .plm_cmn_tbldiv
                    {
                        .table
                        {
                            border-right-width: 1px;
                            border-right-style: solid;
                            border-left-width: 1px;
                            border-left-style: solid;
                        }
                        
                    }
                }
        }
    }
    }
}