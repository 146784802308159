.orderedList{
    list-style: none;
    padding-left: 30px;
    .plusIcWrapper{
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        // left: 7px;
        // top: 11px;
        left: 0px;
        top: 0px;
        // border: #2d3033 solid 1px;
        border: 1px solid #dee2e6;
        background: #fff;
    }
    .plusIc{
        // top: -4px;
        // left: 4px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: absolute;
    }
}

