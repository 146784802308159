.prm_customer_list_div {
  height: 100%;
  .cardBox {
    height: 100%;
    margin: 0px;
    border-radius: 5px;
    .card-head {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .card-body {
      height: calc(100% - 48px);
      padding: 0px;
      border: none;
      border-radius: 0px;

      .tb-wrap {
        overflow-y: auto;
        padding: 0;
        height: 100%;
        margin: 0;
        min-height: 100%;
        max-height: 100%;
        .plm_cmn_tbldiv {
          height: 100%;
        }
      }
    }
  }
}
.headerSearch {
  position: absolute;
  top: 5px;
  right: 15px;
  .searchIc {
    position: absolute;
    z-index: 20;
    top: 5px;
    left: 10px;
    color: gray;
  }
  input {
    text-indent: 15px;
  }
}
