.timesheet_report_rowbox
{
    .plm_tsrept_top
    {
        height: calc(100% - 190px);
        .plm_cltrepo_top
    {
        .plm_rp_partner_col
        {
            padding: 0 15px;
        }
    }
    }
    
    .plm_rp_tsrepgrid_col
    {
       // height: calc(100% - 210px) !important;
        height: initial !important;
        position: relative;
        margin-top: 0px !important;
        .plm_rp_tsreport_idiv 
        {
            overflow-y: auto;
    position: absolute;
    width: 100%;
    top: 15px;
        }
    
    }
    .plm_rp_tsreport_idiv 
    {
        .tb-wrap
        {
           
            min-height: initial;
            max-height: initial;
           height: 100%;
           .table
           {
            border-right-width: 1px;
            border-right-style: solid;
            border-left-width: 1px;
            border-left-style: solid;
           }
        }
        .total_expenses_content
        {
            .tb-wrap
            {
                margin: 0;
                
            }
            .totalRow
            {
                padding: 0;
            }
            .total_exp_coldiv
            {
                .tdivexps_btm
                {
                   .tdivexps_btm_inner
                   {
                       text-align: right;
                   } 
                }
            }
        }
        .total_timeslot_content
        {
            .trow_time_bottom
               {
                .totalRow
                {
                   .tb-wrap
                   {
                       margin: 0 !important;
                      
                   }
                } 
                h5
                {
                    font-size: 1rem;
                }
               } 
            .grand_total_div
            {
                .totalRow
                {
                    padding:0 15px !important;
                    .tb-wrap
                    {
                        min-height: initial !important;
                        
                    }
                }
                h5
                {
                    font-size: 1rem;
                }
            }
        }
        

    }
}

