.container {
    /* background-image: url('/assests/img/Login_bg.jpg'); */
    
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #6093AA; */
    background: rgba(0,0,0,.5);
    display: flex; 
    justify-content: center; 
     align-items: center; 
     height: 100%;
     font-family: Verdana,sans-serif;
}



.loginWrapper {
    text-align: center;
    /* border: 1px solid black; */
    border-radius: 8px;
    padding: 20px 30px;
    width: 300px;
    /* min-width: 400px; */
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    background-color: white;
    /* box-sizing: border-box; */
    color: #6093AA;
    
}

.loginWrapper input, .loginWrapper input::placeholder, .loginWrapper input:focus{
    color: #6093AA !important;
}

.loginHeading {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
}



