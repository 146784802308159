.weekBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .selectWrap{
        width: calc(100% - 80px);
        .form-group{
            margin-bottom: 0;
            .form-section{
                margin-bottom: 0;
            }
        }
    }
    .btWrap{
        height: 100%;
        .btn{
           height: 100%;
        }
    }
}


/* ------------------------ Light theme Code Starts Here -------------------- */
.App.Light
{   background: $plm_thm_light_bgcolor;
    color:$plm_thm_light_fontcolor;
    a
    {
        color:$plm_thm_light_alink;
    }
    .form-control
    {
        background-color: $plm_thm_light_frmctrlbgcolor;
        border-color:  $plm_thm_light_frmctrlbordercolor;
        color:  $plm_thm_light_frmctrlcolor;
        box-shadow: none;
        &:focus {
            background-color: $plm_thm_light_frmctrlbgcolor_focus;
            border-color:  $plm_thm_light_frmctrlbordercolor_focus;
            color:  $plm_thm_light_frmctrlcolor;
        }
    }

    .react-datepicker-wrapper
    {
        .react-datepicker__input-container
        {
            input
            {
                background-color: $plm_thm_light_frmctrlbgcolor;
                border-color:  $plm_thm_light_frmctrlbordercolor;
                color:  $plm_thm_light_frmctrlcolor;
                box-shadow: none;
                &:focus {
                    background-color: $plm_thm_light_frmctrlbgcolor_focus;
                    border-color:  $plm_thm_light_frmctrlbordercolor_focus;
                    color:  $plm_thm_light_frmctrlcolor;
                }
            }
        }
    }

    .table, .plm_cmn_tbldiv
    {
        background-color: $plm_thm_light_tablebgcolor;
        border-color:$plm_thm_light_tablebordercolor;
        .thead
        {
             tr
            {
                 .th
                {
                    color:$plm_thm_light_table_thcolor !important;
                    border-color:$plm_thm_light_tablebordercolor !important;
                    background: transparent !important;
                }
            }
        }
        .tbody
        {
             tr
            {
                 .td
                {
                    color:$plm_thm_light_table_tdcolor !important;
                    border-color:$plm_thm_light_tablebordercolor !important;
                    background: transparent !important;
                }
            }
        }
    }

    header
    {
        background: $plm_thm_light_menubgcolor;
        .notify
        {
            svg
            {
                fill:$plm_thm_light_svgicon;
            }
        }
         .logout
        {
            svg
            {
                .st0 
                {
                    fill:$plm_thm_light_svgicon;
                }
            }
        }

    }
    .navigation
    {   
        .sideBar
        {
            background: $plm_thm_light_menubgcolor;
            .inner_navmenu
            {
                >li
                {
                    > a
                    {
                        color:$plm_thm_light_menulinkcolor;
                        &:hover {
                            color:$plm_thm_light_menulinkhoverfocuscolor;
                        }
                        &:focus {
                            color:$plm_thm_light_menulinkhoverfocuscolor;
                        }
                    }
                    .subMenuList
                    {
                        background: $plm_thm_light_submenubgcolor;                       
                        border-color:$plm_thm_light_bordercolor;
                        > li
                        {
                            > a
                            {
                                color:$plm_thm_light_submenulinkcolor;
                                &:hover {
                                    color:$plm_thm_light_submenulinkhoverfocus;
                                }
                                &:focus {
                                    color:$plm_thm_light_submenulinkhoverfocus;
                                }
                            }
                            > a.active
                            {
                                color:$plm_thm_light_basecolor;
                                &:hover {
                                    color:$plm_thm_light_basecolor;
                                }
                                &:focus {
                                    color:$plm_thm_light_basecolor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .plm_leftnav_wrap
    {
        background-color: $plm_thm_light_lnavbgcolor;
        .rpt_title
        {
            color:$plm_thm_light_fontcolor;
        }
        .left_reportmenu
        {
            >li
            {
                > a
                {
                    color:$plm_thm_light_basecolor;
                    &:hover
                    {
                        color:$plm_thm_light_fontcolor;
                    }
                    &:focus
                    {
                        color:$plm_thm_light_fontcolor;
                    }
                }
            }
        }
    }
    .pHead
    {
        background: $plm_thm_light_pageheadcolor;
        border-color:$plm_thm_light_bordercolor;
        color: $plm_thm_light_basecolor;
    }
    .plm_tabs_typ1
    {
        > .nav 
        {
            > a
            {
                color:$plm_thm_light_tablnkcolor;
                background-color: $plm_thm_light_tablnkbgcolor;
                &:hover {
                    color:$plm_thm_light_tablnk_hovfcscolor;
                background-color: $plm_thm_light_tablnk_actvbgcolor;
                }
                &:focus {
                    color:$plm_thm_light_tablnk_hovfcscolor;
                    background-color: $plm_thm_light_tablnk_actvbgcolor;
                }   
            }
            a.active
            {
                color:$plm_thm_light_tablnk_hovfcscolor;
                background-color: $plm_thm_light_tablnk_actvbgcolor;
            }
        }
    }
    .plm_cmn_nocard, .plm_cmn_cardbtn_alone_div
    {
        .btn,.btn-disabled{
            background: $plm_thm_light_btnbgcolor !important;
            border-color: $plm_thm_light_btnbordercolor !important;
            color:$plm_thm_light_btncolor !important;
        }
        .btn{
            opacity: 1;
        }
        .btn-disabled{
            opacity: 0.5;
        }
    }
    .plm_partboxcmn
    {
        border-color: $plm_thm_light_bordercolor !important;
    }
    .cardBox{
        background: $plm_thm_light_bgcolor !important;
        border-color: $plm_thm_light_bordercolor !important;
        .card-head{
            background: $plm_thm_light_cardheadbgcolor !important;
            border-color: $plm_thm_light_bordercolor !important;
            .card-title{
                color:$plm_thm_light_basecolor;
            }
        }
        .btn,.btn-disabled{
            background: $plm_thm_light_btnbgcolor !important;
            border-color: $plm_thm_light_btnbordercolor !important;
            color:$plm_thm_light_btncolor !important;
        }
        .btn{
            opacity: 1;
        }
        .btn-disabled{
            opacity: 0.5;
        }
        .hrDivider{
            border-color:$plm_thm_light_bordercolor !important;
        }
        .listWrap .selected{
            background: $plm_thm_light_lstacv_bgcolor !important;
            color:$plm_thm_light_lstacv_color;
        }
        .tb-wrap{
            table{
                th{
                    color:$plm_thm_light_table_thcolor ;
                    border-color:$plm_thm_light_tablebordercolor !important;
                    background: transparent;
                }
                td
                {
                    color:$plm_thm_light_table_tdcolor ;
                    border-color:$plm_thm_light_tablebordercolor !important;
                    background: transparent;
                }                
                
            }
        }
    }
}

/* ------------------------ Light theme Code Ends Here ------------------------ */

/* ------------------------ Dark-Yellow theme Code Starts Here ------------------------ */
.App.Darkyl
{   background: $plm_thm_darkyl_bgcolor;
    color:$plm_thm_darkyl_fontcolor;
    a
    {
        color:$plm_thm_darkyl_alink;
    }
    .form-control
    {
        background-color: $plm_thm_darkyl_frmctrlbgcolor;
        border-color:  $plm_thm_darkyl_frmctrlbordercolor;
        color:  $plm_thm_darkyl_frmctrlcolor;
        box-shadow: none;
        &:focus {
            background-color: $plm_thm_darkyl_frmctrlbgcolor_focus;
            border-color:  $plm_thm_darkyl_frmctrlbordercolor_focus;
            color:  $plm_thm_darkyl_frmctrlcolor;
        }
    }

    .react-datepicker-wrapper
    {
        .react-datepicker__input-container
        {
            input
            {
                background-color: $plm_thm_darkyl_frmctrlbgcolor;
                border-color:  $plm_thm_darkyl_frmctrlbordercolor;
                color:  $plm_thm_darkyl_frmctrlcolor;
                box-shadow: none;
                &:focus {
                    background-color: $plm_thm_darkyl_frmctrlbgcolor_focus;
                    border-color:  $plm_thm_darkyl_frmctrlbordercolor_focus;
                    color:  $plm_thm_darkyl_frmctrlcolor;
                }
            }
        }
    }

    .table, .plm_cmn_tbldiv
    {
        background-color: $plm_thm_darkyl_tablebgcolor;
        border-color:$plm_thm_darkyl_tablebordercolor;
        .thead
        {
             tr
            {
                 .th
                {
                    color:$plm_thm_darkyl_table_thcolor !important;
                    border-color:$plm_thm_darkyl_tablebordercolor !important;
                    background: transparent !important;
                }
            }
        }
        .tbody
        {
             tr
            {
                 .td
                {
                    color:$plm_thm_darkyl_table_tdcolor !important;
                    border-color:$plm_thm_darkyl_tablebordercolor !important;
                    background: transparent !important;
                }
            }
        }
    }

    header
    {
        background: $plm_thm_darkyl_menubgcolor;
        .logo_wrap {
            .logo {
                a {
                    background-color: $plm_thm_darkyl_logobg;
                }
            }
        }
        .notify
        {
            svg
            {
                fill:$plm_thm_darkyl_svgicon;
            }
        }
         .logout
        {
            svg
            {
                .st0 
                {
                    fill:$plm_thm_darkyl_svgicon;
                }
            }
        }

    }
    .navigation
    {   
        .sideBar
        {
            background: $plm_thm_darkyl_menubgcolor;
            .inner_navmenu
            {
                >li
                {
                    > a
                    {
                        color:$plm_thm_darkyl_menulinkcolor;
                        &:hover {
                            color:$plm_thm_darkyl_menulinkhoverfocuscolor;
                        }
                        &:focus {
                            color:$plm_thm_darkyl_menulinkhoverfocuscolor;
                        }
                    }
                    .subMenuList
                    {
                        background: $plm_thm_darkyl_menubgcolor;                       
                        border-color:$plm_thm_darkyl_bordercolor;
                        > li
                        {
                            > a
                            {
                                color:$plm_thm_darkyl_menulinkcolor;
                                &:hover {
                                    color:$plm_thm_darkyl_basecolor;
                                }
                                &:focus {
                                    color:$plm_thm_darkyl_basecolor;
                                }
                            }
                            > a.active
                            {
                                color:$plm_thm_darkyl_basecolor;
                                &:hover {
                                    color:$plm_thm_darkyl_basecolor;
                                }
                                &:focus {
                                    color:$plm_thm_darkyl_basecolor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .plm_leftnav_wrap
    {        
        border:1px solid $plm_thm_darkyl_bordercolor;
        .rpt_title
        {
            color:$plm_thm_darkyl_basecolor;
        }
        .left_reportmenu
        {
            >li
            {
                > a
                {
                    color:$plm_thm_darkyl_menulinkcolor;
                    &:hover
                    {
                        color:$plm_thm_darkyl_basecolor;
                    }
                    &:focus
                    {
                        color:$plm_thm_darkyl_basecolor;
                    }
                }
            }
        }
    }
    .pHead
    {
        background: $plm_thm_darkyl_pageheadcolor;
        border-color:$plm_thm_darkyl_bordercolor;
        color: $plm_thm_darkyl_basecolor;
    }
    .plm_tabs_typ1
    {
        > .nav 
        {
            > a
            {
                color:$plm_thm_darkyl_tablnkcolor;
                background-color: $plm_thm_darkyl_tablnkbgcolor;
                &:hover {
                    color:$plm_thm_darkyl_tablnk_hovfcscolor;
                background-color: $plm_thm_darkyl_tablnk_actvbgcolor;
                }
                &:focus {
                    color:$plm_thm_darkyl_tablnk_hovfcscolor;
                    background-color: $plm_thm_darkyl_tablnk_actvbgcolor;
                }   
            }
            a.active
            {
                color:$plm_thm_darkyl_tablnk_hovfcscolor;
                background-color: $plm_thm_darkyl_tablnk_actvbgcolor;
            }
        }
    }
    .plm_cmn_nocard, .plm_cmn_cardbtn_alone_div
    {
        .btn,.btn-disabled{
            background: $plm_thm_darkyl_btnbgcolor !important;
            border-color: $plm_thm_darkyl_btnbordercolor !important;
            color:$plm_thm_darkyl_btncolor !important;
        }
        .btn{
            opacity: 1;
        }
        .btn-disabled{
            opacity: 0.5;
        }
    }
    .plm_partboxcmn
    {
        border-color: $plm_thm_darkyl_bordercolor !important;
    }
    .cardBox{
        background: $plm_thm_darkyl_bgcolor !important;
        border-color: $plm_thm_darkyl_bordercolor !important;
        .card-head{
            background: $plm_thm_darkyl_cardheadbgcolor !important;
            border-color: $plm_thm_darkyl_bordercolor !important;
            .card-title{
                color:$plm_thm_darkyl_basecolor;
            }
        }
        .btn,.btn-disabled{
            background: $plm_thm_darkyl_btnbgcolor !important;
            border-color: $plm_thm_darkyl_btnbordercolor !important;
            color:$plm_thm_darkyl_btncolor !important;
        }
        .btn{
            opacity: 1;
        }
        .btn-disabled{
            opacity: 0.5;
        }
        .hrDivider{
            border-color:$plm_thm_darkyl_bordercolor !important;
        }
        .listWrap .selected{
            background: $plm_thm_darkyl_lstacv_bgcolor !important;
            color:$plm_thm_darkyl_lstacv_color;
        }
        .tb-wrap{
            table{
                th{
                    color:$plm_thm_darkyl_table_thcolor ;
                    border-color:$plm_thm_darkyl_tablebordercolor !important;
                    background: transparent;
                }
                td
                {
                    color:$plm_thm_darkyl_table_tdcolor ;
                    border-color:$plm_thm_darkyl_tablebordercolor !important;
                    background: transparent;
                }                
                
            }
        }
    }
    }

    /* ------------------------ Dark-Yellow theme Code Ends Here ------------------------ */