.plm_creat_inv_div {
  height: 100%;
  .plm_crt_wizdiv {
    height: 100%;
    .plm_cmn_wiz_step {
      height: calc(100% - 10px);
      margin-top: 10px;
      .plm_cmn_cardbox {
        height: calc(100% - 76px);
        .plm_cmn_wiz_content {
          height: 100%;
          overflow: auto;
        }
        .plm_inv_wiz_step1 {
          select {
            background: #eeecec;
            border: none;
            max-width: 200px;
          }
          .plm_frm_radiobox {
            margin-top: 15px;
            .radio_ibox {
              display: flex;
            }
          }
          .cst_tbox_popmdiv {
            .plm_cstpopupbox_wrap {
              .form-section {
                margin-bottom: 0px;
                .form-control {
                  height: 30px;
                }
              }
              .popUpContainer {
                margin-top: 0px;
                overflow: auto;
                .tb-wrap {
                  overflow-y: auto;
                  padding: 0;
                  height: 100%;
                  margin: 0;
                  min-height: 100%;
                  max-height: 100%;
                  .plm_cmn_tbldiv {
                    height: 100%;
                  }
                  .table {
                    border-right-width: 1px;
                    border-right-style: solid;
                    .tbody {
                      > .td {
                        height: 30px;
                        min-width: 120px;
                        > div {
                          height: 100% !important;
                          display: flex;
                          align-items: center;
                          padding-left: 8px;
                          padding-right: 8px;
                          position: relative;
                          .form-section {
                            .form-control {
                              border: 1px solid #ced4da;
                            }
                          }
                          .popUpContainer {
                            top: 30px;
                            left: 0;
                            overflow: auto;
                            width: 100%;
                            max-width: 100%;
                            // min-width: 100%;
                            min-width: 250px;
                            .table {
                              .tbody {
                                > .td {
                                  height: 30px;
                                  min-width: 70px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .plm_inv_wiz_step2 {
          position: relative;
          .plm_operiv {
            margin-bottom: 10px;
            position: relative;
            .finl_ctrldiv {
              .form-group {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
            .plm_floatbtn_div {
              position: absolute;
              right: 15px;
              bottom: 0;
            }
            .tb-wrap {
              height: calc(100% - 48px);
              min-height: initial;
              max-height: initial;
            }
          }
          .tb-wrap {
            .table {
              .tbody {
                > .td {
                  min-width: 90px;
                  padding-left: 8px;
                  padding-right: 8px;
                  > div {
                    padding-left: 5px;
                    padding-right: 5px;
                  }
                  &:last-child {
                    text-align: center;
                    .form-section {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .plm_cmn_cardbtn_alone_div {
        margin: 5px 0;
      }
    }
    .plm_invwiz_stepw2 {
      > .plm_cmn_cardbox {
        height: calc(100% - 96px);
        .tb-wrap {
          height: calc(100% - 48px) !important;
          min-height: initial !important;
          max-height: initial !important;
        }
      }
      .headerCheckbox {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .plm_cmn_cardbox {
    height: 100%;
    margin: 0px;
    border-radius: 5px;
    position: relative;
    .card-head {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .card-body {
      height: calc(100% - 48px);
      padding: 0px;
      border: none;
      border-radius: 0px;
      .plm_cmn_wiz_content {
        padding: 0 15px;
        .tb-wrap {
          overflow-y: auto;
          padding: 0;
          height: 100%;
          margin: 0;
          min-height: 100%;
          max-height: 100%;
          .plm_cmn_tbldiv {
            height: 100%;
          }
          .table {
            border-left-width: 1px;
            border-left-style: solid;
            border-right-width: 1px;
            border-right-style: solid;
            .tbody {
              > .td {
                height: 30px;
                min-width: 120px;
                > div {
                  height: 100% !important;
                  display: flex;
                  align-items: center;
                  padding-left: 8px;
                  padding-right: 8px;
                  position: relative;
                  .form-section {
                    .form-control {
                      border: 1px solid #ced4da;
                    }
                  }
                  .popUpContainer {
                    top: 30px;
                    left: 0;
                    overflow: auto;
                    width: 100%;
                    max-width: 100%;
                    // min-width: 100%;
                    min-width: 250px;
                    .table {
                      .tbody {
                        > .td {
                          height: 30px;
                          min-width: 70px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.App.PinkBlue
  .cardBox
  .card-body
  .plm_inv_wiz_step4
  table
  tbody
  tr:nth-of-type(even)
  td:nth-of-type(2) {
  border: none !important;
}
.App.PinkBlue .cardBox .card-body .plm_inv_wiz_step4 table td,
.App.PinkBlue .cardBox .card-body .plm_inv_wiz_step4 table th {
  &:nth-child(2) {
    padding: 5px 5px !important;
  }
}

.App.PinkBlue
  .cardBox
  .card-body
  .plm_inv_wiz_step2
  table
  tbody
  tr:nth-of-type(even)
  td {
  padding: 3px 5px !important;
}
.App.PinkBlue
  .cardBox
  .card-body
  .plm_inv_wiz_step4
  table
  tbody
  tr:nth-of-type(even)
  td {
  padding: 5px 5px !important;
}

.App.PinkBlue .cardBox .card-body .plm_inv_wiz_step4 table tbody tr td {
  &:nth-child(1) {
    width: 60%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  .popUpContainer{
    table{
      tr{
        td, th{
          &:nth-child(1){
            width: 10% !important;
          }
          &:nth-child(2){
            width: 60% !important;
          }
        }
      }
    }
  }
}

// .App.PinkBlue .cardBox .card-body .plm_inv_wiz_step2 table tbody tr td {
//   height: 38px !important;
// }

.App.PinkBlue {
  .plm_creat_inv_div
    .plm_cmn_cardbox
    .card-body
    .plm_cmn_wiz_content
    .tb-wrap
    .table
    .tbody
    > .td {
    height: 36px !important;
  }
}

.PinkBlue {
  .viewInvoices {
    button {
      min-height: 25px;
      line-height: 22px;
    }
  }
  .tdivexps_top {
    min-height: calc(100vh - 375px);
    table{
      tr{
        td{
          &:nth-child(5){
            .form-section{
              width: 98%;
            }
          }
        }
      }
    }
  }
  .plm_prview_mid {
    margin-bottom: 30px;
    .prn_row {
      margin-left: 0;
      .prn_colh {
        padding-right: 0;
      }
    }
  }

  .plm_invwiz_stepw2,
  .plm_invwiz_stepw3,
  .plm_invwiz_stepw4 {
    .cardBox {
      .card-head {
        // margin-top: 1px !important;
      }
    }
  }

  .btn.btn-primary {
    white-space: nowrap;
  }
  .form-control {
    border-radius: 0 !important;
  }
  .text-value-bg {
    background: #eeecec;
    padding: 0px 5px;
  }
  .plm_inv_wiz_step3 {
    .tblc_chklbl {
      label.control-label {
        color: #475F7B !important;
        font-size: 13px !important;
        font-weight: 600;
      }
    }
    .react-datepicker__input-container,
    .invoice-lbl {
      input {
        font-size: 13px !important;
      }
    }
  }
  .strong-text {
    color: #475F7B !important;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 25px !important;
  }
  .plm_invwiz_stepw2 {
    .norLbl {
      font-size: 13px !important;
      line-height: 30px;
    }
  }
  .plm_inv_wiz_step1 {
    .react-datepicker__input-container {
      input {
        font-size: 13px !important;
      }
    }
    .tblc_chklbl {
      padding: 6px 0;
      label.control-label {
        color: #475F7B !important;
        font-size: 13px !important;
      }
    }
    .form-control {
      .plm_cstpopupbox_wrap {
        font-size: 13px !important;
        line-height: 30px;
      }
    }

    .pad-wrap-0 {
      .col-6 {
        padding-left: 0 !important;
        padding-right: 15px !important;
      }
    }
    .invoice-btn-left {
      .invoiceBtn {
        position: fixed;
        // width: 82.66%;
        // right: 30px;
        left: 245px;
        width: calc(100% - 260px) !important;
        padding: 0 15px;
        padding-right: 15px;
      }
    }
  }
  .plm_invwiz_stepw4 {
    .invoiceBtn {
      position: fixed !important;
      bottom: 0;
      // right: 30px !important;
      // width: 82.11% !important;
      left: 245px;
      width: calc(100% - 260px) !important;
      padding: 0 15px !important;
      padding-right: 15px !important;
      
      button{
        width: calc(8.33% - 22px);
      }
    }
  }
  .plm_inv_wiz_step4 {
    padding: 0 !important;
    .plm_cmn_tbldiv {
      table {
        tr {
          &:nth-of-type(even) {
            td {
              border: none !important;
            }
          }
          td {
            padding: 0 !important;
            &:nth-child(2) {
              background: transparent !important;
              border: none !important;
              button {
                height: 34px;
                padding-left: 30px;
                padding-right: 30px;
                border-radius: 0;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
  .plm_inv_wiz_step2 {
    padding: 0 !important;
  }
  .plm_invwiz_stepw2 {
    .plm_cmn_cardbox {
      min-height: initial !important;
    }
    .invoiceBtn button{
      width: calc(50% - 11px);
    }
  }
  .invoice-btn-bottom {
    // position: fixed;
    // bottom: 0;
    // width: 85.50%;
    // background: #ffffff !important;
    // z-index: 10;
    // right: 30px;

    position: fixed;
    bottom: 0px;

    left: 280px;
    width: calc(100% - 280px);
    background: #ffffff !important;
    z-index: 10;
    right: 0;
    padding: 0 15px;
    padding-left: 20px;
  }
  .invoice-lbl {
    label {
      white-space: nowrap;
    }
  }
  .value-bg {
    input,
    select,
    .l-value-wrap {
      background: #eeecec !important;
      border: none !important;
      max-width: 160px;
      min-width: 160px;
      min-height: 36px;
      display: flex;
      align-items: center;
    }
  }
  .invoice-detail-top {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    & > .col-2.pl-0{
      max-width: 200px !important;
    }
    .invoice-lbl {
      max-width: initial !important;
    }
    input {
      background: #eeecec !important;
      border: none !important;
    }
  }
  .plm_prview_list {
    ul {
      padding-left: 0 !important;
      list-style-type: none;
      margin-top: 15px;
      li {
        color: #475F7B !important;
        font-size: 13px !important;
        padding: 5px 5px !important;
        padding-left: 15px !important;
        &:nth-of-type(even) {
          background: #EEEEEE !important;
        }
      }
    }
  }
  .trans-detail-tbl {
    table {
      tr {
        td,
        th {
          &:nth-child(1) {
            width: 15% !important;
          }
          &:nth-child(2) {
            width: 40% !important;
          }
          &:nth-child(3) {
            width: 15% !important;
          }
          &:nth-child(4) {
            width: 10% !important;
          }
          &:nth-child(5) {
            width: 10% !important;
          }
          &:nth-child(6) {
            width: 10% !important;
          }
        }
      }
    }
  }
  .invoice-lbl {
    max-width: 170px;
    .col-12 {
      padding: 0;
    }
    .label-wraper {
      display: block !important;
      label,
      span {
        display: block;
        width: 100%;
        font-size: 13px !important;
      }
      span.mx-1 {
        display: none;
      }
      span.l-value-wrap {
        background: #eeecec;
        padding: 5px 5px;
        min-height: 36px;
        span {
          min-height: 26px;
          line-height: 26px;
        }
      }
    }
  }
  .invoice-preview {
    .tblc_chklbl {
      padding-right: 0 !important;
      margin-right: 25px !important;
    }
    .form-control {
      height: 36px !important;
    }
    .react-datepicker-wrapper .react-datepicker__input-container input {
      border-radius: 0 !important;
    }
    .transparentLabel.value-bg{
      span{
        &.tblc_chklbl{
          ~ span{
            .txtPrefix{
              padding-left: 15px;
            } 
              #actualAmt{
                padding-left: 25px;
              }
               
          }
        }
      }
    }
    input {
      font-size: 14px !important;
    }
    .label-wraper {
      padding: 0 !important;
      label {
        text-align: right;
        font-size: 13px !important;
        padding-right: 10px !important;
        min-width: 200px;
        margin-right: 15px;
        min-height: 36px;
      }
      span.mx-1 {
        display: none;
      }
      .l-value-wrap {
        font-size: 14px !important;
        padding-left: 10px;
        min-height: 36px;
      }
    }
    .tblc_chklbl {
      text-align: right;
      padding-right: 10px;
    }
  }
  .btn-Input-Container {
    display: flex;
    max-width: 160px;
    align-items: center;
    .btn-input-child {
      flex: 1 50%;
      height: 40px;
      display: flex;
      align-items: center;
      .form-section {
        margin-bottom: 0;
      }
      input {
        height: 40px !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        min-height: initial !important;
      }
      button {
        width: 100%;
        height: 40px !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-radius: 0;
      }
    }
  }
  .plm_invwiz_stepw3,
  .plm_invwiz_stepw5 {
    .invoiceBtn {
      position: fixed !important;
      bottom: 0;
      // width: 83% !important;
      // left: 15% !important;
      left: 245px;
      width: calc(100% - 260px) !important;
      padding: 0 15px;
      padding-right: 15px;
      
      button{
        width: calc(8.33% - 20px);
      }
    }
  }
  .plm_invwiz_stepw3{
    .invoiceBtn{
      padding-right: 15px;
    }
  }
  .text-right-strong {
    text-align: right;
    padding-right: 10px;
    font-weight: 900;
    font-size: 14px !important;
  }
  .trans-detail-bottom-row {
    table {
      tr {
        td {
          font-family: Verdana, sans-serif;

          .text-value-bg {
            font-family: Verdana, sans-serif;
            padding: 1px 5px !important;
          }
          &:nth-child(7) {
            background: #eeecec !important;
          }
          &:nth-child(9) {
            background: #eeecec !important;
          }
        }
      }
    }
  }
  .text-blue {
    .l-value-wrap {
      color: #00ccff !important;
    }
  }
  .text-red {
    .l-value-wrap {
      color: #ff0000 !important;
    }
  }
  .plm_creat_inv_div {
    .client-lbl {
      label {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        font-size: 13px !important;
        min-width: initial !important;
        width: auto !important;
      }
      .l-value-wrap {
        font-size: 13px !important;
      }
      .label-wraper {
        min-height: 40px !important;
        align-items: center !important;
        span {
          display: flex;
          align-items: center;
        }
      }
    }
    .invoiceBtn {
      
      &.tbBtn{
        margin-bottom: 0 !important;
        padding-bottom: 5px !important;
        margin-top: -5px !important;
        padding-top: 5px !important;
      }
      float: right;
      flex-direction: row-reverse;
      justify-content: flex-start !important;
      button {
        margin-left: 0;
        margin-right: 0;
        height: 40px;
        border-radius: 0;
        font-size: 16px !important;
        
        &:nth-child(2) {
          border: 1px solid #eeecec;
          background: #ffffff;
          color: #A42998;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:nth-child(1) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .norLbl {
      background: #eeecec;
      height: 36px;
      line-height: 36px;
    }
    .norInput {
      input {
        height: 26px;
        font-size: 13px;
        text-align: center;
      }
    }
    .cardBox .card-body .plm_feditrow .transparentLabel {
      .label-wraper,
      label {
        padding-left: 0px !important;
        label {
          width: 20% !important;
        }
      }
    }
    .transparentLabel,
    .cardBox .card-body .transparentLabel {
      .label-wraper label,
      label {
        color: #475F7B !important;
        background: transparent !important;
        font-weight: 600;
        padding: 0px;
      }
      .emp_topvaldiv .label-wraper {
        color: #6093AA !important;
        background: transparent !important;
      }
    }
  }
}
