.tbBtn {
  display: flex;
  justify-content: flex-end;
}

.tbBtn button {
  margin-left: 10px;
}

.btnP {
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
}

.roundBtn {
  border-radius: 50% !important;
  min-width: 20px !important;
}

.linkPrimary {
  color: #007bff;
  background-color: #fff;
  margin-right: 5px;
  cursor: pointer;
}

input[type="checkbox"].toggle-switch {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 5em !important;
  height: 2em !important;
  border-radius: 3em;
  background-color: #dddddd;
  outline: 0;
  cursor: pointer;
  transition: background-color 0.09s ease-in-out;
  position: relative;
}
input[type="checkbox"].toggle-switch.checked {
  background-color: #A42998;
}

input[type="checkbox"].toggle-switch::after {
  content: "";
  width: 2em;
  height: 2em;
  background-color: white;
  border-radius: 3em;
  position: absolute;
  transform: scale(0.7);
  left: 0;
  transition: left 0.09s ease-in-out;
  box-shadow: 0 0.1em rgba(0, 0, 0, 0.5);
}
input[type="checkbox"].toggle-switch:checked::after {
  left: 3em;
}


.App.PinkBlue .cardBox .card-body .clientreport_rowbox button{
  background: #A42998;
    color: #ffffff;
    // min-height: initial !important;
    // padding: .4rem .8rem !important;
    // line-height: 1.7em;
    // height: initial !important;
    // font-size: clamp(0.8em, 1vw, 1rem) !important;
    // border: none;
}