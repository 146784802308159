.plm_inv_wiz_step5
{
    height: calc(100% - 66px);
    > .plm_cmn_cardbox
    {
        height: 100% !important;
        .plm_prview_top
        {
            display: flex;

        }
        .plm_prview_mid
        {
            height: calc(100% - 195px);
    padding-top: 15px;
    border-top: 1px solid #e6e6e6;
            .tb-wrap
            {
                margin: 0;
                min-height: initial;
                max-height: initial;
               height: 100%;
                .table
                {
                    border-right-width: 1px;
                    border-right-style: solid;
                }

            }
            .plm_prview_list
            {
                height: 100%;
                overflow: auto;
                h5
                {
                    font-size: 1rem;
                }
                ul
                {
                    li
                    {
                       font-size: 14px;
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                    
                }
               
            }
        }
        
    }
    
}