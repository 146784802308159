header {
  // position: fixed;
  background: #ffffff;
  // left: 0px;
  // right: 0px;
  // top: 0px;
}
header .header {
  border: 1px solid #e6e6e6;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.8rem;
}
header .header .head-right ul {
  padding: 0;
  display: flex;
  margin: 0;
}
header .header .head-right ul li {
  list-style-type: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
  .form-group {
    margin-bottom: 0;
  }
}
header .header .head-right ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
}
header .header .head-right ul li a > div {
  padding: 5px 5px;
  color: #666666;
  font-size: 14px;
}
header .header .head-right ul li a > div svg {
  width: 24px;
  height: 24px;
}
header .header .head-right ul li a:hover {
  background: rgba(255, 255, 255, 0.3);
}
header .header .head-right ul li a:hover > div svg {
  transform: scale(1.2);
  background: rgba(255, 255, 255, 0.3);
}
header .header .head-right ul li a .pro-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
}
header .header .head-right ul li a .pro-img img {
  max-width: 100%;
  max-height: 100%;
}
.profileIc {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
header {
  .header {
    .head-left {
      padding-left: 10px;
      .logo {
        height: 40px;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
.pro-name {
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  white-space: nowrap;
}

.logo_wrap .menuresp_btn {
  background-color: #666;
  margin-right: 0px;
}

.logo_wrap .menuresp_btn i {
  color: #fff;
}

.plm_menubar_wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.header_rlist {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
}

.header_rlist > li:not(:last-child) {
  margin-right: 10px;
}

.header_rlist > li > a > .pro-img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #333;
  padding: 3px;
  background-color: #fff;
}

.plm_theme_lst .form-group {
  margin-bottom: 0px;
}

.plm_theme_lst .form-group .form-section {
  margin-bottom: 0px;
}

.plm_theme_lst .form-group .form-section .form-section__field .form-control {
  height: 24px;
  padding: 3px 10px;
  border: 1px solid #333;
  font-size: 13px;
}

.header_wrap .headmen_rbardiv {
  display: flex !important;
  height: 100%;
  justify-content: flex-end;
}

.header_wrap {
  .logo_wrap {
    padding: 3px 0;
    .logo {
      a {
        display: inline-flex;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
}

/*dark theame*/
.App.Dark {
  background: #000000 !important;
  header {
    background: #000000 !important;
    .header {
      background: #e6e6e6 !important;
      border-color: #323639 !important;
      a {
        .pro-name {
          color: #000000;
        }
        .pro-img {
          background: #ffffff;
        }
      }
      i {
        color: #000000;
      }
    }
  }
  header .header .head-right ul li a > div svg {
    fill: #000000;
    .st0 {
      fill: #000000;
    }
  }
}
@media only screen and (max-width: 360px) {
  .header {
    padding: 10px 0 !important;
  }
}

/*green theame*/
.App.green {
  background: #e6ffe6 !important;
  header {
    background: #e6ffe6 !important;
    .header {
      background: #00cc00 !important;
      border-color: #00cc00 !important;
      a {
        .pro-name {
          color: #000000;
        }
        .pro-img {
          background: #ffffff;
        }
      }
      i {
        color: #000000;
      }
    }
  }
  header .header .head-right ul li a > div svg {
    fill: #000000;
    .st0 {
      fill: #000000;
    }
  }
}

/*blue theame*/
.App.blue {
  background: #e6f7ff !important;
  header {
    background: #e6f7ff !important;
    .header {
      background: #00aaff !important;
      border-color: #00aaff !important;
      a {
        .pro-name {
          color: #000000;
        }
        .pro-img {
          background: #ffffff;
        }
      }
      i {
        color: #000000;
      }
    }
  }
  header .header .head-right ul li a > div svg {
    fill: #000000;
    .st0 {
      fill: #000000;
    }
  }
}

/*red theame*/
.App.red {
  background: #ffe6e6 !important;
  header {
    background: #ffe6e6 !important;
    .header {
      background: #ff0000 !important;
      border-color: #ff0000 !important;
      a {
        .pro-name {
          color: #000000;
        }
        .pro-img {
          background: #ffffff;
        }
      }
      i {
        color: #000000;
      }
    }
  }
  header .header .head-right ul li a > div svg {
    fill: #000000;
    .st0 {
      fill: #000000;
    }
  }
}

/*PinkBlue theme*/
.App.PinkBlue{
  background: #ffffff !important;
  font-family: Verdana, sans-serif;
  header{
    background: #eeecec !important;
    .header_rlist > li > a{
      color: #A42998 !important;
    }
    .header_rlist{
      margin-right: 30px;
    }

    .header_rlist > li{
      .profileIc{
        border: 2px solid #bdcdea;
      }
      .pro-name{
        min-width: 130px;
        font-size: 12px;
        color: #A42998 ;
      }
    }
    .header_rlist > li{
      button{
        //border-radius: 0.25rem !important;
        &:nth-child(1){
          background: #ffffff !important;
          color: #A42998 ;
          //border-top-right-radius: 0 !important;
          //border-bottom-right-radius: 0 !important;
          border: none !important;
        }
        &:nth-child(2){
          background: #A42998 ;
          color: #ffffff !important;
          border: none !important;
          //border-bottom-left-radius: 0 !important;
          //border-top-left-radius: 0 !important;
        }
      }
    }
  }
  .navigation{
    background: rgba(188, 41, 164, .8);
    .PinkBlue .sideBar{
      
      ul{
        li{
          
          a{
            color: #ffffff !important;
          }
        }
      }
    }
  }
 
  /*
  .reportHeader{
    &.navigation{
      background: #A42998;
      .sideBar {
        padding: 0 0px;
        .navtgldiv{
          padding: 0 0;
        }
        ul li a .linkText{
          // font-size: 16px;
          font-size: clamp(0.8em, 1vw, 1rem) !important;
          font-weight: 600;
          text-decoration: none;
        }
        ul.inner_navmenu li{
          opacity: 1;
          // min-width: 160px;
          display: flex;
          justify-content: center;
          // padding: initial;
          padding: .5rem 1.6rem;
          // height: 40px;
          align-items: center;
          &.active{
            background: rgba(255, 255, 255, 0.3);
            border-radius: 0 !important;
          }
          &:hover{
            transform: scale(1);
            a{
              text-decoration: none;
            }
          }
          
        }
      } 
      
    }
  }
  */
}



/* Media Query Starts Here */
@media screen and (max-width: 767px) {
  .header_wrap .logo_wrap {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .menubar {
    padding: 0px;
  }
  .header_wrap .headmen_rbardiv {
    display: none !important;
  }
  .header_wrap .headmen_rbardiv.collapse.show {
    display: flex !important;
  }
}

@media screen and (max-width: 575px) {
}

@media screen and (max-width: 320px) {
  .logo a {
    display: block;
  }
}
/* Media Query Starts ends */



/* header style for report*/
.reportHeaderWrap, .header_wrap{
  height: 150px;
  //height: calc(15vh + 5px);
  display: flex;
  align-items: center;
  min-height: 100px;
  .imgLogo{
    width: 396px !important;
    height: 148px !important;
    max-width: 396px !important;
    max-height: 148px !important;
    // width: calc(20vw - 10px) !important;
    // height: calc(15vh - 10px) !important;
    // min-height: 85px;
  }
  .pro-name{
    font-size: 16px !important;
    max-width: 230px;
    min-width: 200px !important;
    margin-left: 20px;
    color: #A42998 !important;
    font-weight: 700;
    //margin-right: 150px;
    margin-right: 5vw;
  }
  .profileIc{
    // width: 90px;
    // height: 90px;
    width: calc(4vw + 6px);
    height: calc(4vw + 6px);
    min-width: 60px;
    min-height: 60px;
  }
  li{
    &:nth-child(3){
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    
    button{
      //height: 40px;
      border-radius: 0;
      width: 115px;
      height: 40px;
      margin-left: 15px;
      font-size: 16px !important;
      &:nth-child(1){
        color: #A42998 !important;
      }
    }
  }
}

