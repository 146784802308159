.tab-content{
    margin-top: 40px;
    .tb-wrap{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .tab-pane{
        .tb-wrap{
            table{
                tbody{
                    td{
                        padding: 0.25rem 0.25rem;
                    }
                }
            }
        }
        &>.col-12{
            padding: 0;
        }
    }
}
.nav-tabs{
    .nav-link{
        border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    margin-right: 2px;
   
    }
    a.nav-item{
        border: 1px solid #c0c0c0;
    }
}
.Dark.App{
    .nav-tabs{
    .nav-link{
        color: #ffffff;
        &.active{
            color: #495057;
        }
    }
    }
}
@media only screen and (max-width: 600px){
    .nav-tabs{
        a.nav-item{
            border: 1px solid #c0c0c0;
            width: 100%;
            text-align: center;
        }
    }   
}