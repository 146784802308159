.plm_actcodes_list_div
{
    height: 100%;  
    .plm_cmn_cardbox
    {
        height: 100%;
        margin: 0px;
        border-radius: 5px;
        position: relative;
        .card-head
        {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;    
        }
        .card-body
        {
            height: calc(100% - 48px);
            padding: 0px;
            border: none;
            border-radius: 0px;
            .groupWrap
            {
                padding: 0 15px;
            }
            .hr-divider
            {
                display: none;
            }
            .tb-wrap
            {
                overflow-y: auto;
                padding: 0;
                height: 100%;
                margin: 0;
                min-height: 100%;
                max-height: 100%;
                .plm_cmn_tbldiv
                {
                    height: 100%;
                }
            }
            .plm_cmn_cardbtn_div
            {
                position: absolute;
                top: 10px;
                right: 15px
            }
        }
        
    }
}


/* Media Queries Starts Here */

/* Media Queries Ends Here */