/*login container*/
.loginBody{
    .formField{
        text-align: left;
    }
    input:-internal-autofill-selected {
    background-color: #e6e6e6 !important;
    }
    .loginWrapper input, .loginWrapper input::placeholder, .loginWrapper input:focus{
        color: #6093AA !important;
    }
    .login-btn{
        button{
            background: #6dbf0d !important;
            width: 100%;
            border: none;
            border-radius: 0;
            height: 40px;
            padding: 4px;
            font-size: 16px;
        }
    }
}